import React, { useState } from 'react';
import Sidebar from '../../components/sidebar';
import { courseProgress1 } from '../../data';
import Drawer from '../../components/drawer';
import Quiz from '../training-portal/Quiz';

const ExamTrainingPortal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [course, setCourse] = useState([...courseProgress1]);
  const [active, setActive] = useState([0]);
  const [active1, setActive1] = useState([0]);
  const [indexs, setIndexs] = useState([0]);
  
  const [activeindex, setActiveIndex] = useState(0);
  
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const handleFunction = (ind) => {
    setActiveIndex(ind);
    const finded = indexs.find((item) => item === ind);
    
    if (ind !== 0) {
      if (!finded) {
        setIndexs((indexs) => [...indexs, ind]);
        if (active < 5) {
          let data = [...course];
          data[ind - 1].complete = true;
          // setActiveIndex(activeindex + 1);
          let count = active;
          count++;
          setActive(count);
          setCourse([...data]);
        }
      }
    }
  };
  const handleFunction1 = (event) => {
    
    if (active1 < 5) {
      let count = active1;
      count++;
      setActive1(count);
    }
  };
  return (
    <React.Fragment>
      <div className='w-[100%]'>
        <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
        <div className='w-[250px] xl:flex hidden'>
          <Sidebar />
        </div>
        <div className=' xl:w-[calc(100%-240px)] w-[100%] xl:ml-[240px] bg-[#f7f7f7] flex justify-between '>
          <div className='w-[100%]  md:px-[47px] px-[20px] pt-[35px] pb-[100px]'>
            <div className='flex justify-between'>
              <svg
                onClick={() => toggleDrawer()}
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-8 h-8 cursor-pointer xl:hidden flex'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12'
                />
              </svg>
            </div>
            <div className=''>
              <p className='text-[32px] font-bold text-[#32383F]'>
                Quiz Portal
              </p>
              <Quiz />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExamTrainingPortal;
