import React, { useEffect, useState } from 'react';
import ModalWindow from '../all-modal-window/modal-window';
import { menuItemsArray } from '../../utils/constans/menuItemArray';
import { useViewDasboardStore } from '../../storesZustand/useViewDasboardStore';

const MenuBar = ({ user, screenWidth }) => {
  
  const [setDisplayModalWindowType] = useViewDasboardStore(state => [
    state.setDisplayModalWindowType
  ]);
  
  const openModal = (modalType) => {
    setDisplayModalWindowType(modalType);
  };
  
  return (
    <div
      className='fixed py-3 px-7 gap-6 justify-between bottom-0 z-30 left-0 sm:left-auto sm:top-0 sm:right-0 w-full sm:w-[83px] bg-white h-[67px] sm:h-full flex items-center flex-row sm:flex-col sm:justify-end sm:gap-8 sm:p-2.5 sm:pb-8'
      style={{
        boxShadow:
          screenWidth >= 640
            ? 'rgba(0, 0, 0, 0.16) 0px -2px 4px'
            : '5px 4px 20px 0px rgba(0, 0, 0, 0.25)'
      }}
    >
      {menuItemsArray.map((item) => (
        (item.availableForRoles.includes(user.role) &&
          (item.availableForCampaign
              ? user.role === 'superadmin' && user.organization_name === item.availableForCampaign
              : true
          )
        ) && (
          <div
            key={item.id}
            className='flex items-center flex-col justify-center gap-1 cursor-pointer'
            onClick={() => openModal(item.title)}
          >
            <div>
              <img src={item.icon} alt='' className='w-5 h-5' />
            </div>
            <div className='text-[#8597A8] text-[10px] text-center'>
              {item.title}
            </div>
          </div>
        )
      ))}
    </div>
  );
};

export default MenuBar;
