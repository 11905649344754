import { useQuery } from "react-query";
import api from "../../utils/api";
import { useLocationStore } from "../../storesZustand/useLocationStore";
import { useUserStore } from "../../storesZustand/useUserStore";

export function useLocationData() {
  const token = localStorage.getItem("token");
  const [setAllLocation] = useLocationStore((state) => [state.setAllLocation]);

  return useQuery(
    "locationData",
    async () => {
      if (token) {
        const response = await api.get("/location/getAll");
        const data = response?.data?.data;
        setAllLocation(data);
        return data;
      }
    },
    {
      cacheTime: 60000 * 10,
      staleTime: 50000 * 10,
      refetchOnWindowFocus: false,
    }
  );
}
