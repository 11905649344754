import { useQuery } from 'react-query';
import api from '../../utils/api';
import { useOrganizationStore } from '../../storesZustand/useOrganizationStore';
import { useUserStore } from '../../storesZustand/useUserStore';

export function useOrganizationData (user) {
  const token = localStorage.getItem('token');
  const [setAllOrganisation] = useOrganizationStore((state) => [
    state.setAllOrganisation
  ]);
  
  return useQuery(
    'organizationData',
    async () => {
      if (token) {
        const response = await api.get('organization/all');
        const data = response?.data?.data;
        setAllOrganisation(data); //state Zustand
        return data;
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
}

export function useOrganizationById (id) {
  const token = localStorage.getItem('token');
  
  return useQuery(
    ['organizationByIdData', id],
    async () => {
      if (!id) return [];
      const response = await api.get(`organization/byId/${id}`);
      const data = response?.data?.data;
      return data; // Return the data from the API response
    },
    {
      cacheTime: 120000,
      staleTime: 100000
    }
  );
}
