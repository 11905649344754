import React from "react";

const ModalConfirmationToClose = ({
  type,
  handlerClosePerentWindow,
  handlerClose,
}) => {
  const handlerCloseFunc = React.useCallback(() => {
    handlerClosePerentWindow();
  }, [handlerClosePerentWindow]);

  return (
    <div className="flex flex-col sm:flex-row relative sm:min-h-full">
      <div className="flex-1 px-5 pt-5 pb-8 sm:p-6 flex flex-col gap-[15px] sm:gap-[30px]">
        <div className="flex justify-between flex-row items-center">
          <div className="text-[#787878] text-[0.9] flex gap-4 items-center">
            <h2 className="text-[28px] font-bold text-[#32383F]">
              {`Confirm close`}
            </h2>
          </div>
          <button
            className="self-start w-8 h-8"
            onClick={() => handlerClose(null)}
          >
            <img src="/images/close.svg" alt="close" />
          </button>
        </div>

        <div className="text-[20px]">
          Are you sure you want to close this window?
        </div>

        <div className="flex gap-[9px] self-end">
          <button
            onClick={() => handlerClose(null)}
            className="text-[11px] rounded-[7px] border-[1px] border-[#E4A50E] text-[#E4A50E] px-4 py-1"
          >
            No
          </button>
          <button
            className="text-[11px] rounded-[7px] min-w-[72px] border-[1px] border-[#E4A50E] bg-[#E4A50E] text-white px-4 py-1"
            type="submit"
            onClick={() => {
              handlerClose(null);
              handlerCloseFunc();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmationToClose;
