import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { generateDaysOfWeek } from "../../../helpers/iconsResultWorkersByDays";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "Savings",
    },
  },
  scales: {
    x: {
      title: {
        display: true,
      },
    },
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: "Savings - €",
      },
    },
  },
};

export const Chart = ({ data, costPerLitre, variant, screenWidth }) => {
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const today = new Date();
  const labels = [];

  for (let i = 7; i > 0; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    const dayName = daysOfWeek[date.getDay()];
    labels.push(dayName);
  }

  // Create an array to store the values for the last 7 days (not including today)
  const last7DaysArray = [];

  for (let i = 5; i >= -1; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i - 1);
    const dateString = date.toISOString().split("T")[0];
    const value = data?.[dateString] || 0;
    last7DaysArray.push(value.toFixed(2));
  }

  const inputData = {
    labels,
    datasets: [
      {
        label: "Savings",
        data: last7DaysArray,

        borderColor: "#E5A50E",
        backgroundColor: "#E5A50E",
      },
      // {
      //   label: "Dataset 2",
      //   data: [600, 400, 400, 200, 200, 300, 450],
      //   borderColor: "#fbf1db",
      //   backgroundColor: "#fbf1db",
      // },
    ],
  };

  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  const handleChartLoad = (chart) => {
    const parent = chart.canvas.parentNode;

    if (screenWidth <= 430) {
      parent.style.height = `${428}px`;
      parent.style.width = `${349}px`;
    } else if (screenWidth < 640) {
      parent.style.height = `${428}px`;
      parent.style.width = `${361}px`;
    } else if (screenWidth < 1000) {
      parent.style.height = `${372}px`;
      parent.style.width = `${460}px`;
    } else if (screenWidth < 1280) {
      parent.style.height = `${372}px`;
      parent.style.width = "100%";
    } else if (screenWidth < 1440) {
      parent.style.height = `${372}px`;
      parent.style.width = "100%";
    } else if (screenWidth < 1540) {
      parent.style.height = `${372}px`;
      parent.style.width = "100%";
    } else if (screenWidth < 1640) {
      parent.style.height = `${372}px`;
      parent.style.width = "100%";
    } else if (screenWidth < 1840) {
      parent.style.height = `${372}px`;
      parent.style.width = "100%";
    } else if (screenWidth < 2000) {
      parent.style.height = `${372}px`;
      parent.style.width = "100%";
    } else {
      parent.style.height = `${372}px`;
      parent.style.width = "100%";
    }
  };

  const variantClass = useMemo(() => {
    return variant === "dark" ? "training-box2" : "training-box3";
  }, [variant]);

  return (
    <React.Fragment>
      <div className={variantClass + " bg-white"}>
        <p className="font-manrope pb-2 text-[18px]">Savings Overview</p>
        <Line
          options={options}
          data={inputData}
          plugins={[{ afterDraw: handleChartLoad }]}
        />
      </div>
    </React.Fragment>
  );
};

export default Chart;
