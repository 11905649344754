import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

function SidebarCoursePortal({
  completeProgress,
  course,
  handleFunction,
  activeindex,
  onVideoTime,
  videoDuration,
}) {
  const scrollableDivRef = useRef(null);

  // Scroll to activeindex when it changes and activeindex > 3
  useEffect(() => {
    if (activeindex > 2 && scrollableDivRef.current) {
      // Calculate the offset for scrolling
      const offset = activeindex * 100; // Adjust this value as needed

      // Scroll the scrollable-div to the desired position
      scrollableDivRef.current.scrollTop = offset;
    }
  }, [activeindex]);

  return (
    <>
      <div
        ref={scrollableDivRef}
        className="md:col-span-5 col-span-12 md:mt-0 mt-5 custom-scroll"
      >
        <div className="flex items-center justify-between py-[40px] border px-5">
          <p className="text-[#1F1F1F] text-[22px] font-medium">
            Course Progress
          </p>
          <p className="text-[#848484] text-[22px] font-medium">
            {completeProgress + 1}/{course.length}
          </p>
        </div>
        <div className="">
          {course?.map((item, index) => {
            return (
              <div
                onClick={() => handleFunction(index)}
                key={index}
                style={{
                  borderLeft: activeindex === index ? "5px solid #E5A50E" : "",
                }}
                className={`${index % 2 === 1 ? "bg-gray-100" : ""} ${
                  activeindex === index ? "stext-[#E5A50E] " : ""
                }  flex justify-between items-center cursor-pointer px-3 py-[30px] border`}
              >
                <div className="flex items-center">
                  <div
                    className={` ${
                      item.complete ? "bg-[#E5A50E]" : "bg-[#EBEBEB]"
                    } 
                            ${
                              activeindex === index
                                ? "text-[#E5A50E] border border-[#E5A50E]"
                                : "text-[#848484]"
                            }
                                 
                             h-[60px] w-[60px] flex items-center justify-center rounded-full   text-lg font-medium`}
                  >
                    {item.complete ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    ) : (
                      <div>#{index + 1}</div>
                    )}
                  </div>
                  <p
                    className={`text-[14px] ml-4 w-[140px] hover:text-[#E5A50E] cursor-pointer ${
                      item.complete ? "text-[#E5A50E]" : ""
                    }`}
                  >
                    {item.title}
                  </p>
                </div>
                <p className="text-[#848484] font-medium">{item.progress}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

SidebarCoursePortal.propTypes = {};

export default SidebarCoursePortal;
