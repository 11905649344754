import { useQuery } from "react-query";
import api from "../../utils/api";
import { useDashboardDataStore } from "../../storesZustand/useDashboardDataStore";

export function useDashboardData(roleId, organizationId, paramOrg) {
  const token = localStorage.getItem("token");

  const [setAllDashboardData, setLocationWithAnalyticsOneOrganization] =
    useDashboardDataStore((state) => [
      state.setAllDashboardData,
      state.setLocationWithAnalyticsOneOrganization,
    ]);

  return useQuery(
    ["dashboardData", organizationId, paramOrg],
    async () => {
      let org = organizationId;
      if (roleId) {
        if (organizationId === 1 && roleId === 3 && paramOrg === undefined) {
          org = undefined;
        }

        const url = org
          ? `device/dashboard/organization/${paramOrg || organizationId}`
          : "device/dashboard/organization";

        if (token) {
          const response = await api.get(url);
          const data = response?.data?.data;
          if (org) {
            setLocationWithAnalyticsOneOrganization(data);
          } else {
            setAllDashboardData(data);
          }
          return data;
        }
      }
    },
    {
      cacheTime: 60000 * 10,
      staleTime: 60000 * 9,
      refetchOnWindowFocus: false,
    }
  );
}

export function useEmployeesForLocations(locations) {
  return useQuery(
    "employeesForLocations",
    async () => {
      const response = api.post(`/location/employeesForLocations/`, {
        locations: locations,
      });

      return response?.data?.data; // Return the data from the API response
    },
    {
      cacheTime: 120000, // Cache data for 60 seconds
      staleTime: 100000, // Consider data stale after 30 seconds
    }
  );
}
