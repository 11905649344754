import React from "react";
import Spinner from "../../UI/Spinner";
import { useFetching } from "../../../hooks/useFetching";
import { toast } from "react-toastify";
import {
  employeesService,
  useDeleteUserById,
  useDeleteUserByInviteId,
  useDeleteUserInLocationById,
} from "../../../services/employees/employees.service";
import { useViewEmployeesStore } from "../../../storesZustand/useViewEmployeesStore";
import { ModalTypes } from "../modal-window";

const ModalEmployeesPage = ({ handleClose, type, currentIdForGetData }) => {
  const { mutate: mutateDeleteUserBId, isLoading: isLoadingDeleteUser } =
    useDeleteUserById();
  const {
    mutate: mutateDeleteInviteUserBId,
    isLoading: isLoadingDeleteInviteUser,
  } = useDeleteUserByInviteId();
  const {
    mutate: mutateDeleteUserInLocation,
    isLoading: isLoadingDeleteUserInLocation,
  } = useDeleteUserInLocationById();

  const [setIsDeleting] = useViewEmployeesStore((state) => [
    state.setIsDeleting,
  ]);

  const [fetchFunction, isLoading, error] = useFetching(async (item) => {
    const responseData = await employeesService.postSendReminder(
      currentIdForGetData
    );
    if (responseData?.data) {
      toast.success("Reminder sent");
    }
    if (error) {
      toast.error(
        `${
          error?.response?.data.message ? error?.response?.data.message : error
        }!`
      );
    }
  });
  const handleCloseFunc = React.useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handlerRemoveUserInOrganization = () => {
    if (type === ModalTypes.REMOVE_USER) {
      const isInviteCodePresent = currentIdForGetData.invite_code;
      if (isInviteCodePresent) {
        mutateDeleteInviteUserBId(currentIdForGetData);
      } else {
        mutateDeleteUserBId(currentIdForGetData);
      }
    }
    setIsDeleting();
    handleClose();
  };

  const handlerRemoveUserInLocation = () => {
    if (type === ModalTypes.REMOVE_USER) {
      mutateDeleteUserInLocation(currentIdForGetData);
    }
    setIsDeleting();
    handleClose();
  };

  const handlerSendReminder = () => {
    if (type === ModalTypes.SEND_REMINDER) {
      fetchFunction(currentIdForGetData);
    }
    setIsDeleting();
    handleClose();
  };

  return (
    <div className="flex flex-col sm:flex-row relative sm:min-h-full">
      <div className="flex-1 px-5 pt-5 pb-8 sm:p-6 flex flex-col gap-[15px] sm:gap-[30px]">
        <div className="flex justify-between flex-row items-center">
          <div className="text-[#787878] text-[0.9] flex gap-4 items-center">
            <h2 className="text-[28px] font-bold text-[#32383F]">
              Confirm action
            </h2>
          </div>
          <button className="self-start w-8 h-8" onClick={handleCloseFunc}>
            <img src="/images/close.svg" alt="close" />
          </button>
        </div>
        <div className="text-[20px]">
          {type === ModalTypes.SEND_REMINDER &&
            `Are you sure you want to send a reminder to `}
          {type === ModalTypes.SEND_REMINDER && (
            <span className="font-bold">{currentIdForGetData.email}</span>
          )}
          {type === ModalTypes.REMOVE_USER && (
            <p>
              Are you sure you want to delete
              <span className="font-bold">
                {` ${currentIdForGetData?.first_name || ""}  ${
                  currentIdForGetData?.last_name || ""
                }`}
              </span>
              ?
            </p>
          )}
          <br />
        </div>
        <div className="flex gap-[9px] self-end">
          {type === ModalTypes.REMOVE_USER ? (
            <>
              {!currentIdForGetData?.invite_code && (
                <button
                  type="button"
                  onClick={() => handlerRemoveUserInLocation()}
                  className="text-[13px] rounded-[7px] border-[2px] border-[#E4A50E] text-[#E4A50E] px-5 py-2"
                >
                  {isLoadingDeleteUserInLocation ? (
                    <Spinner
                      size={"w-6 h-4"}
                      color={"text-white-200 dark:text-white-600"}
                    />
                  ) : (
                    <span> Delete user from Location </span>
                  )}
                </button>
              )}
              <button
                className="text-[13px] rounded-[7px] min-w-[72px] border-[2px] border-[#E4A50E] bg-[#E4A50E] text-white px-5 py-2"
                type="button"
                onClick={() => handlerRemoveUserInOrganization()}
              >
                {isLoadingDeleteUser || isLoadingDeleteInviteUser ? (
                  <Spinner
                    size={"w-6 h-4"}
                    color={"text-white-200 dark:text-white-600"}
                  />
                ) : (
                  <span>Delete user from organization</span>
                )}
              </button>
            </>
          ) : (
            <>
              <button
                className="text-[13px] rounded-[7px] min-w-[72px] border-[2px] border-[#E4A50E] bg-[#E4A50E] text-white px-5 py-2"
                type="submit"
                onClick={() => handlerSendReminder()}
              >
                {isLoading ? (
                  <Spinner
                    size={"w-6 h-4"}
                    color={"text-white-200 dark:text-white-600"}
                  />
                ) : (
                  <span>Yes</span>
                )}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalEmployeesPage;
