import create from 'zustand';

export const useDeviceStore = create((set, get, api) => ({
  allDevices: null,
  devicesOneLocation: null,
  
  setAllDevices: (data) => {
    set({
      allDevices: data
    });
  },
  
  setAllDevicesOneLocation: (data) => {
    set({
      devicesOneLocation: data
    });
  }
  
}), {
  name: 'deviceStore'
});