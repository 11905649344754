import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";

const Table = ({ children }) => <View style={styles.table}>{children}</View>;
const TableRow = ({ children }) => (
  <View style={styles.tableRow}>{children}</View>
);
const TopRow = ({ children }) => <View style={styles.topRow}>{children}</View>;
const TableCell = ({ children }) => (
  <View style={styles.tableCol}>
    <Text style={styles.tableCell}>{children}</Text>
  </View>
);

const TopCell = ({ children }) => (
  <View style={styles.tableCol}>
    <Text style={styles.tableCellTop}>{children}</Text>
  </View>
);

function TableNew({ data, currency }) {
  return (
    <Table>
      <TopRow>
        <TopCell>Date</TopCell>
        <TopCell>Carbon Savings (kg CO2e)</TopCell>
        <TopCell> Litres of Oil Saved (L)</TopCell>
        <TopCell>Savings ({currency})</TopCell>
      </TopRow>

      {data.map((item) => (
        <TableRow>
          <TableCell> {item.date}</TableCell>
          <TableCell> {item.carbon}</TableCell>
          <TableCell>{item.oil}</TableCell>
          <TableCell>{item.saving}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: "100%",
    height: "120px",
  },
  table: {
    display: "table",
    width: "auto",
    // borderStyle: "none",
    // borderColor: "#00",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  topRow: {
    margin: "auto",
    flexDirection: "row",
    fontWeight: "bold",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    border: "#fff",
    borderBottomColor: "#f7f7f7",
    borderWidth: 2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    verticalAlign: "middle",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    color: "#1E3A56",
    verticalAlign: "middle",
  },
  tableCellTop: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    color: "#B0CAD1",
    fontWeight: "18",
    verticalAlign: "middle",
  },
});

export default TableNew;
