import React, { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

const ModalSelectWithCheckBox = ({
  data,
  optionDefault,
  formik,
  field,
  dropListUp,
}) => {
  const newData = Array.isArray(data) ? data : [data];
  const [isDropdownVisible, setDropdownVisibility] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState(newData);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(newData);
  }, [newData]);

  const toggleDropdown = () => {
    setDropdownVisibility(!isDropdownVisible);
  };

  const handleOptionSelect = async (option) => {
    let selectedOptionIds = selectedOptions.map((selected) => selected.id);
    const isSelected = selectedOptionIds.includes(option.id);
    let _selectedOptions = selectedOptions;
    console.log({ selectedOptionIds, isSelected });

    if (isSelected) {
      setSelectedOptions(
        selectedOptions.filter((selected) => selected.id !== option.id)
      );
    } else {
      _selectedOptions = [
        ..._selectedOptions,
        { id: option.id, name: option.name },
      ];

      setSelectedOptions(_selectedOptions);
    }
    console.log({ _selectedOptions });
    console.log("selectedOptions.map((selected) => selected.id)");
    console.log(selectedOptions.map((selected) => selected.id));
    formik.setFieldValue(
      field,
      _selectedOptions.map((selected) => selected.id)
    );
  };

  const handleClickOutside = () => {
    setDropdownVisibility(false);
  };

  useOutsideClick(dropdownRef, handleClickOutside);

  return (
    <div className="relative rounded-xl text-black" ref={dropdownRef}>
      {isDropdownVisible ? (
        <div className="flex justify-between text-black bg-white font-medium border-[1.3px] rounded-[8px] text-sm px-5 py-2.5">
          <div className="pr-2 flex justify-start  items-center">
            {selectedOptions.map((selected) => (
              <div key={selected.id} className="flex justify-center mr-1">
                <span className="text-[12px] mr-[1px]">{selected.name}</span>
                <button onClick={() => handleOptionSelect(selected)}>
                  <svg
                    className="w-3 h-3 text-gray-500 hover:text-red-500 cursor-pointer"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
          <button onClick={toggleDropdown}>
            <svg
              className={`w-2.5 h-2.5 ms-3  ${
                isDropdownVisible
                  ? "transition duration-500 ease-in-out transform rotate-180"
                  : "transition duration-500 ease-in-out transform rotate-0"
              }`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
        </div>
      ) : (
        <button
          id="dropdownPeriodButton"
          data-dropdown-toggle="dropdownPeriod"
          className="flex justify-between text-black w-full bg-white font-medium border=[#B8BBC2] border-[1.3px] rounded-[8px] text-sm px-5 py-2.5 text-center items-center "
          type="button"
          onClick={toggleDropdown}
        >
          <div className="flex">
            {selectedOptions.length === 0 && optionDefault}
            {selectedOptions.map((selected) => (
              <div key={selected.id} className="flex justify-center mr-1">
                <span className="text-[12px] mr-[1px]">{selected.name}</span>
                <button onClick={() => handleOptionSelect(selected)}>
                  <svg
                    className="w-3 h-3 text-gray-500 hover:text-red-500 cursor-pointer"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
          <button onClick={toggleDropdown}>
            <svg
              className={`w-2.5 h-2.5 ms-3  ${
                isDropdownVisible
                  ? "transition duration-500 ease-in-out transform rotate-180"
                  : "transition duration-500 ease-in-out transform rotate-0"
              }`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
        </button>
      )}
      <div
        id="dropdownPeriod"
        className={`absolute z-10 mt-2 bg-white divide-y divide-gray-100 rounded-[8px] shadow w-full ${
          isDropdownVisible ? "" : "hidden"
        }`}
        style={dropListUp && { bottom: "50px" }}
      >
        <ul
          className="py-2 text-sm text-gray-700 max-h-[200px] w-full overflow-y-auto"
          aria-labelledby="dropdownDefaultButton"
        >
          {filteredOptions.map((el, i) => (
            <li
              key={`${el.id}-${i}`}
              className="flex items-center px-4 py-2 hover:bg-gray-100"
            >
              <input
                type="checkbox"
                checked={selectedOptions.some(
                  (selected) => selected.id === el.id
                )}
                onChange={() => handleOptionSelect(el)}
                className="mr-2 h-4 w-4 hover:cursor-pointer"
                style={{
                  accentColor: selectedOptions.some(
                    (selected) => selected.id === el.id
                  )
                    ? "black"
                    : "transparent",
                }}
              />
              <span>{el?.name ? el.name : el}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ModalSelectWithCheckBox;
