import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProfilePicture from '../profile-picture';
import { formattedCurrency } from '../../helpers/helpers';
import { useUserStore } from '../../storesZustand/useUserStore';

const Profile = ({ data }) => {
  const [user] = useUserStore(state => [
    state.user
  ]);
  var mL = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  
  const currentDate = new Date();
  
  const year = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1 and pad with '0' if necessary
  const currentMonthF = `${year}-${currentMonth}`;
  const prevMonth = String(currentDate.getMonth()).padStart(2, '0'); // Month is zero-based, so we add 1 and pad with '0' if necessary
  const prevMonthF = `${year}-${prevMonth}`;
  const twoMonthsAgo = String(currentDate.getMonth() - 1).padStart(2, '0'); // Month is zero-based, so we add 1 and pad with '0' if necessary
  const twoMonthsAgoF = `${year}-${twoMonthsAgo}`;
  
  const [imageRatio, setImageRatio] = useState(null);
  const [image, setImage] = useState('img');
  
  useEffect(() => {
    const imageUrl =
      user?.picture === 'null'
        ? '/images/blank-profile-picture.png'
        : user?.picture;
    
    const img = new Image();
    
    img.onload = () => {
      setImage(img);
      setImageRatio(img.width / img.height);
      console.log({ imageRatio });
      console.log(img.width);
    };
    
    img.src = imageUrl;
  }, [imageRatio, user?.picture]);
  
  return (
    <React.Fragment>
      <div
        className='w-[350px] bg-white px-5  pb-[100px] pt-[45px]'
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
        }}
      >
        <ProfilePicture user={user} />
        <p className='text-center text-[24px] font-bold mt-[25px] text-[#32383F]'>
          {user?.first_name} {user?.last_name}
        </p>
        <p className='text-[#787878] text-center mt-2'>{user?.email}</p>
        <Link
          to='/settings'
          className='w-[156px] h-[52px] m-auto flex justify-center items-center edit-profile'
        >
          <p className='text-[#112945] font-bold'>Edit Profile</p>
        </Link>
        <div className='training-box2 border m-auto mt-[50px]'>
          <div className='bg-[white] rounded-full h-[44px] w-[44px] flex justify-center items-center'>
            <p className='text-[20px] text-[#112944] font-bold'>1</p>
          </div>
          <p className='text-[#C6C6C6] mt-2'>
            Savings in {mL[currentDate.getMonth() - 2]}
          </p>
          <p className='text-[30px] font-bold  text-white'>
            {formattedCurrency(data?.[twoMonthsAgoF])}
          </p>
          <div className='w-full bg-[#112844] rounded-full h-2.5'>
            <div
              className='bg-[#E5A50E] h-2.5 rounded-full'
              style={{ width: '80%' }}
            ></div>
          </div>
        </div>
        <div className='training-box3 border m-auto mt-[20px]'>
          <div className='bg-[#F4F4F4] rounded-full h-[44px] w-[44px] flex justify-center items-center'>
            <p className='text-[20px] text-[#112944] font-bold'>2</p>
          </div>
          <p className='text-[#C6C6C6] mt-2'>
            Savings in {mL[currentDate.getMonth() - 1]}
          </p>
          <p className='text-[30px] font-bold  text-[#32383F]'>
            {formattedCurrency(data?.[prevMonthF])}
          </p>
          <div className='w-full bg-[#E0E0E0] rounded-full h-2.5'>
            <div
              className='bg-[#E5A50E] h-2.5 rounded-full'
              style={{ width: '50%' }}
            ></div>
          </div>
        </div>
        <div className='training-box3 border m-auto mt-[20px]'>
          <div className='bg-[#F4F4F4] rounded-full h-[44px] w-[44px] flex justify-center items-center'>
            <p className='text-[20px] text-[#112944] font-bold'>3</p>
          </div>
          <p className='text-[#C6C6C6] mt-2'>
            Savings in {mL[currentDate.getMonth()]}
          </p>
          <p className='text-[30px] font-bold  text-[#32383F]'>
            {formattedCurrency(data?.[currentMonthF])}
          </p>
          <div className='w-full bg-[#E0E0E0] rounded-full h-2.5'>
            <div
              className='bg-[#E5A50E] h-2.5 rounded-full'
              style={{ width: '50%' }}
            ></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
