import { useMutation, useQueryClient } from 'react-query';
import api from '../../utils/api';
import { toast } from 'react-toastify';

export function useOrganizationMutation () {
  const token = localStorage.getItem('token');
  const queryClient = useQueryClient();
  
  return useMutation(
    'organizationMutation',
    (newOrganization) =>
      api.post('organization/create', newOrganization),
    {
      onSuccess: (data) => {
        toast.success('Organization is created');
        queryClient.invalidateQueries('organizationData');
      },
      onError: (error) => {
        toast.error(`${error?.response?.data.message ? error?.response?.data.message : error}!`);
      }
    }
  );
}

export function useOrganizationUpdateMutation () {
  const token = localStorage.getItem('token');
  const queryClient = useQueryClient();
  
  return useMutation(
    'organizationUpdateMutation',
    (arg) =>
      api.put(`organization/update/${arg.id}`, arg.data, {
        headers: {
          'x-auth-token': token
        }
      }),
    {
      onSuccess: (data) => {
        toast.success('Organization is updated successfully');
        queryClient.invalidateQueries('organizationData');
      },
      onError: (error) => {
        toast.error(`${error?.response?.data.message ? error?.response?.data.message : error}!`);
      }
    }
  );
}

