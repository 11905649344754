export const rolesUser = [
  {
    id: 2,
    name: 'Employee'
  },
  {
    id: 1,
    name: 'Admin'
  },
  {
    id: 3,
    name: 'Super Admin'
  }
];
