import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../utils/api";
import { handleCreateCert } from "../../helpers/certificate";
import { useUserStore } from "../../storesZustand/useUserStore";

export default function Certification() {
  const [user] = useUserStore((state) => [state.user]);
  const token = localStorage.getItem("token");
  const [results, setResults] = useState({});
  const course_id = 1;

  useEffect(() => {
    api
      .post(
        "/certificate/getByUserIdCourse/" + course_id,
        {},
        {
          headers: {
            "x-auth-token": token,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setResults(res?.data?.data);
        }
      })
      .catch((res) => {
        toast.error(res.response.data.message);
      });
  }, [user?.id]);

  const handleDownloadCertificate = () => {
    handleCreateCert(
      user?.first_name,
      user?.last_name,
      user?.role === "superadmin"
        ? user?.organization_name
        : user?.location_names.toString(),
      results
    );
  };

  return (
    <div className="md:col-span-7 col-span-12">
      <p className="text-[32px] font-medium text-[#1F1F1F]">Congratulations!</p>
      <p className="text-[#848484]">
        You are now certified to operate our oil filtration machine safely.
        Please download your certification.
      </p>
      <table className="border-collapse border border-gray-300 p-5 mt-3">
        <tr>
          <td className="text-[#848484] p-2 text-[24px] font-bold">Results:</td>
          <td></td>
        </tr>

        <tr>
          <td className="text-[#848484]  p-2 text-[16px]">
            <b>Certificate ID:</b>
          </td>
          <td className="p-2">{results?.id}</td>
        </tr>
        <tr>
          <td className="text-[#848484] p-2 text-[16px]">
            <b>Passed:</b>
          </td>
          <td className="p-2">{results?.passed ? "Yes" : "No"}</td>
        </tr>
        <tr>
          <td className="text-[#848484] p-2 text-[16px]">
            <b>Result:</b>
          </td>
          <td className="p-2">{results?.score + "%"}</td>
        </tr>
      </table>

      <button
        className="download-certificate py-[21px] px-[15px] min-w-[350px] rounded-full mt-7 flex justify-center"
        onClick={handleDownloadCertificate}
      >
        <p className="sm:text-[20px] text-[14px] font-bold uppercase text-white">
          DOWNLOAD CERTIFICATE
        </p>
      </button>
    </div>
  );
}
