import React from "react";

const Side = () => {
  return (
    <React.Fragment>
      <div
        className="min-h-screen bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: 'url("/images/my-bg.png")' }}
      >
        <div className="flex justify-center items-center min-h-[100vh]">
          <div className="w-[571px] h-[570px] text-white align-content-center flex flex-col align-items-center">
            {/* <div className="absolute top-1 left-0"></div> */}
            <img
              src="/images/halcyon-logo-glow.svg"
              alt="Halcyon Logo"
              width="55%" // Set the width of the logo
              height="auto" // Set the height of the logo
            />
            <p className="text-[24px] font-normal mt-[10px]">
              The mission of Halcyon is to provide quality bespoke services and
              innovative products to our clients.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Side;
