import React from 'react';

const BurgerOpen = ({ handleClick }) => {
  return (
    <button
      onClick={() => handleClick()}
      className='bg-[#8597A8] p-2 rounded-[5px] mt-3 md:block lg:hidden'
    >
      <img src='/images/burger-close.svg' alt='burger' />
    </button>
  );
};

export default BurgerOpen;
