import React from 'react';

const BreadcrumbSeparator = () => {
  return (
    <svg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path opacity='0.7' d='M2.14 10.5L0.65 9.01L4.06 5.7L0.65 2.39L2.14 0.9L6.95 5.7L2.14 10.5Z' fill='#32383F' />
    </svg>
  );
  
};

export default BreadcrumbSeparator;
