import { useQuery } from 'react-query';
import api from '../../utils/api';
import { useDeviceStore } from '../../storesZustand/useDeviceStore';
import { useUserStore } from '../../storesZustand/useUserStore';


export function useGetAllDevicesData () {
  const token = localStorage.getItem('token');
  const [setAllDevices] = useDeviceStore((state) => [state.setAllDevices]);
  const { user, setUsersDataAfterLogin } = useUserStore();
  return useQuery(
    'getAllDevices',
    async () => {
      if (token) {
        const response = await api.get('/device/getAll');
        const data = response?.data?.data;
        console.log(user);
        setAllDevices(data);
        return response?.data?.data;
      }
    },
    {
      cacheTime: 60000 * 10,
      staleTime: 60000 * 9,
      refetchOnWindowFocus: false,
    }
  );
}

export const fetchDeviceById = async (values) => {
  const token = localStorage.getItem('token');
  return await api.get(`device/getById/${values}`);
};

export function useDevicesByOneLocation (id) {
  const token = localStorage.getItem('token');
  const [setAllDevicesOneLocation] = useDeviceStore((state) => [
    state.setAllDevicesOneLocation
  ]);
  return useQuery(
    ['devicesByOneLocation', id],
    async () => {
      const response = await api.get(`device/dashboard/location/${id}`);
      const data = response?.data?.data;
      setAllDevicesOneLocation(data);
      return data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
}

export function useDevicesData (setFilteredData, setLocation) {
  const token = localStorage.getItem('token');
  return useQuery('devicesData', async () => {
    if (token) {
      const response = await api.get('/device/organizationDevices');
      return response?.data?.data;
    } else {
      return [];
    }
  }, 
  {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
}
