import React from "react";

// Individual item component
const Box = ({ imageSrc, title, value, unit, variant }) => {
  return (
    <div
      className={`${
        variant === "light" ? "training-box3" : "training-box2"
      } border   sm:px-[10px] px-[5px] py-[15px] print:py-[15px]  `}
    >
      <div
        className={`rounded-full ${
          variant === "dark" ? "bg-[white]" : ""
        } sm:h-[35px] sm:w-[35px] h-[15px] w-[15px] flex justify-center items-center`}
      >
        <img src={imageSrc} alt="" />
      </div>
      <p
        className={`text-[#C6C6C6] mt-2 sm:text-[12px] text-[7px] print:text-[8px]`}
      >
        {title}
      </p>
      <p
        className={`sm:text-[16px] text-[7px] font-bold mt-[5px] print:mt-[0px] ${
          variant === "light" ? "text-[#32383F]" : "text-[#fff]"
        }`}
      >
        {value}{" "}
        {unit && <span className="sm:text-[10px] text-[7px]">{unit}</span>}
      </p>
    </div>
  );
};

// Container component for all items
const Boxes = ({ currency, item }) => {
  const items = [
    {
      imageSrc: "/images/frame.svg",
      title: "Total Savings",
      value: currency + item.totalSaving,
      variant: "dark",
    },
    {
      imageSrc: "/images/Frame (12).svg",
      title: "Savings (30 days)",
      value: item.saving,
      variant: "light",
    },
    {
      imageSrc: "/images/Frame (12).svg",
      title: "Carbon saved",
      value: item.totalCarbonSaving,
      unit: "kgCO2e",
      variant: "light",
    },
    {
      imageSrc: "/images/Frame (12).svg",
      title: "Litres of saved",
      value: item.totalLitresSaved,
      variant: "light",
    },
  ];

  return (
    <>
      {items.map((item, index) => (
        <Box
          key={index}
          imageSrc={item.imageSrc}
          title={item.title}
          value={item.value}
          unit={item.unit}
          variant={item.variant}
        />
      ))}
    </>
  );
};

export default Boxes;
