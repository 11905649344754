import React from "react";

const CircleIcon = ({ condition }) => {
  return (
    <div className="flex items-center">
      <div
        className={`w-6 h-6 flex items-center justify-center rounded-full border ${
          condition === 0
            ? "bg-[#daa73b]"
            : condition === 1
            ? "bg-[#BFD78D]"
            : "bg-[#D36363]"
        }`}
      >
        {condition === 1 ? (
          <svg
            className="w-4 h-4 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              d="M5 13l4 4L19 7"
            />
          </svg>
        ) : (
          <svg
            className="w-4 h-4 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default CircleIcon;
