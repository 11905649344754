import create from 'zustand';

export const useViewEmployeesStore = create((set, get, api) => ({
  currentClickSendReminderOrDeleteItem: null,
  isDeleting: false,
  
  setCurrentClickSendReminderOrDeleteItem: (item) => {
    set({
      currentClickSendReminderOrDeleteItem: item
    });
  },
  setIsDeleting: () => {
    const isDeleting = get().isDeleting;
    set({
      isDeleting: !isDeleting
    });
  }
  
}), {
  name: 'useViewEmployeesStore'
});