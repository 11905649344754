import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import BreadcrumbSeparator from "./breadcrumbSeparator";
import { useNavigate } from "react-router-dom";

const BreadcrumbsComponent = ({ breadCrumbsArr }) => {
  const breadcrumbs = [];
  const navigate = useNavigate();
  const handlerNavigate = (url) => {
    navigate(url);
  };

  breadCrumbsArr.map((breadcrumb, index) => {
    if (Object.keys(breadcrumb).length !== 0)
      breadcrumbs.push(
        <Link
          className="cursor-pointer"
          underline="hover"
          key={index}
          color="inherit"
          onClick={() => handlerNavigate(breadcrumb.url)}
        >
          {breadcrumb.name}
        </Link>
      );
  });

  return (
    <Breadcrumbs separator={<BreadcrumbSeparator />} aria-label="breadcrumb">
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
