import create from 'zustand';

export const useOrganizationStore = create((set, get, api) => ({
  organizations: null,
  
  setAllOrganisation: (data) => {
    set({
      organizations: data
    });
  }
}), {
  name: 'OrganizationStore'
});