import jsPDF from "jspdf";

export const handleCreateCert = (
  first_name,
  last_name,
  organization_name,
  results
) => {
  // TODO: put in correct values
  const pdf = new jsPDF("l", "mm", "a4");
  const imgHeight = 210; // A4 width in mm
  const imgWidth = (2000 * imgHeight) / 1414; // Maintain aspect ratio

  const imagePath = "/images/certificate-template.png"; // Adjust the path
  const isValidImage = /* Check if the image exists and is valid */ true;

  if (isValidImage) {
    pdf.addImage(imagePath, "PNG", 0, 0, imgWidth, imgHeight);

    pdf.text(results?.id, 10, 10); // Adjust coordinates as needed
    pdf.setFontSize(32); // Set the font size to 24 (adjust as needed)
    const fullName = first_name + " " + last_name;
    const fullNameWidth =
      (pdf.getStringUnitWidth(fullName) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const fullNameX = (imgWidth - fullNameWidth) / 2; // Centered horizontally
    pdf.text(fullName, fullNameX, 100); // Adjust coordinates as needed

    pdf.setFontSize(16); // Set the font size to 24 (adjust as needed)
    const lastLine = `Completed on ${new Date(
      results?.completed_at
    ).toLocaleDateString()} for ${organization_name}`;
    const lastLineWidth =
      (pdf.getStringUnitWidth(lastLine) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const lastLineX = (imgWidth - lastLineWidth) / 2; // Centered horizontally
    pdf.text(lastLine, lastLineX, 125); // Adjust coordinates as needed
    pdf.save("Halcyon Eco Certificate.pdf");
  } else {
    console.error("The image file is invalid or not found.");
  }
};
