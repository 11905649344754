export const training = [
  {
    name: "Abraham Pay For",
    percent: 87,
  },
  {
    name: "Linguina Pay For",
    percent: 78,
  },
  {
    name: "Valentino Pay For",
    percent: 62,
  },
  {
    name: "Dianne Pay For",
    percent: 43,
  },
  {
    name: "Ursula Pay For",
    percent: 20,
  },
];

const Lesson1 = () => {
  return (
    <>
      <ul style={{ listStyleType: "disc", padding: 20, fontSize: 18 }}>
        <li>
          You must watch the full video to progress to the next section of the
          course.
        </li>
        <li>A score of 90% or above in the exam is required to pass.</li>
        <li>You can resit the exam as many times as you wish.</li>
      </ul>
    </>
  );
};

const Lesson4 = () => {
  return (
    <div className="p-2">
      <style>
        {` .odd-row {
        background-color: #f3f3f3;
      }
      .even-row {
        background-color: #ffffff;
      }`}
      </style>
      <span className="pl-4 text-[20px] text-[black] font-bold">
        Instructions on how to use Hot Oil Tester
      </span>
      <div className="pl-5 pt-1">
        <ul className="list-disc">
          <li>
            Inspect the system visually before each measurement. If any damage
            is visible, do not use the device. Only use the device in systems
            that are electrically tested on a regular basis.
          </li>
          <li>
            Wear your personal protective equipment during each measurement to
            avoid injury.
          </li>
          <li>
            To avoid burns, never touch the sensor or the metal shaft directly
            after the measurement as these parts can become very hot when they
            are immersed into hot oil.
          </li>
        </ul>{" "}
      </div>
      <br />
      <span className="text-[20px] font-bold text-[black]">
        Important notes to achieve the most accurate results
      </span>
      <div className="pl-5 pt-1">
        <ul className="list-disc">
          <li>
            During the measurement, there should not be any fried food in the
            oil/fat. After taking out the fried items, wait at least 5 minutes
            before making a measurement.
          </li>
          <li>
            If you would like to measure in an induction cooker, switch it off
            as its electromagnetic field can falsify the result.
          </li>
          <li>
            Stir the oil/fat briefly with the device to ensure an even
            temperature.
          </li>
          <li>
            Avoid direct contact with metal objects such as cooking pots or
            frying baskets as this can influence the measurement result.Minimum
            distance: 1 cm.
          </li>
          <li>Replace the oil/fat when a PC value of 24 % is reached.</li>
        </ul>
      </div>
      <br />
      <span className="text-[20px] font-bold text-[black]">
        Press and release the on / off key to switch on the device.
      </span>
      <div className="pl-5 pt-1">
        <ol className="list-decimal">
          <li>The temperature display will show “Lo”.</li>
          <li>The PC display will show “- – -“.</li>
          <li>
            Insert the sensor into the hot oil so that the oil level is between
            the minimum and maximum lines on the metal shaft.
          </li>
          <li>
            When the temperature indication flashes and shows “HI”, the oil/fat
            is too hot and exceeds the upper end of the measurement range of 200
            °C.
          </li>
          <li>
            When the temperature indication flashes and shows “Lo”, the oil/fat
            is too cold and is below the lower end of the measurement range of
            30 °C.
          </li>
          <li>
            When the PC indication flashes and shows “Lo,” the oil/fat used is
            not suitable to be tested with the Oil Tester
          </li>
        </ol>
      </div>
      <br />

      <span className="text-[20px] font-bold text-[black]">
        Interpretating Measured Values
      </span>

      <table className="w-full border border-gray-400 mt-4">
        <tbody>
          <tr className="odd-row">
            <td className="w-10/12 px-4 py-2">
              Oil is new and good to use (LED Flashes in Green){" "}
            </td>
            <td className="w-2/12 px-4 py-2 text-right">
              <b>0 …. 18.5%</b>
            </td>
          </tr>
          <tr className="even-row">
            <td className="px-4 py-2">
              Oil quality classified as critical (LED Flashes in BLUE)It is
              recommended to renew the oil partly or completely
            </td>
            <td className="px-4 py-2 text-right">
              <b>19 …. 24%</b>
            </td>
          </tr>
          <tr className="odd-row">
            <td className="w-9/12 px-4 py-2">
              The oil in the fryer should no longer be used.The set threshold
              value (default setting:24% has been exceeded and the LED Flashed
              in red. The oil must be exchanged completeled)
            </td>
            <td className="w-3/12 px-4 py-2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const courseProgress = [
  {
    title: "Introduction to Halcyon Eco",
    title1: "Introduction to Halcyon Eco",
    des: "Please watch the entire video to proceed to the next section",
    video:
      "https://wiz.halcyon-eco.com/videos/course/course1/halcyon-training-video-1.mp4",
    progress: "00:33",
    complete: false,
    videoid: "",
  },
  {
    title: "Machine Components",
    title1: "Machine Components",
    des: "Please watch the entire video to proceed to the next section",
    video:
      "https://wiz.halcyon-eco.com/videos/course/course1/halcyon-training-video-2.mp4",
    progress: "01:57",
    complete: false,
    videoid: "Rijv7PhzgG4",
  },
  {
    title: "Safety Features",
    title1: "Safety Features",
    des: "Please watch the entire video to proceed to the next section",
    video:
      "https://wiz.halcyon-eco.com/videos/course/course1/halcyon-training-video-3.mp4",
    progress: "01:08",
    complete: false,
    videoid: "r8Gnr-qCtLE",
  },
  {
    title: "General Overview",
    title1: "General Overview",
    des: "Please watch the entire video to proceed to the next section",
    video:
      "https://wiz.halcyon-eco.com/videos/course/course1/halcyon-training-video-4.mp4",
    progress: "03:02",
    complete: false,
    videoid: "r8Gnr-qCtLE",
  },
  {
    title: "Filtration Preparation",
    title1: "Filtration Preparation",
    des: "Please watch the entire video to proceed to the next section",
    video:
      "https://wiz.halcyon-eco.com/videos/course/course1/halcyon-training-video-5.mp4",
    progress: "01:47",
    complete: false,
    videoid: "",
  },
  {
    title: "Filtration Process",
    title1: "Filtration Process",
    des: "Please watch the entire video to proceed to the next section",
    video:
      "https://wiz.halcyon-eco.com/videos/course/course1/halcyon-training-video-6.mp4",
    progress: "04:34",
    complete: false,
    videoid: "",
  },
  {
    title: "Testing the Oil",
    title1: "Testing the Oil",
    des: "Please watch the entire video to proceed to the next section",
    video:
      "https://wiz.halcyon-eco.com/videos/course/course1/halcyon-training-video-7.mp4",
    progress: "00:27",
    complete: false,
    videoid: "",
  },
  {
    title: "Removing Expired Oil",
    title1: "Removing Expired Oil",
    des: "Please watch the entire video to proceed to the next section",
    video:
      "https://wiz.halcyon-eco.com/videos/course/course1/halcyon-training-video-8.mp4",
    progress: "00:55",
    complete: false,
    videoid: "",
  },
  {
    title: "Cleaning the machine",
    title1: "Cleaning the machine",
    des: "Please watch the entire video to proceed to the next section",
    video:
      "https://wiz.halcyon-eco.com/videos/course/course1/halcyon-training-video-9.mp4",
    progress: "02:16",
    complete: false,
    videoid: "",
  },
  {
    title: "KeyPoints to Remember",
    title1: "KeyPoints to Remember",
    des: "Please watch the entire video to proceed to the next section",
    video:
      "https://wiz.halcyon-eco.com/videos/course/course1/halcyon-training-video-10.mp4",
    progress: "00:25",
    complete: false,
    videoid: "",
  },
  {
    title: "Examination",
    title1: "Examination",
    des: "",
    video: "",
    progress: "05:00",
    complete: false,
    videoid: "",
  },
  {
    title: "Certification",
    title1: "Certification",
    des: "",
    video: "",
    progress: "",
    complete: false,
    videoid: "",
  },
];

export const courseProgress1 = [
  {
    title: "Intro",
    title1: "Introduction to Halcyon Eco",
    des: "This lesson outlines an introduction to the Halcyon Eco training course.",
    video:
      "https://wiz.halcyon-eco.com/videos/course/course1/halcyon-training-video-1.mp4",
    progress: "00:33",
    complete: false,
  },
  {
    title: "How to operate Halcyon Eco",
    title1: "How to operate Halcyon Eco",
    des: "How ot operate to the Halcyon Eco training course.",
    video: "https://www.youtube.com/watch?v=UD10qO3823k",
    progress: "03:15",
    complete: false,
  },
  {
    title: "Safety Precautions",
    title1: "Safety Precautions",
    des: "Safety Precautions",
    video: "https://www.youtube.com/watch?v=CNjGNCLSbDc",
    progress: "02:40",
    complete: false,
  },
  {
    title: "Testing the oil",
    title1: "Testing the oil",
    des: "how to test Testing the oil",
    video: "https://www.youtube.com/watch?v=9DzGVMkeAeA",
    progress: "02:34",
    complete: false,
  },
  {
    title: "Examination",
    title1: "Examination",
    des: "how to take Examination",
    video: "https://www.youtube.com/watch?v=9DzGVMkeAeA",
    progress: "03:12",
    complete: false,
  },
  {
    title: "Certification",
    title1: "Certification",
    des: "how to get Certification",
    video: "https://www.youtube.com/watch?v=CNjGNCLSbDc",
    progress: "",
    complete: false,
  },
];
