import React from 'react';

const SearchBar = () => {
    return (
        <div className='relative mx-auto w-full sm:max-w-md'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                <img
                    src='/images/search-icon.svg'
                    alt=''
                    className='w-5 h-5 text-gray-400'
                />
            </div>
            <input
                type='text'
                placeholder='Search'
                className='w-full py-2.5 pl-10 pr-4 rounded-2xl text-sm text-gray-700 bg-white focus:outline-none focus:shadow-outline'
                style={{
                    background: '#FFF',
                    boxShadow: '0px 20px 100px 0px rgba(85, 85, 85, 0.08)',
                }}
            />
        </div>
    );
};

export default SearchBar;
