import React from "react";
import { NavbarPolicies } from "../privacy-policy";

const TermsOfService = () => {
  return (
    <>
      <NavbarPolicies />
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="mt-5 mb-5 bg-white shadow-md rounded-lg p-8 max-w-3xl w-full">
          <h1 className="text-2xl font-bold text-center mb-4">
            Terms of Service
          </h1>
          <p>
            <strong>Effective Date: 16 October 2023</strong>
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">1. Introduction</h2>
          <p>
            Welcome to Halcyon Eco Limited ("we," "us," "our"). These Terms of
            Service ("Terms") govern your access and use of our platform, which
            provides restaurant owners and employees with data from Halcyon Eco
            Oil Filtration machines, along with training and certification
            functionalities. By using our platform, you agree to comply with
            these Terms. If you do not agree, you must not use the platform.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">2. Eligibility</h2>
          <p>
            By accessing or using the platform, you confirm that you are at
            least 18 years old, or the legal age of majority in your
            jurisdiction, and capable of entering into binding agreements.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            3. Account Registration and Responsibilities
          </h2>
          <p>
            To access certain features of the platform, you must register for an
            account. You agree to provide accurate and complete information
            during registration and to keep your account credentials
            confidential. You are responsible for all activities that occur
            under your account. If you suspect unauthorised access to your
            account, notify us immediately.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">4. Platform Use</h2>
          <p>
            You agree to use the platform only for lawful purposes and in
            compliance with these Terms. Prohibited actions include, but are not
            limited to:
          </p>
          <ul className="list-disc list-inside">
            <li>Tampering with or bypassing platform security features.</li>
            <li>
              Using the platform to collect or store personal data of others
              without their consent.
            </li>
            <li>
              Reverse-engineering or decompiling any part of the platform.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            5. Fees and Payments
          </h2>
          <p>
            Certain features of the platform may require payment. If you choose
            to access paid services, you agree to pay all applicable fees in
            accordance with the pricing and payment terms provided at the time
            of purchase. All fees are non-refundable unless otherwise specified.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            6. Intellectual Property
          </h2>
          <p>
            All content, software, and materials on the platform, including
            text, graphics, logos, and code, are owned by or licensed to Halcyon
            Eco Limited and are protected by intellectual property laws. You are
            granted a limited, non-exclusive, non-transferable licence to access
            and use the platform solely for its intended purposes. You must not
            copy, modify, distribute, or create derivative works from the
            platform without our prior written consent.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            7. Data and Privacy
          </h2>
          <p>
            By using the platform, you consent to the collection and use of your
            personal data as outlined in our Privacy Policy. You agree to
            provide only accurate data and understand that we will process your
            data in accordance with GDPR and CCPA standards.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            8. Service Availability and Modifications
          </h2>
          <p>
            We strive to keep the platform available at all times, but we cannot
            guarantee uninterrupted access. We reserve the right to modify,
            suspend, or discontinue any part of the platform at any time without
            notice. We are not liable for any disruption or loss of access to
            the platform.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            9. Limitation of Liability
          </h2>
          <p>
            To the maximum extent permitted by law, Halcyon Eco Limited and its
            affiliates shall not be liable for any direct, indirect, incidental,
            or consequential damages arising out of or in connection with your
            use of the platform. This includes, but is not limited to, loss of
            profits, data, or business interruptions, even if we have been
            advised of the possibility of such damages.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            10. User-Generated Content
          </h2>
          <p>
            The platform may allow users to submit content, such as training
            completion data. You retain ownership of any content you submit but
            grant us a worldwide, non-exclusive, royalty-free licence to use,
            modify, and display such content in connection with the operation of
            the platform. We reserve the right to remove any content that
            violates these Terms or is deemed inappropriate.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">11. Termination</h2>
          <p>
            We reserve the right to terminate or suspend your account at our
            sole discretion for violation of these Terms or for any reason
            without prior notice. Upon termination, your right to use the
            platform will cease immediately.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">12. Governing Law</h2>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of Ireland, without regard to its conflict of law
            provisions. Any disputes arising from these Terms or your use of the
            platform shall be resolved exclusively in the courts of Ireland.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            13. Amendments to Terms
          </h2>
          <p>
            We may update or revise these Terms from time to time. Any changes
            will be posted on this page, and we will notify you via email or
            through the platform of any significant updates. Continued use of
            the platform after changes to the Terms indicates acceptance of the
            revised Terms.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            14. Contact Information
          </h2>
          <p>
            If you have any questions or concerns about these Terms, please
            contact us at:
            <br />
            <strong>Email:</strong> info@halcyon-eco.com
            <br />
            <strong>Address:</strong> Unit 1, Racecourse Road, Roscommon Town,
            Roscommon, Ireland
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
