import React, { useEffect, useState } from 'react';

export default function ProfilePicture ({ user }) {
  const [imageRatio, setImageRatio] = useState(1);
  const [image, setImage] = useState('');
  
  useEffect(() => {
    const imageUrl =
      user?.picture === 'null'
        ? '/images/blank-profile-picture.png'
        : user?.picture;
    
    const img = new Image();
    
    img.onload = () => {
      setImage(img);
      setImageRatio(img.width / img.height);
      // console.log({ imageRatio });
      // console.log(img.width);
    };
    
    img.src = imageUrl;
  }, [imageRatio, user]);
  
  return (
    <svg
      className='h-[164px] w-[164px] m-auto '
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      data-name='Layer 1'
      viewBox='0 0 165 174'
    >
      <defs>
        <linearGradient
          id='a'
          x1={-57.37}
          x2={136.05}
          y1={278.66}
          y2={16.39}
          gradientTransform='matrix(1 0 0 -1 0 176)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#112844' />
          <stop offset={1} stopColor='#e3a410' />
        </linearGradient>
        <clipPath id='b'>
          <circle
            cx={85.46}
            cy={87}
            r={76.54}
            style={{
              fill: 'none',
              position: 'absolute',
              objectFit: 'none'
            }}
          />
        </clipPath>
      </defs>
      <path
        d='M1.5 87c0-47.3 36.34-85.5 81-85.5s81 38.2 81 85.5-36.34 85.5-81 85.5-81-38.2-81-85.5Z'
        style={{
          fill: 'none',
          stroke: 'url(#a)',
          strokeWidth: 3
        }}
      />
      <circle
        cx={87}
        cy={87}
        r={75}
        style={{
          fill: 'none'
        }}
      />
      <image
        href={
          user?.picture === 'null'
            ? '/images/blank-profile-picture.png'
            : user?.picture
        }
        width={imageRatio <= 1 ? 175 : 175 * imageRatio}
        height={imageRatio >= 1 ? 175 : 175 * (1 / imageRatio)}
        style={{
          clipPath: 'url(#b)',
          objectFit: 'cover', // Disable default object-fit behavior
          position: 'absolute'
          // left: "50%",
          // top: "50%",
          // transform: "translate(-50%, -50%)", // Center the image within the SVG
        }}
      />
    </svg>
  );
}
