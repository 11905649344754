// src/Table.js
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useSortData from "../../hooks/useSortData";
import SortButton from "../sort-button";
import Spinner from "../UI/Spinner";
import { useRestaurantStats } from "../../services/locations/queries";
import { ModalTypes } from "../all-modal-window/modal-window";

function TableOrganisations() {
  const { organization_id } = useParams();

  const { data: organizationLocations, isLoading: isLoadingRestaurantStats } =
    useRestaurantStats(organization_id);

  return (
    <Table data={organizationLocations} isLoading={isLoadingRestaurantStats} />
  );
}

function Table({ data, isLoading }) {
  const { sortedData, sortData, sortDataNum, sortParam } = useSortData(data);
  const location = useLocation();

  const handleSort = (param) => {
    sortData(param);
  };

  const handleSortNum = (param) => {
    sortDataNum(param);
  };

  const encodeURL = (item) => {
    return location.pathname + `/loc/${item.id}`;
  };

  if (isLoading) {
    return (
      <Spinner
        size={"w-10 h-10 mt-10"}
        color={"text-white-200 dark:text-white-600"}
      />
    );
  }

  return (
    <div className="table-container overflow-auto w-full mt-8">
      <table className="divide-y-8 divide-[#F4F4F4] w-full">
        <thead>
          <tr>
            <th className="pl-4 whitespace-nowrap px-2 py-2 text-left md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span className="mr-2">Location Name</span>
              <SortButton
                handlerToggler={handleSort}
                text="A - Z"
                sortBy="location_name"
              />
            </th>
            <th className="min-w-[220px] px-2 py-2 text-left md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span className="mr-2"> Address</span>
              <SortButton
                handlerToggler={handleSort}
                text="A - Z"
                sortBy="name"
              />
            </th>
            <th className="px-2 py-2 text-left md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span
                className="mr-2"
                onClick={() => handleSortNum("trainings_complete")}
              >
                Trainings Completed
              </span>
            </th>
            <th className="px-2 py-2 text-center md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span
                className="mr-2"
                onClick={() => handleSortNum("courses_complete")}
              >
                Courses Passed
              </span>
            </th>
            <th className="px-2 py-2 text-center md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span
                className="mr-2"
                onClick={() => handleSortNum("total_employees")}
              >
                Total No. of Employees
              </span>
            </th>
            <th className="px-2 py-2 text-center md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider"></th>
          </tr>
        </thead>

        <tbody className="divide-y-8 divide-[#F4F4F4]">
          {sortedData?.map((item) => (
            <tr className="bg-white rounded-lg" key={item?.id}>
              <td className="px-6 py-3 whitespace-nowrap rounded-l-full">
                <div className="flex gap-3">
                  <img
                    className="cursor-pointer"
                    // onClick={() =>
                    //   handlerCickButtonForOpenEditModal(
                    //     item.id,
                    //     ModalTypes.EDIT_ORGANIZATION
                    //   )
                    // }
                    src="/images/pencil.svg"
                    alt=""
                  />
                  <div className="text-base font-medium text-[#1E3A56]">
                    {item?.name}
                  </div>
                </div>
              </td>
              {/*<td className='px-2 pl-4 py-3 whitespace-nowrap rounded-l-full'>*/}
              {/*  <img*/}
              {/*    className='cursor-pointer'*/}
              {/*    // onClick={() =>*/}
              {/*    //   handlerCickButtonForOpenEditModal(*/}
              {/*    //     item.id,*/}
              {/*    //     ModalTypes.EDIT_ORGANIZATION*/}
              {/*    //   )*/}
              {/*    // }*/}
              {/*    src='/images/pencil.svg'*/}
              {/*    alt=''*/}
              {/*  />*/}
              {/*  <div className='text-base font-medium text-[#1E3A56]'>*/}
              {/*    {item?.name}*/}
              {/*  </div>*/}
              {/*</td>*/}
              <td className="px-2 py-3 whitespace-nowrap">
                <div className="text-base text-[#1E3A56]">{item?.address}</div>
              </td>
              <td className="px-2 py-3 whitespace-nowrap">
                <div className="text-base text-[#1E3A56]  text-center">
                  {item?.trainings_complete}
                </div>
              </td>
              <td className="px-2 py-3 whitespace-nowrap">
                <div className="text-base text-[#1E3A56] text-center">
                  {item?.trainings_complete}
                </div>
              </td>
              <td className="px-2 py-3 whitespace-nowrap">
                <div className="text-base text-[#1E3A56] text-center">
                  {item?.total_employees}
                </div>
              </td>
              <td className="px-2 py-3 whitespace-nowrap rounded-r-full">
                <div className="text-base text-[#1E3A56] flex justify-center">
                  {item?.total_employees > 0 ? (
                    <Link to={encodeURL(item)}>
                      <button className="w-[80px] h-[30px]  flex justify-center items-center font-ABeeZee  text-xs font-normal text-[#8597A8] border border-[#8597A8] rounded-[7px] py-3 px-6">
                        View
                      </button>
                    </Link>
                  ) : null}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableOrganisations;
