import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/sidebar';
import TableOrganisations from '../../components/table-organisations';
import Drawer from '../../components/drawer';
import { useRestaurantStats } from '../../services/locations/queries';
import { useUserStore } from '../../storesZustand/useUserStore';

function valuetext (value) {
  return `${value}°C`;
}

const SuperAdmin = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [user] = useUserStore(state => [
    state.user
  ]);
  
  const { data: locations, refetch } = useRestaurantStats();
  console.log(locations);
  // useEffect(() => {
  //   refetch();
  // }, []);
  
  useEffect(() => {
    if (locations) setFilteredData(locations);
  }, [locations]);
  
  const searchHandler = (event) => {
    const query = event?.target?.value;
    if (query)
      setFilteredData(
        locations.filter(
          (item) =>
            item?.name?.toLowerCase().includes(query) ||
            item?.address?.toLowerCase().includes(query)
        )
      );
    else {
      setFilteredData(locations);
    }
  };
  
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <React.Fragment>
      <div className='w-[100%]'>
        <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
        <div className='w-[250px] xl:flex hidden'>
          <Sidebar />
        </div>
        <div className=' xl:w-[calc(100%-240px)] w-[100%] xl:ml-[240px] bg-[#f7f7f7] flex justify-between h-screen'>
          <div className='w-[100%]  md:px-[47px] px-[20px] pt-[35px] pb-[100px]'>
            <div className='flex justify-between'>
              <svg
                onClick={() => toggleDrawer()}
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-8 h-8 cursor-pointer xl:hidden flex'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12'
                />
              </svg>
            </div>
            <div className=''>
              <p className='text-[32px] font-bold'>
                {user?.organization_name} - Training Status
              </p>
              <div className='grid grid-cols-12 space-x-14 mt-[20px]'>
                <div className='col-span-11'>
                  <div
                    className='py-[12px] px-10  rounded-xl text-[#32383F] font-bold text-[15px] bg-white flex  flex-col'
                    style={{
                      boxShadow: '0px 20px 100px 0px rgba(85, 85, 85, 0.05)'
                    }}
                  >
                    Search for a business/ organisation...
                    <input
                      type='text'
                      className='text-gray-600 rounded indent-7 search-input mt-3 h-[30px]'
                      placeholder='Halcyon Eco'
                      onChange={searchHandler}
                    />
                  </div>
                  <TableOrganisations data={filteredData} />
                </div>
                {/* <div className="col-span-3">
                  <div className="training-box1">
                    <div className="bg-[white] rounded-full h-[44px] w-[44px] flex justify-center items-center">
                      <img src="/images/Frame (10).svg" alt="" />
                    </div>
                    <p className="text-[#C6C6C6] mt-2">
                      Total Savings by
                      <br /> Halcyon Eco
                    </p>
                    <p className="text-[30px] font-bold  text-white">
                      €99,900.00
                    </p>
                  </div>
                </div> */}
                {/* <div className="col-span-3">
                  <div className="registered-box1">
                    <div className="bg-[#DBDBDB] rounded-full h-[44px] w-[44px] flex justify-center items-center">
                      <img src="/images/Frame (11).svg" alt="" />
                    </div>
                    <p className="text-[#787878] pt-[20px]">
                      Total Litres of Oil Saved
                    </p>
                    <p className="text-[30px] font-bold">143,556</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SuperAdmin;
