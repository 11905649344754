// src/Table.js
import React from "react";
import SortButton from "../sort-button";
import useSortData from "../../hooks/useSortData";
import { Link } from "react-router-dom";
import { useOrganizationData } from "../../services/organization/queries";

function TableEmployeesSummary() {
  const { data: organizationData, error: errorOrganization } =
    useOrganizationData();
  const selectedOption = "All Employees"; // TODO: Temp

  return <Table data={organizationData} selectedOption={selectedOption} />;
}

export default TableEmployeesSummary;

const Table = ({ data, selectedOption }) => {
  const { sortedData, sortData, sortDataNum, sortParam } = useSortData(data);

  const handleSort = (param) => {
    sortData(param);
  };

  const handleSortNum = (param) => {
    sortDataNum(param);
  };

  return (
    <div className="table-container overflow-auto w-full mt-8">
      <table className="divide-y-8 divide-[#F4F4F4] w-full">
        <thead>
          <tr>
            <th className="pl-4 whitespace-nowrap px-2 py-2 text-left md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span className="mr-2">Name</span>
              <SortButton
                handlerToggler={handleSort}
                text="A - Z"
                sortBy="name"
              />
            </th>
            <th className="min-w-[220px] px-2 py-2 text-left md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span className="mr-2">Address</span>
              <SortButton
                handlerToggler={handleSort}
                text="A - Z"
                sortBy="address1"
              />
            </th>
            <th className="px-2 py-2 text-center md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span
                className="mr-2"
                onClick={() => handleSortNum("courses_complete")}
              >
                Courses Complete
              </span>
            </th>
            <th className="px-2 py-2 text-center md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span
                className="mr-2"
                onClick={() => handleSortNum("num_employees")}
              >
                Total No. of Employees
              </span>
            </th>
            <th className="px-2 py-2 text-center md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span
                className="mr-2"
                onClick={() => handleSortNum("num_locations")}
              >
                Locations
              </span>
            </th>
            <th className="px-2 py-2 "></th>
          </tr>
        </thead>

        <tbody className="divide-y-8 divide-[#F4F4F4]">
          {sortedData
            ?.filter((person) => {
              return (
                selectedOption === "All Employees" ||
                person.name === selectedOption
              );
            })
            .map((item) => (
              <tr className="bg-white rounded-lg" key={item?.id}>
                <td className="px-2 pl-4 py-3 whitespace-nowrap rounded-l-full">
                  <div className="text-base font-medium text-[#1E3A56]">
                    {item?.name}
                  </div>
                </td>
                <td className="px-2 py-3 whitespace-nowrap">
                  <div className="text-base text-[#1E3A56]">
                    {(item?.address1 || "") +
                      (item?.address1 && item?.billing_city ? ", " : "") +
                      (item?.billing_city || "")}
                  </div>
                </td>
                <td className="px-2 py-3 whitespace-nowrap">
                  <div className="text-base text-[#1E3A56]  text-center">
                    {item?.courses_complete}
                  </div>
                </td>
                <td className="px-2 py-3 whitespace-nowrap">
                  <div className="text-base text-[#1E3A56] text-center">
                    {item?.num_employees}
                  </div>
                </td>
                <td className="px-2 py-3 whitespace-nowrap">
                  <div className="text-base text-[#1E3A56] text-center">
                    {item?.num_locations}
                  </div>
                </td>
                <td className="px-2 py-3 whitespace-nowrap">
                  {item?.num_locations > 0 ? (
                    <div className="text-base text-[#1E3A56] text-center">
                      <Link to={`/training/org/${item?.id}`}>
                        <button className="w-[80px] h-[30px]  flex justify-center items-center font-ABeeZee  text-xs font-normal text-[#8597A8] border border-[#8597A8] rounded-[7px] py-3 px-6">
                          View
                        </button>
                      </Link>
                    </div>
                  ) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
