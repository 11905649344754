import React from "react";
import PDFVisualiser from "./pdf-visualiser/index";
import { useCovertToPDFData } from "./pdf-context/useCovertToPDFData";
import "./index.css";

const PDFDownloader = ({
  data,
  handleDownload,
  handleClose,
  setLoadingPDF,
}) => {
  const [monthArray, currency] = useCovertToPDFData(data);

  return (
    <React.Fragment>
      {monthArray?.length > 0 ? (
        <PDFVisualiser
          monthArray={monthArray}
          currency={currency}
          handleClose={handleClose}
          setLoadingPDF={setLoadingPDF}
        />
      ) : (
        <span className="text-red-500">
          <svg
            onClick={handleDownload}
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 24 24"
            height="52px"
            width="52px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023.479 0 .774-.242.774-.651 0-.366-.254-.586-.704-.586zm3.487.012c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018.817.006 1.349-.444 1.349-1.396.006-.83-.479-1.268-1.255-1.268z"></path>
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.498 16.19c-.309.29-.765.42-1.296.42a2.23 2.23 0 0 1-.308-.018v1.426H7v-3.936A7.558 7.558 0 0 1 8.219 14c.557 0 .953.106 1.22.319.254.202.426.533.426.923-.001.392-.131.723-.367.948zm3.807 1.355c-.42.349-1.059.515-1.84.515-.468 0-.799-.03-1.024-.06v-3.917A7.947 7.947 0 0 1 11.66 14c.757 0 1.249.136 1.633.426.415.308.675.799.675 1.504 0 .763-.279 1.29-.663 1.615zM17 14.77h-1.532v.911H16.9v.734h-1.432v1.604h-.906V14.03H17v.74zM14 9h-1V4l5 5h-4z"></path>
          </svg>
        </span>
      )}
    </React.Fragment>
  );
};

export default PDFDownloader;
