import { getCurrencySymbol } from "./helpers";

export const downloadCSV = (data) => {
  const csvHeadersOg = ["Date", "litresSaved", "savingsCarbon", "savings"];
  const csvHeaders = [
    "Date",
    "Litre Saved (L)",
    "Carbon Saved (kg CO2e)",
    `Savings (${getCurrencySymbol(data.currency)})`,
  ];
  const csvData = [csvHeaders.join(",")];

  data = data.data;

  data.forEach((item) => {
    const row = csvHeadersOg.map((header) => item[header]);
    csvData.push(row.join(","));
  });

  const csvBlob = new Blob([csvData.join("\n")], { type: "text/csv" });

  const csvURL = window.URL.createObjectURL(csvBlob);

  const a = document.createElement("a");
  a.style.display = "none";
  a.href = csvURL;
  a.download = `Halcyon Summary.csv`;
  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(csvURL);
  document.body.removeChild(a);
};
