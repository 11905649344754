import * as React from "react";
const SVGExamResult = ({ result }) => {
  const fraction = result.split("/");

  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 451.78 451.78"
        width="95"
      >
        <circle
          cx={225.89}
          cy={225.89}
          r={216.89}
          style={{
            fill: "rgba(0,0,0,0)",
            stroke: "#d31111",
            strokeMiterlimit: 10,
            strokeWidth: 18,
          }}
        />
        <path
          d="m116.78 328.55 208-208"
          style={{
            fill: "none",
            stroke: "#d31111",
            strokeMiterlimit: 10,
            strokeWidth: 16,
          }}
        />
        <text
          style={{
            fill: "#d31111",
            fontFamily: "Roboto-Medium,Roboto",
            fontSize: "135.97px",
          }}
          textAnchor="middle"
          transform="matrix(1.05 0 0 1 150 204.39)"
        >
          <tspan x={0} y={0}>
            {fraction?.[0]}
          </tspan>
        </text>
        <text
          style={{
            fill: "#d31111",
            fontFamily: "Roboto-Medium,Roboto",
            fontSize: "135.97px",
          }}
          textAnchor="middle"
          transform="matrix(1.05 0 0 1 300.31 334.74)"
        >
          <tspan x={0} y={0}>
            {fraction?.[1]}
          </tspan>
        </text>
      </svg>
    </div>
  );
};
export default SVGExamResult;
