import { useMutation, useQueryClient } from 'react-query';
import api from '../../utils/api';
import { toast } from 'react-toastify';

export function useDeviceCreateMutation () {
  const token = localStorage.getItem('token');
  const queryClient = useQueryClient();
  return useMutation(
    (deviceData) =>
      api.post('/device/create', deviceData, {
        headers: {
          'x-auth-token': token
        }
      }),
    {
      onSuccess: (data) => {
        toast.success('Device is created');
        queryClient.invalidateQueries('dashboardData');
      },
      onError: (error) => {
        toast.error(`${error?.response?.data.message ? error?.response?.data.message : error}!`);
      }
    }
  );
}

export function useDeviceUpdateMutation () {
  const queryClient = useQueryClient();
  
  return useMutation(
    'deviceUpdateMutation',
    (arg) => {
      const response = api.put(`device/update/${arg.id}`, arg.data);
      if (response) {
        arg.handleClose();
      }
    },
    {
      onSuccess: async (data, arg) => {
        toast.success('Device is updated successfully');
        const invalidateDevicesByOneLocationPromise = queryClient.invalidateQueries(['devicesByOneLocation']);
        const invalidateDashboardDataaPromise = queryClient.invalidateQueries('dashboardData');
        await Promise.all([invalidateDashboardDataaPromise, invalidateDevicesByOneLocationPromise]);
        
        //
      },
      onError: (error) => {
        toast.error(`${error?.response?.data.message ? error?.response?.data.message : error}!`);
      }
    }
  );
}

