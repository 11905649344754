import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar";
import { courseProgress } from "../../data";
import Drawer from "../../components/drawer";
import SidebarCoursePortal from "./SidebarCoursePortal";
import Quiz from "./Quiz";
import Certification from "./Certification";
import LessonContent from "./LessonContent";
import api from "../../utils/api";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useUserStore } from "../../storesZustand/useUserStore";

const ExamTrainingPortal = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const [course, setCourse] = useState([...courseProgress]);
  const [completeProgress, setCompleteProgress] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [user] = useUserStore((state) => [state.user]);
  const [coursesProgressId, setCoursesProgressId] = useState();
  const token = localStorage.getItem("token");
  const [hasWatchedVideo, setHasWatchedVideo] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post(
          "/coursesProgress/getAll",
          {
            user_id: user?.id,
          },
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );

        const currentProgress = response?.data?.data[0]?.progress || 0;
        setCoursesProgressId(response?.data?.data[0]?.id);

        setCompleteProgress(currentProgress);
        setActiveIndex(currentProgress);

        const updatedCourse = courseProgress.map((lesson, i) => {
          if (i < currentProgress) {
            return { ...lesson, complete: true };
          }
          return lesson;
        });

        setCourse(updatedCourse);
      } catch (error) {
        // Handle error here
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user?.id, courseProgress]); // Added courseProgress dependency

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleLessonClick = (ind) => {
    if (ind >= 0 && ind <= completeProgress && ind < courseProgress.length) {
      setActiveIndex(ind);

      if (ind > 0) {
        const updatedCourse = [...course];
        updatedCourse[ind - 1].complete = true;

        setCourse([...updatedCourse]);
      }
    }
  };

  const handleMarkComplete = async () => {
    if (
      activeIndex + 1 > completeProgress &&
      (hasWatchedVideo || activeIndex > courseProgress.length - 3)
    ) {
      if (activeIndex > courseProgress.length) {
        return;
      }
      setCompleteProgress(activeIndex + 1);
      const updatedCourse = [...course];
      updatedCourse[activeIndex].complete = true;
      setCourse(updatedCourse);

      api
        .patch(
          `/coursesProgress/update/${coursesProgressId}`,
          {
            course_id: 1,
            user_id: user?.id,
            progress: activeIndex + 1,
            organization_id: user?.organization_id,
          },
          {
            headers: {
              "x-auth-token": token,
            },
          }
        )
        .then((res) => {
          // console.log("completeProgressres", res);
          // console.log("completeProgress", res.data);
        });
    } else {
      if (activeIndex < courseProgress.length - 2) setShowModal(true);
    }
  };

  const handleNext = () => {
    setActiveIndex(activeIndex + 1);
  };

  const handlePrevious = () => {
    if (activeIndex !== 0) setActiveIndex(activeIndex - 1);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <div className="w-[100%]">
        <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
        <div className="w-[250px] xl:flex hidden">
          <Sidebar />
        </div>
        <div className=" xl:w-[calc(100%-240px)] w-[100%] xl:ml-[240px] bg-[#f7f7f7] flex justify-between h-screen">
          <div className="w-[100%]  md:px-[47px] px-[20px] pt-[35px] pb-[100px]">
            <div
              className={`w-full h-[40px] flex flex-row justify-between items-center py-2 px-2 ${
                screenWidth <= 640 || screenWidth >= 1025 ? "hidden" : "block"
              }`}
            >
              <button onClick={() => toggleDrawer()}>
                <img src="/images/burger-open.svg" alt="burger" />
              </button>
            </div>
            {screenWidth < 640 && (
              <div className="fixed z-30 top-0 left-0 w-full h-[88px] bg-white flex flex-row justify-between items-center py-7 px-5">
                <button onClick={() => toggleDrawer()}>
                  <img src="/images/burger-open.svg" alt="burger" />
                </button>
              </div>
            )}
            <div className={`${screenWidth < 640 ? "mt-14" : ""}`}>
              <p className="text-[32px] font-bold text-[#32383F]">
                Training Portal
              </p>
              <div className="grid grid-cols-12 md:space-x-8 mt-9">
                <div className="md:col-span-7 col-span-12">
                  {/* Content Part */}
                  {activeIndex === courseProgress.length - 1 ? (
                    <Certification />
                  ) : activeIndex === courseProgress.length - 2 ? (
                    <Quiz
                      handleNext={handleNext}
                      completeProgress={completeProgress}
                      handleMarkComplete={handleMarkComplete}
                    />
                  ) : (
                    <LessonContent
                      hasWatchedVideo={hasWatchedVideo}
                      setHasWatchedVideo={setHasWatchedVideo}
                      completeProgress={completeProgress}
                      handleNext={handleNext}
                      handlePrevious={handlePrevious}
                      handleMarkComplete={handleMarkComplete}
                      lessons={courseProgress}
                      lessonNumber={activeIndex}
                    />
                  )}

                  {/*  */}
                </div>
                <SidebarCoursePortal
                  completeProgress={completeProgress}
                  course={course}
                  handleFunction={handleLessonClick}
                  activeindex={activeIndex}
                />
                {/* here */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            "&:focus": {
              outline: "none", // Remove the focus outline
            },
          }}
        >
          <Typography id="video-modal-title" variant="h6" component="h2">
            Attention
          </Typography>
          <Typography id="video-modal-description" sx={{ mt: 2 }}>
            You are required to watch the entire video to proceed to the next
            stage.
          </Typography>
          <Button
            variant="contained"
            sx={{
              mt: 3,
              backgroundColor: "#dc972f",
              color: "white",
              "&:hover": {
                backgroundColor: "#d8a561", // Remove the focus outline
              },
            }}
            onClick={handleCloseModal}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ExamTrainingPortal;
