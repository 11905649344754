/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

const DropdownDays = ({
    screenWidth,
    data,
    selectedOption,
    setSelectedOption,
    optionDefault,
}) => {
    const [isDropdownVisible, setDropdownVisibility] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisibility(!isDropdownVisible);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setDropdownVisibility(false);
    };
    return (
        <div
            className='relative rounded-xl'
            style={
                screenWidth < 640
                    ? {
                          boxShadow: 'rgba(85, 85, 85, 0.05) 3px 8px 14px 0px',
                      }
                    : {}
            }
        >
            <button
                id='dropdownPeriodButton'
                data-dropdown-toggle='dropdownPeriod'
                className='text-black bg-white border-none font-medium rounded-2xl text-sm px-5 py-2.5 text-center inline-flex items-center '
                type='button'
                style={{ width: 'max-content' }}
                onClick={toggleDropdown}
            >
                {selectedOption || optionDefault}{' '}
                <svg
                    className={`w-2.5 h-2.5 ms-3 ${
                        isDropdownVisible
                            ? 'transition duration-500 ease-in-out transform rotate-180'
                            : 'transition duration-500 ease-in-out transform rotate-0'
                    }`}
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 10 6'
                >
                    <path
                        stroke='black'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='m1 1 4 4 4-4'
                    />
                </svg>
            </button>

            <div
                id='dropdownPeriod'
                className={`absolute z-10 mt-2 bg-white divide-y divide-gray-100 rounded-2xl shadow w-44 ${
                    isDropdownVisible ? '' : 'hidden'
                }`}
            >
                <ul
                    className='py-2 text-sm text-gray-700'
                    aria-labelledby='dropdownDefaultButton'
                >
                    {optionDefault && (
                        <li>
                            <a
                                href='#'
                                className='block px-4 py-2 hover:bg-gray-100'
                                onClick={() =>
                                    handleOptionSelect(optionDefault)
                                }
                            >
                                {optionDefault}
                            </a>
                        </li>
                    )}
                    {data?.map((el) => (
                        <li key={el.id}>
                            <a
                                href='#'
                                className='block px-4 py-2 hover:bg-gray-100'
                                onClick={() => handleOptionSelect(el.name)}
                            >
                                {el.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DropdownDays;
