import api from "../../utils/api";

const analyticsService = {
  getAnalyticsOrganization: (id) => api.get(`organization/get-analytics/${id}`),
  getAnalyticsLocation: (id) => api.get(`location/get-analytics/${id}`),
  getAnalyticsDevice: (deviceId, organizationId) =>
    api.get(`device/get-analytics/${deviceId}/${organizationId}`),
};

export { analyticsService };
