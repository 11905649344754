import create from 'zustand';

export const useViewDasboardStore = create((set, get, api) => ({
  isOpen: false,
  isOpen1: false,
  displayModalWindowType: '',
  currentOpenIdForEditModalWindow: null,
  currentDawnloadExcelOrganizationIdForDevice: null,
  
  setIsOpen: (data) => {
    set({
      isOpen: data
    });
  },
  setIsOpen1: (data) => {
    set({
      isOpen1: data
    });
  },
  setCurrentOpenIdForEditModalWindow: (id) => {
    set({
      currentOpenIdForEditModalWindow: id
    });
  },
  
  setDisplayModalWindowType: (type) => {
    set({
      displayModalWindowType: type
    });
  },
  
  //Dawnload Excel device page
  setCurrentDawnloadExcelOrganizationIdForDevice: (id) => {
    set({
      currentDawnloadExcelOrganizationIdForDevice: id
    });
  }
  
}), {
  name: 'UseViewDashboardStore'
});