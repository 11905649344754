import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ModalSelect from '../../UI/modal-select';
import { useLocationStore } from '../../../storesZustand/useLocationStore';
import { useOrganizationStore } from '../../../storesZustand/useOrganizationStore';
import Spinner from '../../UI/Spinner';
import { fetchDeviceById } from '../../../services/devices/queries';
import { useDebounce } from '../../../hooks/useDebounce';
import { useDeviceStore } from '../../../storesZustand/useDeviceStore';
import { useDeviceCreateMutation, useDeviceUpdateMutation } from '../../../services/devices/mutation';
import { ModalTypes } from '../modal-window';
import { useLocationData } from '../../../services/location/queries';

const ModalDevice = ({ currentAuthUser, handleClose, type, currentIdForGetData }) => {
  const [filteredLocation, setFilteredLocation] = useState([]);
  const [findDevice, setFindDevice] = useState(null);
  const [findDeviceError, setFindDeviceError] = useState('');
  // const { locations, isLoading, isError } = useLocationData();
  
  const [locations] = useLocationStore(state => [state.locations]);
  const [organizations] = useOrganizationStore(state => [
    state.organizations
  ]);
  const [allDevices] = useDeviceStore(state => [
    state.allDevices
  ]);
  
  const { mutate: mutateCreateDevice, isLoading: isLoadingCreateDevice } = useDeviceCreateMutation();
  const { mutate: mutateUpdateDevice, isLoading: isLoadingUpdateDevice } = useDeviceUpdateMutation();
  
  let initialValues;
  
  if (type === ModalTypes.EDIT_DEVICE && currentIdForGetData) {
    initialValues = allDevices.find(item => item.id === currentIdForGetData);
  } else {
    initialValues = {
      location_id: '',
      name: '',
      organization_id: ''
    };
  }
  
  const fetchFindDeviceById = async (value) => {
    setFindDevice(null);
    try {
      const response = await fetchDeviceById(value);
      if (response.data.success) {
        const data = response?.data?.data;
        setFindDevice(data);
        setFindDeviceError('');
      }
    } catch (e) {
      setFindDevice(null);
      setFindDeviceError(e.response.data.message);
    }
  };
  
  const debouncedFetchDeviceById = useDebounce(fetchFindDeviceById, 400);
  
  const validationSchema = Yup.object().shape({
    organization_id: Yup.string().required('Organization is required'),
    location_id: Yup.string().required('Location is required'),
    name: Yup.string().required('Device name is required'),
    id: Yup.string().required('Device code is required')
  });
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      type === ModalTypes.ADD_DEVICE && !currentIdForGetData ?
        mutateCreateDevice(values, {
          onSuccess: (data) => {
            handleClose();
          },
          onError: (error) => {
          }
        })
        : mutateUpdateDevice({
          id: currentIdForGetData,
          handleClose,
          data: {
            ...values
          }
        });
    }
  });
  
  const handleCloseFunc = React.useCallback(() => {
    formik.resetForm();
    handleClose();
  }, [formik, handleClose]);
  
  useEffect(() => {
    const filtered = locations.filter(location => location.organization_id === formik.values.organization_id);
    setFilteredLocation(filtered);
  }, [formik.values.organization_id]);
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-col sm:flex-row relative sm:min-h-full'>
        <div className='flex-1 px-5 pt-5 pb-8 sm:p-10 flex flex-col gap-[35px] sm:gap-[50px]'>
          <div className='flex justify-between flex-row items-center'>
            <div className='flex flex-col gap-9 text-[#787878] text-[0.9]'>
              <div className='flex gap-4 items-center'>
                <div className='w-[40px] h-[40px]'>
                  <img
                    className=' w-full h-full'
                    src='/images/addDeviceIcon.svg'
                    alt='location'
                    style={{ fill: '#10223F' }}
                  />
                </div>
                <h2 className='text-[24px] font-bold text-[#32383F]'>
                  {type === ModalTypes.ADD_DEVICE ? ModalTypes.ADD_DEVICE : ModalTypes.EDIT_DEVICE}
                </h2>
              </div>
              <div className='flex'>
                <div className=''>Please fill out the information about the device.</div>
              </div>
            </div>
            <button className='self-start' onClick={handleCloseFunc}>
              <img src='/images/close.svg' alt='close' />
            </button>
          
          </div>
          
          <div className='flex flex-row gap-[35px] sm:gap-[50px]'>
            <div className='flex flex-col relative flex-1'>
              <label className='text-[15px] font-bold'>
                Organisation
                <span className='text-[#F91212]'>*</span>
              </label>
              <div className='w-full mt-5'>
                <ModalSelect
                  defaultOption={
                    type === ModalTypes.EDIT_DEVICE
                      ? (initialValues?.organization_id
                        ? organizations.find(item => item.id === initialValues.organization_id)
                        : null)
                      : null
                  }
                  data={organizations}
                  optionDefault='Organization'
                  formik={formik}
                  field='organization_id'
                  // dropListUp={true}
                />
              </div>
              {formik.touched.organization_id && formik.errors.organization_id && (
                <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                  {formik.errors.organization_id}
                </div>
              )}
            </div>
            <div className='flex flex-col relative mb-2 flex-1'>
              <label className='text-[15px] font-bold'>
                Location
                <span className='text-[#F91212]'>*</span>
              </label>
              <div className='w-full mt-5'>
                <ModalSelect
                  defaultOption={
                    type === ModalTypes.EDIT_DEVICE
                      ? (initialValues?.location_id
                        ? filteredLocation.find(item => item.id === initialValues.location_id)
                        : null)
                      : null
                  }
                  data={filteredLocation}
                  optionDefault='Location'
                  formik={formik}
                  field='location_id'
                  // dropListUp={true}
                />
              </div>
              {formik.touched.location_id && formik.errors.location_id && (
                <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                  {formik.errors.location_id}
                </div>
              )}
            </div>
          </div>
          <div className='flex flex-col gap-[30px] sm:gap-[40px] h-full'>
            <div className='flex flex-col gap-2 relative'>
              <label className='text-[15px] font-bold'>
                Device code/Serial Number
                <span className='text-[#F91212]'>*</span>
              </label>
              <div className='w-1/2 flex border-b-[1.3px] border-[#B8BBC2]'>
                <input
                  disabled={type === ModalTypes.EDIT_DEVICE}
                  className='w-4/5 p-1 text-[12px] border-none focus:outline-none'
                  type='text'
                  name='id'
                  placeholder='Device code'
                  onChange={(event) => {
                    if (type === ModalTypes.ADD_DEVICE) {
                      debouncedFetchDeviceById(event.target.value);
                      formik.handleChange(event);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.id}
                />
                <div className='w-1/5 flex items-center justify-center'>
                  {
                    formik.values.id && type === ModalTypes.ADD_DEVICE ? (
                      findDevice && !findDeviceError ? (
                        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={4}
                             stroke='green' className='w-4 h-4 transition-all duration-300'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 12.75l6 6 9-13.5' />
                        </svg>
                      ) : (
                        <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={4}
                             stroke='red' className='w-4 h-4 transition-all duration-300'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                        </svg>
                      )
                    ) : null
                  }
                </div>
              </div>
              {formik.touched.id && formik.errors.id && (
                <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                  {formik.errors.id}
                </div>
              )}
            </div>
            <div className='w-1/2 flex flex-col gap-2 relative'>
              <label className='text-[15px] font-bold'>
                Device Name
                <span className='text-[#F91212]'>
                                *
                 </span>
              </label>
              <input
                className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px] outline-none'
                type='text'
                name='name'
                placeholder='e.g Hilton Cork-Upstairs Kitchen'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.errors.name && (
                <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                  {formik.errors.name}
                </div>
              )}
            </div>
          </div>
          <div className='flex gap-[9px] self-end'>
            <button
              onClick={handleCloseFunc}
              className='text-[11px] rounded-[7px] border-[1px] border-[#E4A50E] text-[#E4A50E] px-4 py-1'
            >
              Go Back
            </button>
            <button
              disabled={type === ModalTypes.ADD_DEVICE && !findDevice}
              className='text-[11px] rounded-[7px] min-w-[72px] border-[1px] border-[#E4A50E] bg-[#E4A50E] text-white px-4 py-1'
              type='submit'
            >
              {isLoadingCreateDevice || isLoadingUpdateDevice ? (
                <Spinner size={'w-6 h-4'} color={'text-white-200 dark:text-white-600'} />
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </div>
        
        {/* Add other form fields similarly */}
      </div>
    </form>
  );
};

export default ModalDevice;
