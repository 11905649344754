import axios from "axios";
import { Navigate } from "react-router-dom";

// const token = localStorage.getItem('token');

// Determine the base URL based on the environment
export const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.halcyon-eco.com"
    : "http://localhost:3900";

// ? "https://api.halcyon-eco.com"
//? "https://halcyon-test-b43cfab73a45.herokuapp.com/"

const api = axios.create({ baseURL });

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["x-auth-token"] = token;
  }
  return config;
});

export default api;
