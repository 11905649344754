import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import country from 'country-list-js';
import ModalSelect from '../../UI/modal-select';
import { useOrganizationStore } from '../../../storesZustand/useOrganizationStore';
import { currencies } from '../../../utils/constans/currencies';
import { useOrganizationMutation, useOrganizationUpdateMutation } from '../../../services/organization/mutation';
import Spinner from '../../UI/Spinner';
import ModalSelectCountries from '../../UI/modal-select-countries';
import { ModalTypes } from '../modal-window';

const ModalOrganization = ({ currentAuthUser, handleClose, type, currentIdForGetData }) => {
  const [organizations] = useOrganizationStore(state => [
    state.organizations
  ]);
  
  const { mutate: mutateCreateOrganization, isLoading: isLoadingCreateOrganization } = useOrganizationMutation();
  const { mutate: mutateUpdateOrganization, isLoading: isLoadingUpdateOrganization } = useOrganizationUpdateMutation();
  
  let initialValues;
  
  if (type === ModalTypes.EDIT_ORGANIZATION && currentIdForGetData) {
    initialValues = organizations.find(item => item.id === currentIdForGetData);
  } else {
    initialValues = {
      parent_organization_id: '',
      name: '',
      currency: '',
      billing_country: ''
    };
  }
  
  const validationSchema = Yup.object().shape({
    parent_organization_id: Yup.string().required(
      'Parent organization is required'
    ),
    name: Yup.string().required('Name is required'),
    account_number: Yup.string()
      .matches(/^[0-9]+$/, 'Enter only the numberr').nullable(),
    post_code: Yup.string().nullable(),
    currency: Yup.string().required('Currency is required'),
    billing_contact_name: Yup.string().nullable(),
    address1: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    address3: Yup.string().nullable(),
    billing_post_code: Yup.string().nullable(),
    billing_country: Yup.object().required('Country is required'),
    billing_city: Yup.string().nullable()
  });
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      type === ModalTypes.ADD_ORGANIZATION && !currentIdForGetData ?
        mutateCreateOrganization({
          ...values,
          billing_country: values.billing_country.name,
          billing_country_code: values.billing_country.iso2
        }, {
          onSuccess: (data) => {
            handleClose();
          },
          onError: (error) => {
          }
        }) :
        mutateUpdateOrganization({
          id: currentIdForGetData,
          data: {
            ...values,
            billing_country: values.billing_country.name,
            billing_country_code: values.billing_country.iso2
          }
        }, {
          onSuccess: (data) => {
            handleClose();
          },
          onError: (error) => {
          }
        });
    }
  });
  
  const countryNames = Object.values(country.all).map(code => ({ name: code.name, iso2: code.iso2 }));
  
  const handleCloseFunc = React.useCallback(() => {
    formik.resetForm();
    handleClose();
  }, [formik, handleClose]);
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-col sm:flex-row relative'>
        <div
          className='hidden sm:block bg-[#07203E] absolute top-[50%] translate-y-[-50%] bottom-[0px] left-[50%] w-[1px] h-[90%] opacity-50'></div>
        <div className='flex-1 px-5 pt-5 pb-8 sm:p-10 flex flex-col gap-[40px]'>
          <div className=' sm:hidden self-end mb-[-36px]'>
            <button onClick={handleCloseFunc}>
              <img src='/images/close.svg' alt='close' />
            </button>
          </div>
          <div className='flex gap-4 items-center'>
            <div className='w-[40px] h-[40px]'>
              <img
                className=' w-full h-full'
                src='/images/addOrgIcon.svg'
                alt='location'
                style={{ fill: '#10223F' }}
              />
            </div>
            <h2 className='text-[24px] font-bold text-[#32383F]'>
              {type === ModalTypes.EDIT_ORGANIZATION ? ModalTypes.EDIT_ORGANIZATION : ModalTypes.ADD_ORGANIZATION}
            </h2>
          </div>
          <div className='flex flex-col relative'>
            <label className='text-[15px] font-bold'>
              Parent Organisation
              <span className='text-[#F91212]'>*</span>
            </label>
            <ModalSelect
              defaultOption={
                type === ModalTypes.EDIT_ORGANIZATION
                  ? (initialValues?.parent_organization_id
                    ? organizations.find(item => item.id === initialValues?.parent_organization_id)
                    : null)
                  : (organizations?.length === 1 ? organizations[0] : null)
              }
              optionDefault='Parent Organization'
              formik={formik}
              field={'parent_organization_id'}
              data={organizations.filter(organization => organization.id === currentAuthUser.organization_id)}
            />
            {formik.touched.parent_organization_id &&
              formik.errors.parent_organization_id && (
                <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                  {formik.errors.parent_organization_id}
                </div>
              )}
          </div>
          
          <div className='flex flex-col gap-2 relative'>
            <label className='text-[15px] font-bold'>
              Name<span className='text-[#F91212]'>*</span>
            </label>
            <input
              className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
              type='text'
              name='name'
              placeholder='Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                {formik.errors.name}
              </div>
            )}
          </div>
          <div className='flex sm:flex-col flex-row justify-between gap-y-[50px] gap-x-[70px]'>
            <div className='flex flex-1 justify-between gap-5'>
              <div className='flex flex-1 flex-col gap-2 relative'>
                <label className='text-[15px] font-bold'>
                  Account Number
                </label>
                <input
                  className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                  type='string'
                  name='account_number'
                  placeholder='Account Number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.account_number}
                />
                {formik.touched.account_number &&
                  formik.errors.account_number && (
                    <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                      {formik.errors.account_number}
                    </div>
                  )}
              </div>
              <div className='flex flex-1 flex-col gap-2 relative'>
                <label className='text-[15px] font-bold'>
                  Postcode
                </label>
                <input
                  className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                  type='string'
                  name='post_code'
                  placeholder='Postcode'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.post_code}
                />
                {formik.touched.post_code &&
                  formik.errors.post_code && (
                    <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                      {formik.errors.post_code}
                    </div>
                  )}
              </div>
            </div>
          
          
          </div>
          <div className='sm:grow-[2] self-stretch sm:self-auto flex flex-col gap-4 sm:justify-start'>
            <div className='flex flex-col gap-2 relative sm:max-w-[50%]'>
              <label className='text-[15px] font-bold'>
                Local Currency
                <span className='text-[#F91212]'>*</span>
              </label>
              <ModalSelect
                defaultOption={initialValues?.currency ? { name: initialValues.currency } : null}
                data={currencies}
                optionDefault='Local Currency'
                formik={formik}
                field='currency'
                // dropListUp={true}
              />
              {formik.touched.currency &&
                formik.errors.currency && (
                  <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                    {formik.errors.currency}
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className='flex-1 px-5 pb-5 sm:p-10  flex flex-col gap-[50px] justify-between'>
          <div className='flex flex-col justify-between h-full gap-[142px]'>
            <div className='hidden sm:block self-end'>
              <button onClick={handleCloseFunc}>
                <img src='/images/close.svg' alt='close' />
              </button>
            </div>
            <div className='flex flex-col gap-10'>
              <div className='flex flex-col gap-2 relative'>
                <label className='text-[15px] font-bold'>
                  Billing Contact Name
                </label>
                <input
                  className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                  type='text'
                  name='billing_contact_name'
                  placeholder='Billing Contact Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.billing_contact_name}
                />
                {formik.touched.billing_contact_name &&
                  formik.errors.billing_contact_name && (
                    <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                      {formik.errors.billing_contact_name}
                    </div>
                  )}
              </div>
              <div className='flex flex-col gap-2'>
                <label className='text-[15px] font-bold'>
                  Billing Address
                </label>
                <div className='flex flex-row gap-8 items-end'>
                  <div className='grow-[3] flex flex-col gap-4 justify-start'>
                    <div className=' relative'>
                      <input
                        className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                        type='text'
                        name='address1'
                        placeholder='Billing Address 1'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values
                            .address1
                        }
                      />
                      {formik.touched.address1 &&
                        formik.errors
                          .address1 && (
                          <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                            {
                              formik.errors
                                .address1
                            }
                          </div>
                        )}
                    </div>
                    <div className=' relative'>
                      <input
                        className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                        type='text'
                        name='address2'
                        placeholder='Billing Address 2'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values
                            .address2
                        }
                      />
                    </div>
                    <div className=' relative'>
                      <input
                        className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                        type='text'
                        name='address3'
                        placeholder='Billing Address 3'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values
                            .address3
                        }
                      />
                    </div>
                  </div>
                  <div className='grow-[1] flex flex-col gap-4 justify-start'>
                    <div className='hidden sm:block relative w-full'>
                      <ModalSelectCountries
                        defaultOption={initialValues?.billing_country ? { name: initialValues.billing_country } : null}
                        data={countryNames}
                        optionDefault='Billing country'
                        formik={formik}
                        field={'billing_country'}
                      />
                      {formik.touched.billing_country &&
                        formik.errors
                          .billing_country && (
                          <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                            {
                              formik.errors
                                .billing_country
                            }
                          </div>
                        )}
                    </div>
                    <div className=' relative'>
                      <input
                        className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                        type='text'
                        name='billing_city'
                        placeholder='Billing City'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values.billing_city
                        }
                      />
                      {formik.touched.billing_city &&
                        formik.errors.billing_city && (
                          <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                            {
                              formik.errors
                                .billing_city
                            }
                          </div>
                        )}
                    </div>
                    <div className='relative'>
                      <input
                        className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                        type='text'
                        name='billing_post_code'
                        placeholder='Billing Postcode'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={
                          formik.values
                            .billing_post_code
                        }
                      />
                      {formik.touched.billing_post_code &&
                        formik.errors
                          .billing_post_code && (
                          <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                            {
                              formik.errors
                                .billing_post_code
                            }
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className='sm:hidden relative mt-7'>
                  <ModalSelectCountries
                    defaultOption={initialValues?.billing_country ? { name: initialValues.billing_country } : null}
                    data={countryNames}
                    optionDefault='Billing country'
                    formik={formik}
                    field={'billing_country'}
                  />
                  {formik.touched.billing_country &&
                    formik.errors.billing_country && (
                      <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                        {formik.errors.billing_country}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className='flex gap-[9px] self-end'>
            <button
              onClick={handleCloseFunc}
              className='text-[11px] rounded-[7px] border-[1px] border-[#E4A50E] text-[#E4A50E] px-4 py-1'
            >
              Go Back
            </button>
            <button
              className='text-[11px] rounded-[7px] min-w-[72px] border-[1px] border-[#E4A50E] bg-[#E4A50E] text-white px-4 py-1'
              type='submit'
            >
              {isLoadingCreateOrganization || isLoadingUpdateOrganization ? (
                <Spinner size={'w-6 h-4'} color={'text-white-200 dark:text-white-600'} />
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </div>
      
      </div>
    </form>
  );
};

export default ModalOrganization;
