import React from "react";
import { Link } from "react-router-dom";
import "../../logo.svg";

const PrivacyPolicy = () => {
  return (
    <>
      <NavbarPolicies />
      <div className="flex items-center justify-center min-h-screen bg-gray-100 mt-5 mb-5">
        <div className="bg-white shadow-md rounded-lg p-8 max-w-3xl w-full">
          <h1 className="text-2xl font-bold text-center mb-4">
            Privacy Policy
          </h1>
          <p>
            <strong>Effective Date: 16 October 2023</strong>
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">1. Introduction</h2>
          <p>
            Halcyon Eco Limited ("we", "us", or "our") is committed to
            safeguarding the privacy of individuals who use our platform. This
            Privacy Policy outlines how we collect, use, store, and protect your
            personal data when you access or use our platform, which enables
            restaurant owners and employees to monitor IoT kitchen machines,
            including training and certification functionalities. By using our
            platform, you agree to the collection and use of information as
            outlined in this policy.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            2. Data Controller and Contact Information
          </h2>
          <p>
            The data controller responsible for processing your personal data
            is:
            <br />
            <strong>Company Name:</strong> Halcyon Eco Limited
            <br />
            <strong>Contact Email:</strong> info@halcyon-eco.com
            <br />
            <strong>Address:</strong> Unit 1, Racecourse Road, Roscommon Town,
            Roscommon, Ireland
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            3. Data We Collect
          </h2>
          <ul className="list-disc list-inside">
            <li>Name</li>
            <li>Email Address</li>
            <li>Company Information (e.g., restaurant name)</li>
            <li>
              Login Credentials: Encrypted passwords managed through AWS Cognito
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            4. How We Collect Data
          </h2>
          <ul className="list-disc list-inside">
            <li>Register for an account on the platform.</li>
            <li>Complete training and certifications via the platform.</li>
            <li>
              Access and interact with the Halcyon Eco Oil Filtration machine
              and/or online dashboard.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            5. How We Use Your Data
          </h2>
          <ul className="list-disc list-inside">
            <li>
              Account Management: To create and manage your account on the
              platform.
            </li>
            <li>
              IoT Data Access: To provide you with access to Halcyon Eco Oil
              Filtration machine data, such as oil savings and machine usage.
            </li>
            <li>
              Training and Certification: To allow employees to complete
              training modules and receive certifications.
            </li>
            <li>
              Communication: To inform you about platform updates or important
              information.
            </li>
            <li>
              Platform Security: To ensure the security of the platform and
              prevent unauthorised access.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            6. Legal Basis for Processing Your Data
          </h2>
          <ul className="list-disc list-inside">
            <li>
              Contractual Necessity: The processing is necessary to deliver the
              services you requested, such as access to the platform, IoT
              machine data, and training functionalities.
            </li>
            <li>
              Legitimate Interests: We have legitimate interests in improving
              system efficiency, ensuring platform security, and optimising the
              functionality of the IoT machine.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            7. Data Sharing and Transfers
          </h2>
          <ul className="list-disc list-inside">
            <li>
              AWS Cognito: For secure user authentication and encryption of
              login credentials.
            </li>
            <li>
              Allure Software Limited: Our development partner, which has access
              to the platform solely for maintenance and enhancement purposes.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-2">8. Data Retention</h2>
          <p>
            We retain your personal data only as long as necessary to fulfil the
            purposes outlined in this Privacy Policy.
            <ul className="list-disc list-inside">
              <li>
                Account Information: Retained for the duration of your active
                account.
              </li>
              <li>
                Training and Certification Records: Retained for [specify
                retention period].
              </li>
              <li>
                IoT Data: Retained according to the operational needs of the
                platform.
              </li>
            </ul>
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            9. Your Data Rights (GDPR & CCPA)
          </h2>
          <ul className="list-disc list-inside">
            <li>
              Right of Access: Request a copy of the personal data we hold about
              you.
            </li>
            <li>
              Right to Rectification: Request corrections to any inaccurate or
              incomplete data.
            </li>
            <li>
              Right to Erasure: Request the deletion of your personal data (the
              “right to be forgotten”).
            </li>
            <li>
              Right to Data Portability: Request that your data be transferred
              to another service in a machine-readable format.
            </li>
            <li>
              Right to Object: Object to the processing of your data under
              certain conditions.
            </li>
            <li>
              Right to Withdraw Consent: Withdraw your consent to processing
              where processing is based on consent.
            </li>
            <li>
              Right to Opt Out (CCPA): California residents have the right to
              opt out of the sale of their personal data.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-2">10. Data Security</h2>
          <ul className="list-disc list-inside">
            <li>
              Encryption: All data transmissions are encrypted using HTTPS, and
              sensitive information such as login credentials is encrypted.
            </li>
            <li>
              Access Control: Only authorised personnel have access to your
              data.
            </li>
            <li>
              Data Breach Procedures: We have processes in place to detect,
              report, and mitigate data breaches in compliance with legal
              requirements.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            11. International Data Transfers
          </h2>
          <p>
            When transferring personal data outside the European Economic Area
            (EEA), we implement appropriate safeguards.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">
            12. Changes to this Privacy Policy
          </h2>
          <p>
            We reserve the right to update this Privacy Policy from time to
            time. Any changes will be posted on this page.
          </p>

          <h2 className="text-xl font-semibold mt-6 mb-2">13. Contact Us</h2>
          <p>
            If you have any concerns or questions, please contact us at:
            <br />
            <strong>Email:</strong> info@halcyon-eco.com
            <br />
            <strong>Address:</strong> Unit 1, Racecourse Road, Roscommon Town,
            Roscommon, Ireland
          </p>
        </div>
      </div>
    </>
  );
};

export const NavbarPolicies = () => {
  return (
    <nav className="bg-white shadow-md py-4">
      <div className="container mx-auto flex justify-between items-center px-4">
        {/* Logo */}
        <div className="flex-shrink-0 ">
          <Link to="/" className="text-xl font-bold text-gray-800 ml-4">
            {/* You can replace this with an image or an actual logo */}
            <img
              src="/images/halcyon-logo.png"
              alt="Halcyon Logo"
              width={170}
              heigh={50}
              style={{ marginTop: -40 }}
            />
          </Link>
        </div>

        {/* Links */}
        <div className="flex items-center space-x-4">
          <Link to="/login" className="text-gray-700 hover:text-[#040824]">
            Login
          </Link>
          <Link
            to="/register"
            className="bg-[#040824] text-white px-4 py-2 rounded hover:bg-opacity-90"
          >
            Register
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default PrivacyPolicy;
