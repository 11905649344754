import { daysOfWeek } from '../utils/constans/daysObject';

export function getPrevious7Days () {
  const today = new Date();
  
  // Create an array to store the previous 7 days of the week
  const previousDays = [];
  
  // Create an array of day names
  const _daysOfWeek = [
    daysOfWeek.Sunday,
    daysOfWeek.Monday,
    daysOfWeek.Tuesday,
    daysOfWeek.Wednesday,
    daysOfWeek.Thursday,
    daysOfWeek.Friday,
    daysOfWeek.Saturday
  ];
  
  // Calculate the previous 7 days
  for (let i = 0; i < 7; i++) {
    // Calculate the date for the previous day
    const previousDay = new Date(today);
    previousDay.setDate(today.getDate() - i);
    
    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayIndex = previousDay.getDay();
    
    // Add the day name to the array
    previousDays.push(_daysOfWeek[dayIndex]);
  }
  
  return previousDays.reverse();
}
