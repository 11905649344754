import React, { useMemo } from "react";
import VideoModal from "../../components/video";

export default function LessonContent({
  lessons,
  lessonNumber,
  hasWatchedVideo,
  setHasWatchedVideo,
  handleOpenModal,
  handleMarkComplete,
  completeProgress,
  handleNext,
  handlePrevious,
  onGetTime,
}) {
  const lesson = useMemo(() => {
    return lessons[lessonNumber];
  }, [lessonNumber, lessons]);

  return (
    <div className="w-100">
      <div className="md:col-span-8 col-span-12">
        {lesson?.video !== "" ? (
          <VideoModal
            hasWatchedVideo={hasWatchedVideo}
            setHasWatchedVideo={setHasWatchedVideo}
            completeProgress={completeProgress}
            lessonNumber={lessonNumber}
            video={lesson?.video}
            controlsList="nodownload"
          />
        ) : null}
        <p className="text-[32px] font-medium mt-4">{lesson?.title1}</p>
        <p className="text-[#848484]">{lesson?.des}</p>
        {/* <div
          onClick={handleOpenModal}
          className="px-[32px] cursor-pointer py-5 bg-[#ECECEC] rounded-xl flex items-center mt-[23px]"
        >
          <img src="/images/document.svg" alt="" />
          <p className="ml-5">Safety Manual</p>
        </div> */}
        <div className="flex justify-end space-x-4 mt-7">
          {lessonNumber !== 0 && lessonNumber !== lessons.length - 1 ? (
            <button
              className="download-certificate py-[4px] min-w-[150px] rounded-full flex justify-center bg-blue-500 hover:bg-blue-600 text-white font-bold uppercase text-[14px]"
              onClick={handlePrevious}
            >
              Previous
            </button>
          ) : null}
          {completeProgress <= lessonNumber ? (
            <button
              className="download-certificate py-[4px] min-w-[150px] rounded-full flex justify-center bg-blue-500 hover:bg-blue-600 text-white font-bold uppercase text-[14px] disabled:opacity-50 disabled:pointer-events-none"
              onClick={handleMarkComplete}
            >
              Mark Complete
            </button>
          ) : null}
          <button
            className="download-certificate py-[4px] min-w-[150px] rounded-full flex justify-center bg-blue-500 hover:bg-blue-600 text-white font-bold uppercase text-[14px] disabled:opacity-50 disabled:pointer-events-none"
            onClick={handleNext}
            disabled={!(lessonNumber < completeProgress)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
