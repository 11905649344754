// src/Table.js
import React from "react";

const TableDevices = ({ data }) => {
  return (
    <div className="table-container overflow-auto w-full">
      <table className="divide-y-8 divide-[#F4F4F4] w-full">
        <thead>
          <tr>
            <th className="px-6 py-2 text-left md:text-base text-[12px] font-bold text-[#B0CAD1] md:uppercase tracking-wider">
              ID
            </th>
            <th className="px-6 py-2 text-left md:text-base text-[12px] font-bold text-[#B0CAD1] md:uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-2 text-left md:text-base text-[12px] font-bold text-[#B0CAD1] md:uppercase tracking-wider">
              Location
            </th>
            <th className="px-6 py-2 text-center md:text-base text-[12px] font-bold text-[#B0CAD1] md:uppercase tracking-wider">
              Status
            </th>
          </tr>
        </thead>

        <tbody className="divide-y-8 divide-[#F4F4F4]">
          {data?.map((item) => (
            <tr className="bg-white rounded-lg" key={item?.id}>
              <td className="px-6 py-3 whitespace-nowrap rounded-l-full">
                <div className="text-base font-medium text-[#1E3A56]">
                  {item?.id}
                </div>
              </td>
              <td className="px-6 py-3 whitespace-nowrap">
                <div className="text-base text-[#1E3A56]">{item?.name}</div>
              </td>
              <td className="px-6 py-3 whitespace-nowrap">
                <div className="text-base text-[#1E3A56]">
                  {item?.location_name}
                </div>
              </td>
              <td className="lg:px-6 px-20 py-3 whitespace-nowrap rounded-r-full">
                <div className="flex justify-center items-center h-full">
                  <div
                    className={`inline-block rounded-full py-2 px-4 ${
                      item?.location_id === null
                        ? "bg-orange-400"
                        : "bg-green-500"
                    }`}
                  ></div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableDevices;
