import { useMutation, useQueryClient } from 'react-query';
import api from '../../utils/api';
import { toast } from 'react-toastify';

export function useInviteUserMutation () {
  //const queryClient = useQueryClient();
  
  return useMutation(
    (inviteUser) =>
      api.post('/invites/create', inviteUser),
    {
      onSuccess: (data) => {
        toast.success('Invite for user is created');
        // queryClient.invalidateQueries('yourQueryKey');
        // Вместо 'yourQueryKey' укажите ключ запроса, который вы хотите обновить
        //queryClient.invalidateQueries(['yourQueryKey', { /* queryKey options */ }]);
        // Возможно, вы хотите также очистить связанные с этим запросом данные в кеше
        // queryClient.removeQueries(['yourQueryKey', { /* queryKey options */ }]);
      },
      onError: (error) => {
        toast.error(`${error?.response?.data.message ? error?.response?.data.message : error}!`);
      }
    }
  );
}
