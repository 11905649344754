import React, { useState } from "react";
import Spinner from "../../UI/Spinner";
import { useFetching } from "../../../hooks/useFetching";
import { analyticsService } from "../../../services/analytics/analytics.service";
import { downloadCSV } from "../../../helpers/convertCSV";
import { ModalTypes } from "../modal-window";
import PDFDownloader from "../../download-pdf";

const ModalConfirmation = ({
  handleClose,
  type,
  currentIdForGetData,
  organizationId,
}) => {
  const [usageData, setUsageData] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [fetchDataExcel, isLoading] = useFetching(async (id, method) => {
    let responseData;
    // eslint-disable-next-line default-case
    switch (type) {
      case ModalTypes.DOWNLOAD_ORG_DATA:
        responseData = (await analyticsService.getAnalyticsOrganization(id))
          .data;
        break;

      case ModalTypes.DOWNLOAD_LOCATION_DATA:
        responseData = (await analyticsService.getAnalyticsLocation(id)).data;
        break;

      case ModalTypes.DOWNLOAD_DEVICE_DATA:
        responseData = (
          await analyticsService.getAnalyticsDevice(id, organizationId)
        ).data;
        break;
    }
    if (responseData?.data) {
      if (method === "csv") {
        handleClose();
        downloadCSV(responseData.data);
      }

      if (method === "pdf") {
        setLoadingPDF(true);
        setUsageData(responseData.data);
      }
    }
  });

  const handleCloseFunc = React.useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handlerSubmit = (method) => {
    fetchDataExcel(currentIdForGetData, method);
  };

  return (
    <div
      className={`flex flex-col sm:flex-row relative sm:min-h-full ${
        isLoading || loadingPDF ? "loading_overlay_white" : ""
      }`}
    >
      {(isLoading || loadingPDF) && <div className="overlay-white"></div>}
      <div className="flex-1 px-5 pt-5 pb-8 sm:p-6 flex flex-col gap-[15px] sm:gap-[30px]">
        <div className="flex justify-between flex-row items-center">
          <div className="text-[#787878] text-[0.9] flex gap-4 items-center">
            <h2 className="text-[28px] font-bold text-[#32383F]">
              Confirm action
            </h2>
          </div>
          <button className="self-start w-8 h-8" onClick={handleCloseFunc}>
            <img src="/images/close.svg" alt="close" />
          </button>
        </div>

        <div className="text-[20px]">
          Would you like to download as PDF or CSV?
        </div>

        <div className="flex justify-center">
          <span
            className="mr-4 cursor-pointer hover:cursor-pointer"
            style={{ color: "red" }}
          >
            <PDFDownloader
              handleClose={handleClose}
              setLoadingPDF={setLoadingPDF}
              data={usageData}
              handleDownload={() => handlerSubmit("pdf")}
            />
          </span>

          <span
            onClick={isLoading ? "" : () => handlerSubmit("csv")}
            className="ml-4 cursor-pointer hover:cursor-pointer"
            style={{ color: "green", marginTop: 4 }}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 384 512"
              height="44px"
              width="44px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm-96 144c0 4.42-3.58 8-8 8h-8c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h8c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-8c-26.51 0-48-21.49-48-48v-32c0-26.51 21.49-48 48-48h8c4.42 0 8 3.58 8 8v16zm44.27 104H160c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h12.27c5.95 0 10.41-3.5 10.41-6.62 0-1.3-.75-2.66-2.12-3.84l-21.89-18.77c-8.47-7.22-13.33-17.48-13.33-28.14 0-21.3 19.02-38.62 42.41-38.62H200c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-12.27c-5.95 0-10.41 3.5-10.41 6.62 0 1.3.75 2.66 2.12 3.84l21.89 18.77c8.47 7.22 13.33 17.48 13.33 28.14.01 21.29-19 38.62-42.39 38.62zM256 264v20.8c0 20.27 5.7 40.17 16 56.88 10.3-16.7 16-36.61 16-56.88V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v20.8c0 35.48-12.88 68.89-36.28 94.09-3.02 3.25-7.27 5.11-11.72 5.11s-8.7-1.86-11.72-5.11c-23.4-25.2-36.28-58.61-36.28-94.09V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8zm121-159L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"></path>
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmation;
