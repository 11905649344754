import create from 'zustand';

export const useLocationStore = create((set, get, api) => ({
  locations: null,
  
  setAllLocation: (data) => {
    set({
      locations: data
    });
  }
  
}), {
  name: 'locationStore'
});