import React from "react";
import Boxes from "../boxes";
import Chart from "../chart";

export default function TopGrid({
  item,
  currency,
  monthArray,
  onChartsRendered,
  index,
}) {
  return (
    <div
      className="grid grid-cols-12"
      style={{
        left: -3000,
        top: 50 + index * 280,
        position: "absolute",
        width: "1100px",
        height: "270px",
        visibility: "hidden",
      }}
      id={`chart-${index}`}
    >
      <div className="col-span-5 print:col-span-4">
        <div className="grid grid-cols-2 sm:gap-3 gap-1 print:gap-2">
          <Boxes item={item} currency={currency} />
        </div>
      </div>
      <div className="col-span-7 print:col-span-8 ">
        <Chart
          item={item}
          len={monthArray?.length}
          i={index}
          currency={currency}
          onChartsRendered={onChartsRendered}
        />
      </div>
    </div>
  );
}
