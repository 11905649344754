import create from 'zustand';

export const useDashboardDataStore = create((set, get, api) => ({
  devicesWithLocationWithAnalytics: null,
  locationWithAnalyticsOneOrganization: null,
  
  setAllDashboardData: (data) => {
    set({
      devicesWithLocationWithAnalytics: data
    });
  },
  setLocationWithAnalyticsOneOrganization: (data) => {
    set({
      locationWithAnalyticsOneOrganization: data
    });
  }
}), {
  name: 'dasboardStore'
});