import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

function convertCurrencyCodeToSymbol(currencyCode) {
  const locale = "en-IE"; // Get the user's locale or fallback to 'en-US'

  // Create a number formatter instance for the user's locale
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  });

  // Extract the currency symbol from the formatted currency value
  const parts = formatter.formatToParts(1);
  const symbolPart = parts.find((part) => part.type === "currency");
  return symbolPart ? symbolPart.value.trim() : currencyCode; // Return the symbol or the original code if not found
}

export const useCovertToPDFData = (data) => {
  const [monthArray, setMonthArray] = useState([]);
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    if (data?.data?.length > 0) {
      async function processData(inputData) {
        const monthGroups = {};

        // Initialize monthGroups with default values
        inputData.forEach((entry) => {
          const monthYear = dayjs(entry.Date).format("YYYY-MM");
          if (!monthGroups[monthYear]) {
            monthGroups[monthYear] = {
              monthData: [],
              monthTotals: {
                litresSaved: 0,
                savingsCarbon: 0,
                savings: 0,
              },
            };
          }
          monthGroups[monthYear].monthData.push(entry);

          // Calculate totals for the current entry
          monthGroups[monthYear].monthTotals.litresSaved += parseFloat(
            entry.litresSaved
          );
          monthGroups[monthYear].monthTotals.savingsCarbon += parseFloat(
            entry.savingsCarbon
          );
          monthGroups[monthYear].monthTotals.savings += parseFloat(
            entry.savings
          );
        });

        // Convert monthGroups into the desired output format
        const monthArray = Object.keys(monthGroups).map((monthYear) => {
          const { monthData, monthTotals } = monthGroups[monthYear];
          return {
            month: dayjs(monthData[0].Date).format("MMMM"),
            totalSaving: monthTotals.savings.toFixed(2),
            saving: monthTotals.savings.toFixed(2),
            totalCarbonSaving: monthTotals.savingsCarbon.toFixed(2),
            totalLitresSaved: monthTotals.litresSaved.toFixed(2),
            day: monthData.map((entry) => ({
              id: entry.id,
              date: entry.Date,
              carbon: entry.savingsCarbon,
              oil: entry.litresSaved,
              saving: entry.savings,
            })),
          };
        });

        // Sort the month array by month and year in descending order
        monthArray.sort((a, b) => {
          return dayjs(a.day[0].date).diff(dayjs(b.day[0].date));
        });

        setMonthArray(monthArray);
        setCurrency(convertCurrencyCodeToSymbol(data?.currency || "EUR"));
        return monthArray;
      }

      // Call the main function
      processData(data.data);
    }
  }, [data, data?.currency, data.data]);

  return [monthArray, currency];
};
