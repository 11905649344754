export const listOfDates = [
  {
    id: 0,
    name: 'Last 30 Days'
  },
  {
    id: 1,
    name: 'Last 2 Weeks'
  },
  {
    id: 2,
    name: 'Last Week'
  },
  {
    id: 3,
    name: 'Last 3 Days'
  }
];