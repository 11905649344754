import { useQuery } from 'react-query';
import api from '../../utils/api';
import { useUserStore } from '../../storesZustand/useUserStore';
import { toast } from 'react-toastify';
import { isAuthenticated } from '../../components/auth-gaurd';

export function useUserData () {
  const token = localStorage.getItem('token');
  const [setUsersDataAfterLogin] = useUserStore(state => [state.setUsersDataAfterLogin]);
  return useQuery(
    'userData',
    async () => {
      if (token && isAuthenticated()) {
        const response = await api.get(
          '/user/getCurrentUser'
        );
        const data = response?.data?.data;
        setUsersDataAfterLogin(data);
        return response?.data?.data; // Return the data from the API response
      }
    },
    {
      cacheTime: 1200,
      staleTime: 1000,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(`${error.response.data.message}!`);
        throw error;
      }
    }
  );
}
