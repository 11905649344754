import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import country from 'country-list-js';
import ModalSelect from '../../UI/modal-select';
import { currencies } from '../../../utils/constans/currencies';
import { useOrganizationStore } from '../../../storesZustand/useOrganizationStore';
import {
  useLocationCreateMutation,
  useLocationUpdateMutation
} from '../../../services/location/mutations';
import Spinner from '../../UI/Spinner';
import { useLocationStore } from '../../../storesZustand/useLocationStore';
import { ModalTypes } from '../modal-window';

const ModalLocation = ({
  currentAuthUser,
  handleClose,
  type,
  currentIdForGetData
}) => {
  const [organizations] = useOrganizationStore((state) => [
    state.organizations
  ]);
  
  const [locations] = useLocationStore((state) => [state.locations]);
  
  const { mutate: mutateCreateLocation, isLoading: isLoadingCreateLocation } =
    useLocationCreateMutation();
  const { mutate: mutateUpdateLocation, isLoading: isLoadingUpdateLocation } =
    useLocationUpdateMutation();
  
  let initialValues;
  if (type === ModalTypes.EDIT_LOCATION && currentIdForGetData) {
    initialValues = locations.find((item) => item.id === currentIdForGetData);
  } else {
    initialValues = {
      name: '',
      address1: '',
      country: '',
      long: '',
      lat: '',
      local_currency: '',
      oil_Cost_liter: ''
    };
  }
  
  const validationSchema = Yup.object().shape({
    organization_id: Yup.string().required('Organization is required'),
    name: Yup.string().required('Name is required'),
    address1: Yup.string().required('At least one address(1) is required.'),
    address2: Yup.string().nullable(),
    address_3: Yup.string().nullable(),
    country: Yup.string().required('Country is required'),
    city: Yup.string().nullable(),
    post_Code: Yup.string().nullable(),
    long: Yup.string().required('Longitude is required'),
    lat: Yup.string().required('Latitude is required'),
    local_currency: Yup.string().required('Local Currency is required'),
    oil_Cost_liter: Yup.string()
      .matches(/^\d*[.,]?\d*$/, 'Enter only number')
      .nullable()
      .required('Oil Cost  Liter is required'),
    oil_Cost_hour: Yup.string()
      .matches(/^\d*[.,]?\d*$/, 'Enter only number ')
      .nullable(),
    liters_cost_hour: Yup.string()
      .matches(/^\d*[.,]?\d*$/, 'Enter only number')
      .nullable(),
    contact_name: Yup.string().nullable(),
    contact_phone: Yup.string().nullable(),
    contact_email: Yup.string().email().nullable()
  });
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      type === ModalTypes.ADD_LOCATION && !currentIdForGetData
        ? mutateCreateLocation(values, {
          onSuccess: (data) => {
            handleClose();
          },
          onError: (error) => {}
        })
        : mutateUpdateLocation(
          {
            id: currentIdForGetData,
            data: {
              ...values
            }
          },
          {
            onSuccess: (data) => {
              handleClose();
            },
            onError: (error) => {}
          }
        );
    }
  });
  
  const countryNames = country.names().map((name) => ({ name }));
  
  const handleCloseFunc = React.useCallback(() => {
    formik.resetForm();
    handleClose();
  }, [formik, handleClose]);
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-col sm:flex-row relative'>
        <div
          className='hidden sm:block bg-[#07203E] absolute top-[50%] translate-y-[-50%] bottom-[0px] left-[50%] w-[1px] h-[90%] opacity-50'></div>
        <div className='flex-1 px-5 pt-5 pb-8 sm:p-10 flex flex-col gap-[50px]'>
          <div className=' sm:hidden self-end mb-[-36px]'>
            <button onClick={handleCloseFunc}>
              <img src='/images/close.svg' alt='close' />
            </button>
          </div>
          <div className='flex gap-4 items-center'>
            <div className='w-[40px] h-[40px]'>
              <img
                className=' w-full h-full'
                src='/images/addLocIcon.svg'
                alt='location'
                style={{ fill: '#10223F' }}
              />
            </div>
            <h2 className='text-[24px] font-bold text-[#32383F]'>
              {type === ModalTypes.ADD_LOCATION ? ModalTypes.ADD_LOCATION : ModalTypes.EDIT_LOCATION}
            </h2>
          </div>
          <div className='flex flex-col relative mt-1'>
            <label className='text-[15px] font-bold'>
              Please select the organization this new location belongs to
              <span className='text-[#F91212]'>*</span>
            </label>
            <div className='relative mt-2 mb-2'>
              <ModalSelect
                defaultOption={
                  type === ModalTypes.EDIT_LOCATION
                    ? initialValues?.organization_id &&
                    currentAuthUser.organization_id === 1
                      ? organizations.find(
                        (item) => item.id === initialValues?.organization_id
                      )
                      : null
                    : organizations.length === 1
                      ? organizations[0]
                      : null
                }
                data={organizations}
                optionDefault='Parent Organisation'
                formik={formik}
                field={'organization_id'}
              />
            </div>
            {formik.touched.organization_id &&
              formik.errors.organization_id && (
                <div className='absolute bottom-[20px] left-1 text-[#F91212] text-[12px]'>
                  {formik.errors.organization_id}
                </div>
              )}
            <small className='text-[#787878] font-[12px]'>
              This location doesn’t have a registered organization yet? You can
              create the organization here.
            </small>
          </div>
          
          <div className='flex flex-col gap-2 relative'>
            <label className=' mt-4 text-[15px] font-bold'>
              Name<span className='text-[#F91212]'>*</span>
            </label>
            <input
              className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
              type='text'
              name='name'
              placeholder='Location Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                {formik.errors.name}
              </div>
            )}
          </div>
          <div className='flex flex-col'>
            <label className='mt-1 text-[15px] font-bold'>
              Address<span className='text-[#F91212]'>*</span>
            </label>
            <div className='flex flex-row gap-8 items-end'>
              <div className='grow-[3] flex flex-col gap-4 justify-start'>
                <div className=' relative'>
                  <input
                    className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                    type='text'
                    name='address1'
                    placeholder='Location Address 1'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address1}
                  />
                  {formik.touched.address1 && formik.errors.address1 && (
                    <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                      {formik.errors.address1}
                    </div>
                  )}
                </div>
                <div className=' relative'>
                  <input
                    className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                    type='text'
                    name='address2'
                    placeholder='Location Address 2'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address2}
                  />
                </div>
                <div className=' relative'>
                  <input
                    className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                    type='text'
                    name='address_3'
                    placeholder='Location Address 3'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address_3}
                  />
                </div>
              </div>
              <div className='grow-[2] flex flex-col gap-4 justify-start'>
                <div className='hidden sm:block relative'>
                  <ModalSelect
                    defaultOption={
                      initialValues?.country
                        ? { name: initialValues.country }
                        : null
                    }
                    data={countryNames}
                    optionDefault='Country'
                    formik={formik}
                    field={'country'}
                  />
                  {formik.touched.country && formik.errors.country && (
                    <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                      {formik.errors.country}
                    </div>
                  )}
                </div>
                <div className=' relative'>
                  <input
                    className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                    type='text'
                    name='city'
                    placeholder='City'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                      {formik.errors.city}
                    </div>
                  )}
                </div>
                <div className=' relative'>
                  <input
                    className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                    type='text'
                    name='post_Code'
                    placeholder='Postcode'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.post_Code}
                  />
                  {formik.touched.post_Code && formik.errors.post_Code && (
                    <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                      {formik.errors.post_Code}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='sm:hidden relative mt-7'>
              <ModalSelect
                data={countryNames}
                optionDefault='Country'
                formik={formik}
                field={'country'}
              />
              {formik.touched.country && formik.errors.country && (
                <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                  {formik.errors.country}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='flex-1 px-5 pb-5 sm:p-10  flex flex-col gap-[50px]'>
          <div className='hidden sm:block self-end'>
            <button onClick={handleCloseFunc}>
              <img src='/images/close.svg' alt='close' />
            </button>
          </div>
          <div className='flex flex-col sm:flex-row gap-y-5 gap-x-[80px] items-start'>
            <div className='grow-[3] flex flex-col gap-5 justify-start'>
              <div className='flex flex-col gap-2 relative'>
                <label className='text-[15px] font-bold'>
                  Longitude
                  <span className='text-[#F91212]'>*</span>
                </label>
                <input
                  className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                  type='text'
                  name='long'
                  placeholder='Longitude'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.long}
                />
                {formik.touched.long && formik.errors.long && (
                  <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                    {formik.errors.long}
                  </div>
                )}
              </div>
              <div className='flex flex-col gap-2 relative'>
                <label className='text-[15px] font-bold'>
                  Latitude
                  <span className='text-[#F91212]'>*</span>
                </label>
                <input
                  className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                  type='text'
                  name='lat'
                  placeholder='Latitude'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lat}
                />
                {formik.touched.lat && formik.errors.lat && (
                  <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                    {formik.errors.lat}
                  </div>
                )}
              </div>
            </div>
            <div
              className='sm:grow-[2] self-stretch sm:self-auto flex flex-col gap-4 sm:justify-start'
              style={{ marginTop: -2.5 }}
            >
              <div className='flex flex-col relative'>
                <label className='text-[15px] font-bold'>
                  Local Currency
                  <span className='text-[#F91212]'>*</span>
                </label>
                <ModalSelect
                  defaultOption={
                    initialValues?.local_currency
                      ? { name: initialValues.local_currency }
                      : null
                  }
                  data={currencies}
                  optionDefault='Local Currency'
                  formik={formik}
                  field='local_currency'
                  // dropListUp={true}
                />
                {formik.touched.local_currency &&
                  formik.errors.local_currency && (
                    <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                      {formik.errors.local_currency}
                    </div>
                  )}
              </div>
              <div className='flex flex-col gap-2 relative'>
                <label className='text-[15px] font-bold'>
                  Oil Cost per Liter
                  <span className='text-[#F91212]'>*</span>
                </label>
                <input
                  className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                  type='text'
                  name='oil_Cost_liter'
                  placeholder='Oil Cost per Liter'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.oil_Cost_liter}
                />
                {formik.touched.oil_Cost_liter &&
                  formik.errors.oil_Cost_liter && (
                    <div className='whitespace-nowrap absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                      {formik.errors.oil_Cost_liter}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className='flex flex-col sm:flex-row gap-[20px] justify-between'>
            <div className='flex flex-col gap-2 relative'>{/*  */}</div>
            <div className='flex flex-col gap-2 relative'>
              {/* <label className='text-[15px] font-bold'>
                Oil Cost per Hour
              </label>
              <input
                className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                type='text'
                name='oil_Cost_hour'
                placeholder='Oil Cost per Hour'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.oil_Cost_hour}
              />
              {formik.touched.oil_Cost_hour &&
                formik.errors.oil_Cost_hour && (
                  <div className='whitespace-nowrap absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                    {formik.errors.oil_Cost_hour}
                  </div>
                )} */}
            </div>
            <div className='flex flex-col gap-2 relative'>
              {/* <label className='text-[15px] font-bold'>
                Liters Cost per Hour
              </label>
              <input
                className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                type='text'
                name='liters_cost_hour'
                placeholder='Liters Cost per Hour'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.liters_cost_hour}
              />
              {formik.touched.liters_cost_hour &&
                formik.errors.liters_cost_hour && (
                  <div className='whitespace-nowrap absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                    {formik.errors.liters_cost_hour}
                  </div>
                )} */}
            </div>
          </div>
          <div className='flex flex-col gap-2 relative'>
            <label className='text-[15px] font-bold'>Contact Name</label>
            <input
              className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
              type='text'
              name='contact_name'
              placeholder='Contact Name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contact_name}
            />
          </div>
          <div className='flex flex-col sm:flex-row justify-between gap-y-[50px] gap-x-[70px]'>
            <div className='flex flex-1 flex-col gap-2 relative'>
              <label className='text-[15px] font-bold'>
                Contact Phone Number
              </label>
              <input
                className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                type='text'
                name='contact_phone'
                placeholder='Contact Phone Number'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contact_phone}
              />
            </div>
            <div className='flex flex-1 flex-col gap-2 relative'>
              <label className='text-[15px] font-bold'>Contact Email</label>
              <input
                className='w-full border-b-[1.3px] border-b-[#B8BBC2] p-1 text-[12px]'
                type='text'
                name='contact_email'
                placeholder='Contact Email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contact_email}
              />
              {formik.touched.contact_email && formik.errors.contact_email && (
                <div className='absolute bottom-[-18px] left-1 text-[#F91212] text-[12px]'>
                  {formik.errors.contact_email}
                </div>
              )}
            </div>
          </div>
          <div className='flex gap-[9px] self-end'>
            <button
              onClick={handleCloseFunc}
              className='text-[11px] rounded-[7px] border-[1px] border-[#E4A50E] text-[#E4A50E] px-4 py-1'
            >
              Go Back
            </button>
            <button
              className='text-[11px] rounded-[7px] min-w-[72px] border-[1px] border-[#E4A50E] bg-[#E4A50E] text-white px-4 py-1'
              type='submit'
            >
              {isLoadingUpdateLocation || isLoadingCreateLocation ? (
                <Spinner
                  size={'w-6 h-4'}
                  color={'text-white-200 dark:text-white-600'}
                />
              ) : (
                <span>Submit</span>
              )}
            </button>
          </div>
        </div>
        
        {/* Add other form fields similarly */}
      </div>
    </form>
  );
};

export default ModalLocation;
