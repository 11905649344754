import api from '../../utils/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

const token = localStorage.getItem('token');
export const employeesService = {
  postSendReminder: (item) => {
    return api.post(
      `/${item?.invite_code ? 'invites' : 'courses'}/sendReminder/`,
      {
        code: item?.invite_code,
        email: item?.email,
        name: item?.first_name
      }
    );
  }
};

export function useDeleteUserByInviteId () {
  const queryClient = useQueryClient();
  return useMutation(
    'deleteByInviteId',
    async (item) => {
      const response = await api
        .delete(`/invites/deleteByInviteId/${item.id}`);
    },
    {
      onSuccess: (data, item) => {
        toast.success('Remove user');
        console.log(item);
        if (item.location_id) {
          console.log(item);
          queryClient.invalidateQueries(['invitesForLocation', item.location_id]);
        }
      },
      onError: (error) => {
        toast.error(`${error?.response?.data.message ? error?.response?.data.message : error}!`);
      }
    }
  );
}

export function useDeleteUserById () {
  const queryClient = useQueryClient();
  return useMutation(
    'deleteUserById',
    async (item) => {
      const response = await api
        .delete(`/user/deleteUser/${item.id}`);
    },
    {
      onSuccess: (data, item) => {
        console.log(item, 'deleteUserById');
        toast.success('Remove user');
        if (item.location_id) {
          console.log(item);
          queryClient.invalidateQueries(['employeesForLocations123', item.location_id]);
        }
      },
      onError: (error) => {
        toast.error(`${error?.response?.data.message ? error?.response?.data.message : error}!`);
      }
    }
  );
}

export function useDeleteUserInLocationById () {
  const queryClient = useQueryClient();
  return useMutation(
    'deleteUserinLocationById',
    async (item) => {
      const response = await api
        .delete(`/user/deleteUserInLocation/${item.id}/${item.location_id}`);
    },
    {
      onSuccess: (data, item) => {
        toast.success('Remove user');
        if (item.location_id) {
          queryClient.invalidateQueries(['employeesForLocations123', item.location_id]);
        }
      },
      onError: (error) => {
        toast.error(`${error?.response?.data.message ? error?.response?.data.message : error}!`);
      }
    }
  );
}





