import create from 'zustand';

export const useUserStore = create((set, get, api) => ({
  user: null,
  setUsersDataAfterLogin: (data) => {
    set({
      user: data
    });
  }
}), {
  name: 'UserStore'
});