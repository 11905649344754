import React from 'react';

const ProgressBar = ({ percentage }) => {
    const validPercentage = Math.max(0, Math.min(100, percentage));

    const style = {
        width: `${validPercentage}%`,
        height: '8px',
        backgroundColor: '#E5A50E',
        borderRadius: '4px',
        position: 'absolute',
        top: '0px',
        left: '0px',
    };

    const backgroundLineStyle = {
        width: '100%',
        height: '8px',
        backgroundColor: '#F2F2F2', // Grey color for the background line
        borderRadius: '4px',
        position: 'relative',
        // Adjust this to vertically center the background line
    };

    const circleContainerStyle = {
        position: 'absolute',
        top: '2px',
        left: '2px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    };

    const endCircle = {
        position: 'absolute',
        right: '-8px',
        top: '-7px',
        height: '10px',
        width: '10px',
        borderRadius: '100%',
        backgroundColor: '#fff',
        zIndex: '40',
        border: '6px solid #E5A50E',
        boxSizing: 'content-box',
    };

    const renderCircles = (validPercentage, color) => {
        let numberOfCircles = 5;
        if (color === '#fff') {
            if (+validPercentage === 100) {
                numberOfCircles = 5;
            } else if (+validPercentage === 75) {
                numberOfCircles = 4;
            } else if (+validPercentage === 50) {
                numberOfCircles = 3;
            } else {
                numberOfCircles = 2;
            }
        }
        const circles = [];

        for (let i = 1; i <= numberOfCircles; i++) {
            const circleStyle = {
                width: '4px',
                height: '4px',
                borderRadius: '50%',
                backgroundColor: color,
                marginRight: `3%`,
            };

            circles.push(<div key={i} style={circleStyle}></div>);
        }

        return circles;
    };

    return (
        <div className=' min-w-[150px]'>
            <div style={backgroundLineStyle}>
                <div style={circleContainerStyle}>
                    {renderCircles(100, '#C2C2C2')}
                </div>
                <div style={style}>
                    <div style={circleContainerStyle}>
                        {renderCircles(percentage, '#fff')}
                    </div>
                    {+percentage > 0 && <div style={endCircle}></div>}
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
