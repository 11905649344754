import dayjs from 'dayjs';

export const generateDaysOfWeek = () => {
  const today = dayjs();
  const daysOfWeek = {};
  
  for (let i = 0; i < 7; i++) {
    const day = today.subtract(i, 'day');
    daysOfWeek[day.format('dddd')] = day.format('ddd');
  }
  
  const sortedDaysOfWeek = {};
  const dayNames = Object.keys(daysOfWeek).reverse();
  
  dayNames.forEach((dayName) => {
    sortedDaysOfWeek[dayName] = daysOfWeek[dayName];
  });
  
  return sortedDaysOfWeek;
};
