import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import { toast } from "react-toastify";
import SVGExamResult from "../../components/svg-exam-result";
import { courseProgress } from "../../data";
import { useUserStore } from "../../storesZustand/useUserStore";

const questions = [
  {
    questionId: "a2f3d1e4",
    question:
      "The oil should be within a temperature range of 90°-150° before filtering.",
  },
  {
    questionId: "b4c5e2f8",
    question: "If the oil is good, the Probe flashes green.",
  },
  {
    questionId: "e1f7d9b3",
    question:
      "The grate in the bottom of the fryer should be removed before filtering.",
  },
  {
    questionId: "f3c5d8e1",
    question: "The wheels should be locked.",
  },
  {
    questionId: "b6f2c7a9",
    question: `The Fryer drain valve is turned to "Open" position for cleaning down the fryer.`,
  },
  {
    questionId: "e4a7b3f8",
    question: "The filtration process runs for 6 minutes per fryer.",
  },
  {
    questionId: "c1d5e9b2",
    question:
      "The sediment in the fryer base is removed by flushing it through.",
  },
  {
    questionId: "d9e3f6a7",
    question: `The Fryers drain valve should be in the "Closed" position to refill.`,
  },
  {
    questionId: "b8c2d4e1",
    question: "The fryer is refilled with filtered oil using the wand.",
  },
  {
    questionId: "a6f4b9c3",
    question:
      "To remove saturated oil for disposal, you should fill the machine, lock the lid, and bring the Eco machine to the disposal area.",
  },
  {
    questionId: "d1c8b5a2",
    question: "The Probe flashes red if the oil is good.",
  },
  {
    questionId: "e3f8c7b9",
    question: "Gloves are always required when handling the fryer.",
  },
  {
    questionId: "b3e6d9f7",
    question: "The wheels should be left unlocked in use.",
  },
  {
    questionId: "a2c8e5b6",
    question: "You should not place your hands in the hot oil.",
  },
  {
    questionId: "f9d4g7h1",
    question: "Filter Baskets do not need to be cleaned.",
  },
  {
    questionId: "k6j2m8n5",
    question: "The paper filter does not need to be used when filtering.",
  },
  {
    questionId: "p3r7t1q9",
    question: "The Eco Machine should be left outside when not in use.",
  },
  {
    questionId: "x0z4v6y8",
    question: "The Eco Flo Powder should be added before filtering process.",
  },
];

export default function Quiz({
  handleNext,
  completeProgress,
  handleMarkComplete,
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [user] = useUserStore((state) => [state.user]);
  const [quizId, setQuizId] = useState();
  const [error, setError] = useState("");
  const [isLoadingBtn, setisLoadingBtn] = useState(false);
  const [result, setResult] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    // console.log({ user });
    api
      .get(`/quiz/getByUserId`, {
        headers: {
          "x-auth-token": token,
        },
      })
      .then((res) => {
        console.log({ res });
        if (res.status === 200) {
          setQuizId(res?.data?.data?.[0].id);
        }
      });
    // Shuffle the questions array
    const shuffled = questions.sort(() => Math.random() - 0.5);
    setShuffledQuestions(shuffled.slice(0, 15)); // Take the first 15 shuffled questions
  }, []);

  console.log("quizId", quizId);
  const handleResponse = async (response) => {
    if (!isLoading) {
      setIsLoading(true);

      const answer = response;
      questions[currentQuestionIndex].answer = response;

      response = true;

      if (response) {
        if (currentQuestionIndex < shuffledQuestions.length) {
          setAnswers((ans) => {
            return {
              ...ans,
              [questions[currentQuestionIndex].questionId]: answer,
            };
          });
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else if (currentQuestionIndex >= shuffledQuestions.length - 1) {
          console.log(answers);
          console.log({ user });

          api
            .post(
              `/quiz/submit-answers/${quizId}`,
              {
                answers,
                course_id: 1,
                user_id: user?.id,
                organization_id: user?.organization_id,
              },
              {
                headers: {
                  "x-auth-token": token,
                },
              }
            )
            .then((res) => {
              console.log(res?.data);
              if (res?.data?.message === "Passed") {
                setResult(res?.data?.data);
                handleMarkComplete();
                handleNext();
              }
              if (res?.data?.message === "Failed") {
                setResult(res?.data?.data);
                setError("Sorry, you failed, please try again.");
              }
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.message);
            });
        }
        setIsLoading(false);
      }
    }
  };

  // const handleSubmitResponse = () => {
  //   //handle submitquize for backend
  //   setisLoadingBtn(true);
  //   console.log("questions", questions);
  //   const correctcount = questions.filter((el) => {
  //     return el.correctAnswer === el.answer;
  //   }).length;
  //   console.log("correctcount", correctcount);

  //   api
  //     .patch(`/quiz/update/${quizeId}`, {
  //       course_id: "64dfaa1cc915da2618bbd1b7",
  //       user_id: user?.id,
  //       organization_id: user?.organization_id,
  //       score: ((correctcount / questions.length) * 100).toFixed(2),
  //       passed: correctcount === questions.length ? true : false,
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setisLoadingBtn(false);
  //         const { passed, score } = res?.data?.data;
  //         console.log("completeProgress", res?.data?.data);
  //         toast.success(res.data.message);
  //         // navigate("/certification", {
  //         //   state: {
  //         //     passed,
  //         //     score,
  //         //   },
  //         // });
  //       }
  //     });
  //   console.log("submit");
  // };

  // TODO: Make more dynamic
  if (completeProgress > courseProgress.length - 2) {
    return (
      <div>
        <p className="text-[#848484]">
          Congrats you have passed the course, please go to certificate tab to
          download your certificate
        </p>
        <button
          className="download-certificate py-[21px] px-[15px] min-w-[350px] rounded-full mt-7 flex justify-center"
          onClick={handleNext}
        >
          <p className="sm:text-[20px] text-[14px] font-bold uppercase text-white">
            GET MY CERTIFICATE
          </p>
        </button>
      </div>
    );
  }

  const handleTryAgain = () => {
    // Shuffle the questions array
    const shuffled = questions.sort(() => Math.random() - 0.5);
    setShuffledQuestions(shuffled.slice(0, 15)); // Take the first 15 shuffled questions
    setError("");
    setCurrentQuestionIndex(0);
    setAnswers({});
  };

  return (
    <>
      <div className="flex justify-between max-w-[569px] w-[100%]">
        <p className="text-[22px] font-medium text-[#1F1F1F]">
          90% is required to pass
        </p>
        <p className="text-[#848484] text-[22px] font-medium">
          {Math.min(currentQuestionIndex + 1, 15)}/{shuffledQuestions.length}
        </p>
      </div>
      <p className="text-[#848484]">
        You may sit the exam as many times as you like.
      </p>
      {currentQuestionIndex <= shuffledQuestions.length - 1 ? (
        <div className="px-[32px] py-3 max-w-[569px] w-[100%] bg-white product-work rounded-xl flex items-center mt-[23px]">
          <p className="text-[#1F1F1F] text-[18px] font-medium">
            {shuffledQuestions[currentQuestionIndex]?.question}
          </p>
        </div>
      ) : null}

      {currentQuestionIndex <= shuffledQuestions.length - 1 ? (
        <div className="flex mt-14">
          <>
            <button
              disabled={isLoading}
              onClick={() => handleResponse(true)}
              className={
                "font-medium text-lg  w-[143px] rounded-xl h-[51px] " +
                (isLoading
                  ? "bg-[#b5b5b5] text-[white]"
                  : "bg-[#33FF12] text-[#1F1F1F]")
              }
              // ... add other styles
            >
              True
            </button>
            <button
              disabled={isLoading}
              onClick={() => handleResponse(false)}
              className={
                "font-medium text-lg ml-[43px] text-[white] w-[143px] rounded-xl h-[51px] " +
                (isLoading ? "bg-[#b5b5b5]" : "bg-[#FF5912]")
              }
              // ... add other styles
            >
              False
            </button>{" "}
          </>
        </div>
      ) : (
        <>
          {error ? (
            <>
              <div
                className="w-300 flex flex-col items-center mt-5"
                style={{ width: 300 }}
              >
                <SVGExamResult result={result} className="mx-auto" />
                <p className="text-18 font-medium mt-3 text-red-600">{error}</p>
                <button
                  disabled={isLoading}
                  onClick={() => handleTryAgain()}
                  className={
                    "mt-3 font-medium text-lg  w-[143px] rounded-xl h-[51px] " +
                    (isLoading
                      ? "bg-[#b5b5b5] text-[white]"
                      : "bg-[#0e2644] text-[#df9b22]")
                  }
                  // ... add other styles
                >
                  Try Again
                </button>
              </div>
            </>
          ) : (
            <button
              disabled={isLoading}
              onClick={() => handleResponse(true)}
              className={
                "mt-5 font-medium text-lg  w-[143px] rounded-xl h-[51px] " +
                (isLoading
                  ? "bg-[#b5b5b5] text-[white]"
                  : "bg-[#33FF12] text-[#1F1F1F]")
              }
              // ... add other styles
            >
              Submit
            </button>
          )}
        </>
      )}
    </>
  );
}
