import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar";
import Drawer from "../../components/drawer";
import { Link, useParams, Outlet, useLocation } from "react-router-dom";
import MenuBar from "../../components/menu-bar";
import { useUserStore } from "../../storesZustand/useUserStore";
import { useOrganizationStore } from "../../storesZustand/useOrganizationStore";
import {
  useEmployeesForLocations,
  useRestaurantStats,
} from "../../services/locations/queries"; // import "react-select/dist/react-select.css"; // Import the default styles
import BreadcrumbsComponent from "../../components/UI/breadcrumbs";
import { useOrganizationData } from "../../services/organization/queries";
import { useLocationData } from "../../services/location/queries";
import { useGetAllDevicesData } from "../../services/devices/queries";

const Training = () => {
  const { organization_id, organization_name, location_id } = useParams();
  const location = useLocation();

  const { data: organizationData, error: errorOrganization } =
    useOrganizationData();
  const { error: errorLocation } = useLocationData();
  const { error: errorAllDevicesData } = useGetAllDevicesData();
  const { data: employeesLoc, isLoading: isLoadingEmployees } =
    useEmployeesForLocations(location_id);
  const { data: organizationLocations } = useRestaurantStats(organization_id);

  const [user] = useUserStore((state) => [state.user]);
  const [organizations] = useOrganizationStore((state) => [
    state.organizations,
  ]);

  const [numTrainingsComplete, setNumTrainingsComplete] = useState(0);
  const [percentageOfCompletions, setPercentageOfCompletions] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOpen]);
  useEffect(() => {
    if (location_id && employeesLoc?.length > 0) {
      let completedTrainings = 0;
      let employees = 0;

      employeesLoc.forEach((emp) => {
        completedTrainings += (parseInt(emp?.score) || 0) > 70 ? 1 : 0;
      });

      employees = employeesLoc.length;

      let perc = Math.round((completedTrainings / employees) * 100);

      setNumTrainingsComplete(completedTrainings);
      if (!isNaN(perc)) setPercentageOfCompletions(perc);
    } else if (organizationLocations?.length > 0) {
      let completedTrainings = 0;
      let employees = 0;

      organizationLocations.forEach((org) => {
        completedTrainings += parseInt(org.courses_complete) || 0;
        employees += parseInt(org.total_employees) || 0;
      });

      let perc = Math.round((completedTrainings / employees) * 100);

      setNumTrainingsComplete(completedTrainings);
      if (!isNaN(perc)) setPercentageOfCompletions(perc);
    } else if (organizations) {
      let completedTrainings = 0;
      let employees = 0;

      organizations.forEach((org) => {
        completedTrainings += parseInt(org.courses_complete) || 0;
        employees += parseInt(org.num_employees) || 0;
      });

      let perc = Math.round((completedTrainings / employees) * 100);

      setNumTrainingsComplete(completedTrainings);
      if (!isNaN(perc)) setPercentageOfCompletions(perc);
    }
  }, [
    organizations,
    organizationLocations,
    organization_name,
    location_id,
    employeesLoc,
  ]);

  return (
    <React.Fragment>
      <div className="w-[100%]">
        <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
        <div className="w-[250px] xl:flex hidden">
          <Sidebar />
        </div>
        <div className=" xl:w-[calc(100%-240px)] w-[100%] xl:ml-[240px] bg-[#f7f7f7] flex justify-between ">
          <div className="w-[100%]  ld:px-[47px] px-5 sm:pt-[25px] pb-[100px] sm:pr-[120px]">
            <div
              className={`w-full h-[40px] flex flex-row justify-between items-center py-2 px-2 ${
                screenWidth <= 640 || screenWidth >= 1025 ? "hidden" : "block"
              }`}
            >
              <button onClick={() => toggleDrawer()}>
                <img src="/images/burger-open.svg" alt="burger" />
              </button>
            </div>
            <div>
              {screenWidth < 640 && (
                <div className="fixed z-30 top-0 left-0 w-full h-[88px] bg-white flex flex-row justify-between items-center py-7 px-5">
                  <button onClick={() => toggleDrawer()}>
                    <img src="/images/burger-open.svg" alt="burger" />
                  </button>

                  {/* <DropdownDays
                    screenWidth={screenWidth}
                    data={mockData}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    optionDefault={"All Employees"}
                  /> */}
                </div>
              )}
              <div className="flex justify-between mb-4 items-center">
                <div className="flex items-start gap-2">
                  {user && organizations && organizationLocations && (
                    <BreadcrumbsComponent
                      breadCrumbsArr={
                        user.role_id === 3
                          ? [
                              { name: "Overview", url: "/training" },
                              organization_id
                                ? {
                                    name: organizations.find(
                                      (item) => item.id === +organization_id
                                    )?.name,
                                    url: `/training/org/${organization_id}`,
                                  }
                                : {},
                              location_id
                                ? {
                                    name: organizationLocations.find(
                                      (item) => item.id === +location_id
                                    )?.name,
                                    url: location.pathname,
                                  }
                                : {},
                            ]
                          : [
                              { name: "Overview", url: "" },
                              {
                                name: user.organization_name,
                                url: `/training/org/${organization_id}`,
                              },
                              location_id
                                ? {
                                    name: organizationLocations.find(
                                      (item) => item.id === +location_id
                                    )?.name,
                                    url: location.pathname,
                                  }
                                : {},
                            ]
                      }
                    />
                  )}
                </div>
                <div className="flex items-start gap-2">
                  {/* {screenWidth >= 640 && (
                    <DropdownDays
                      screenWidth={screenWidth}
                      data={mockData}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      optionDefault={"All Employees"}
                    />
                  )} */}
                </div>
              </div>
              <div className="flex flex-col sm:flex-row justify-between items-center gap-5 mt-[50px]">
                <div
                  className="flex flex-row items-center justify-center gap-8 rounded-[15px] p-[44px] flex-1 basis-0	grow h-[131px]"
                  style={{
                    background:
                      "linear-gradient(46deg, #07203e 58.85%, #e4a40c 100%)",
                    boxShadow: "0px 20px 100px 0px rgba(85, 85, 85, 0.05)",
                  }}
                >
                  <p className="text-[30px] font-bold text-center text-white">
                    {numTrainingsComplete}
                  </p>
                  <p className="text-[#C6C6C6] text-center">
                    Number of Completed Trainings
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center gap-8 rounded-[15px] p-[44px] bg-white flex-1 basis-0 grow h-[131px]">
                  <p className="text-[30px] font-bold text-center">
                    {percentageOfCompletions || 0}%
                  </p>
                  <p className="text-[#787878]  text-center">
                    of employees registered who completed training
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center gap-8 rounded-[15px] p-[44px] bg-white flex-1 basis-0	grow h-[131px]">
                  <p className="text-[#787878]  text-center ">
                    Take the training course
                  </p>
                  <div className="flex justify-center">
                    <Link
                      to="/training-portal"
                      className="text-center border border-[#E5A50E] m-auto px-8 py-2 text-[12px] rounded-full uppercase text-[#E5A50E] font-bold "
                    >
                      Access
                    </Link>
                  </div>
                </div>
              </div>
              <Outlet />
            </div>
            <div>
              {user && <MenuBar user={user} screenWidth={screenWidth} />}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Training;
