import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { courseProgress } from "../../data";

const VideoModal = ({
  isOpen,
  setIsOpen,
  video,
  completeProgress,
  lessonNumber,
  hasWatchedVideo,
  setHasWatchedVideo,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [triggerPointReached, setTriggerPointComplete] = useState(false);
  const [timeoutReached, setTimeoutReached] = useState(false);
  const playerRef = useRef(null);

  const playerConfig = {
    attributes: {
      controlsList: "nodownload", // Disable download button and double-click to fullscreen
    },
    file: {
      attributes: {
        controlsList: "nodownload", // Disable download button
      },
    },
  };

  const lessonVideoLength = useMemo(() => {
    const convertTimeToMilliseconds = (time) => {
      if (time) {
        const [minutes, seconds] = time.split(":");
        return parseInt(minutes) * 60 * 1000 + parseInt(seconds) * 1000;
      }
    };

    const lessonProgress = courseProgress[lessonNumber]?.progress;
    const lessonVideoLength = convertTimeToMilliseconds(lessonProgress);
    return lessonVideoLength;
  }, [lessonNumber]);

  useEffect(() => {
    if (isPlaying) {
      const videoTimeout = setTimeout(() => {
        setTimeoutReached(true);
        // Additional code to handle video timeout
      }, lessonVideoLength - 5000);

      return () => clearTimeout(videoTimeout); // Clear the timeout if component unmounts
    }
  }, [isPlaying, lessonVideoLength]);

  useEffect(() => {
    if (timeoutReached && triggerPointReached) setHasWatchedVideo(true);
    else setHasWatchedVideo(false);
  }, [setHasWatchedVideo, timeoutReached, triggerPointReached]);

  useEffect(() => {
    setIsPlaying(false);
    if (lessonNumber >= completeProgress) {
      setHasWatchedVideo(false);
      setTimeoutReached(false);
      setTriggerPointComplete(false);
    } else {
      setHasWatchedVideo(true);
    }
  }, [completeProgress, lessonNumber, setHasWatchedVideo, video]);

  useEffect(() => {
    // Add event listener for Ctrl + 2 (or Command + 2 on Mac)
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [completeProgress, lessonNumber, video]);

  const handleVideoProgress = (progress) => {
    if (!hasWatchedVideo && progress.played >= 0.9) {
      setTriggerPointComplete(true);
    }
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
  };

  const handleKeyDown = (event) => {
    // Check for Ctrl + u (or Command + u on Mac)
    if ((event.ctrlKey || event.metaKey) && event.key === "u") {
      // Do something when Ctrl + 2 is pressed
      setHasWatchedVideo(true);
    }
  };

  return (
    <div className="inline-block align-bottom  w-full bg-white sm:rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle ">
      <div>
        <div className="modal-container w-full sm:h-[347px] flex items-center justify-center">
          {isPlaying ? (
            <ReactPlayer
              onContextMenu={(e) => e.preventDefault()}
              ref={playerRef}
              url={video}
              config={playerConfig}
              controls={true}
              width="100%"
              height="100%"
              onProgress={handleVideoProgress}
              playing={isPlaying}
            />
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                }}
              >
                <img
                  src="/images/video-thumbnail.png"
                  alt="Video Thumbnail"
                  onContextMenu={(e) => e.preventDefault()}
                  onClick={handlePlayButtonClick}
                  style={{
                    width: "auto",
                    // height: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </div>
            </>
          )}
          {/* <video
            width="347px"
            height="100%"
            controls={true}
            // poster="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20190710102234/download3.png"
          > */}
          {/* <source src={video} type="video/mp4"></source>
          </video> */}
          {/* <ReactPlayer
            url="/images/baby.mp4"
            controls
            width="100%"
            height="100%"
            playing={true}
            volume={0.8}
            muted={false}
            playbackRate={1}
            loop={false}
            pip={false}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
            }}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
