import { useQuery } from 'react-query';
import api from '../../utils/api';

export function useInviteForLocation (id) {
  
  return useQuery(
    ['invitesForLocation', id],
    async () => {
      const response = await api.post(
        '/invites/invitesByLocation/',
        { location_ids: [id] }
      );
      return response?.data?.data || [];
    },
    {
      cacheTime: 120000,
      staleTime: 100000
    }
  );
}
