// src/Table.js
import React, { memo, useCallback, useEffect, useState } from "react";
import SortButton from "../sort-button";
import useSortData from "../../hooks/useSortData";
import ProgressBar from "../progress-bar";
import api from "../../utils/api";
import Spinner from "../UI/Spinner";
import { useInviteForLocation } from "../../services/invite/queries";
import { useParams } from "react-router-dom";
import { useEmployeesForLocations } from "../../services/locations/queries";
import { useViewEmployeesStore } from "../../storesZustand/useViewEmployeesStore";
import { useViewDasboardStore } from "../../storesZustand/useViewDasboardStore";
import { ModalTypes } from "../all-modal-window/modal-window";
import { toast } from "react-toastify";

const userLocales = navigator.languages || [navigator.language];

const options = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
};

const TableLocation = () => {
  //for rerrender after delete
  const [isDeleting] = useViewEmployeesStore((state) => [state.isDeleting]);

  const [mergedData, setMergedData] = useState(null);

  const { organization_id, organization_name, location_id } = useParams();

  const { data: employeesLoc, isLoading: isLoadingEmployees } =
    useEmployeesForLocations(location_id);

  const { data: invites, isLoading: isLoadingInvites } =
    useInviteForLocation(location_id);

  useEffect(() => {
    if (employeesLoc && invites) {
      // Create a set of emails from list2 for faster lookup
      // const emailSet2 = new Set(employeesLoc.map((item) => item.email));

      // let filteredD = invites
      //   ? invites.filter((item) => !emailSet2.has(item.email))
      //   : [];

      // Merge the two lists, add the "isInvite" property, and filter duplicates
      const mergedListUsersAndUnvites = [...employeesLoc, ...invites];

      setMergedData(mergedListUsersAndUnvites);
    }
  }, [employeesLoc, invites]);

  return isLoadingInvites || isLoadingEmployees ? (
    <Spinner
      size={"w-10 h-10 mt-10"}
      color={"text-white-200 dark:text-white-600"}
    />
  ) : (
    <Table
      location_id={location_id}
      data={mergedData}
      setMergedData={setMergedData}
      isLoading={isLoadingInvites || isLoadingEmployees}
    />
  );
};

const Table = ({
  location_id,
  isLoading,
  data,
  selectedOption,
  setMergedData,
}) => {
  //const token = localStorage.getItem('token');
  const { sortedData, sortData, sortDataNum, sortParam } = useSortData(data);

  const [setCurrentClickSendReminderOrDeleteItem] = useViewEmployeesStore(
    (state) => [state.setCurrentClickSendReminderOrDeleteItem]
  );

  const [setDisplayModalWindowType] = useViewDasboardStore((state) => [
    state.setDisplayModalWindowType,
  ]);

  let userLocale;
  for (const locale of userLocales) {
    if (locale.includes("-")) {
      userLocale = locale;
      break;
    }
  }

  const handleSort = (param) => {
    sortData(param);
  };

  const handleSortNum = (param) => {
    sortDataNum(param);
  };

  const convertToDate = (registeredDate) => {
    let options = { year: "numeric", month: "2-digit", day: "2-digit" };
    let date = new Date(registeredDate);
    return date.toLocaleDateString(userLocale, options).replace(/\//g, "-");
  };

  const handlerSendReminder = (item) => {
    setCurrentClickSendReminderOrDeleteItem(item);
    setDisplayModalWindowType(ModalTypes.SEND_REMINDER);
  };

  const handlerRemoveInvite = (item) => {
    setCurrentClickSendReminderOrDeleteItem({
      ...item,
      location_id: location_id,
    });
    setDisplayModalWindowType(ModalTypes.REMOVE_USER);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(
      "https://wiz.halcyon-eco.com/register/" + text
    );
    toast.success("Link copied");
  };

  return (
    <div className="table-container overflow-auto w-full mt-8">
      <table className="divide-y-8 divide-[#F4F4F4] w-full">
        <thead>
          <tr>
            <th className="pl-4 whitespace-nowrap px-2 py-2 text-left md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span className="mr-2">Employee Name</span>
              <SortButton
                handlerToggler={handleSort}
                text="A - Z"
                sortBy="first_name"
              />
            </th>
            <th className="px-2 py-2 text-left md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span className="mr-2">Email</span>
              <SortButton
                handlerToggler={handleSort}
                text="A - Z"
                sortBy="email"
              />
            </th>
            <th className="px-2 py-2 text-left md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span className="mr-2">Training Status</span>
              <SortButton
                handlerToggler={handleSortNum}
                text="%"
                sortBy="score"
              />
            </th>
            <th className="px-2 py-2 text-left md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              <span className="mr-2">Activity</span>
              <SortButton
                handlerToggler={handleSort}
                text="Sort"
                sortBy="registered_date"
              />
            </th>
            <th className="px-2 py-2 text-center md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider"></th>
            <th className="px-2 py-2 text-center md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
              Remove
            </th>
          </tr>
        </thead>

        <tbody className="divide-y-8 divide-[#F4F4F4]">
          {sortedData?.map((item) => (
            <tr className="bg-white rounded-lg" key={item?.id}>
              <td className="px-2 pl-4 py-3 whitespace-nowrap rounded-l-full">
                <div className="text-base font-medium text-[#1E3A56]">
                  {item?.role || ""}
                  {(item?.first_name || "") + " " + (item?.last_name || "")}
                </div>
              </td>
              <td className="px-2 pl-4 py-3 whitespace-nowrap">
                <div className="text-base font-medium text-[#1E3A56]">
                  {item?.email}
                </div>
              </td>
              <td className="px-2 py-3 whitespace-nowrap">
                <div className="text-base text-[#1E3A56] flex items-center gap-4">
                  <ProgressBar
                    percentage={
                      item?.invite_code
                        ? 0
                        : item?.certificate_id !== null
                        ? 100
                        : item?.score
                    }
                  />

                  <p className="text-[#A8A39A] text-left text-[12px] font-[500]">
                    {item?.score}%
                  </p>
                </div>
              </td>
              <td className="px-2 py-3 whitespace-nowrap">
                <div className="text-base text-[#1E3A56] flex items-center gap-4">
                  <div className="text-[#1E3A56]">
                    {item?.invite_code
                      ? "Code: " + item?.invite_code
                      : item?.registered_date
                      ? "Registered: " + convertToDate(item?.registered_date)
                      : "Inactive"}
                  </div>
                  {item?.invite_code ? (
                    <div>
                      <button
                        className="self-start"
                        style={{
                          width: 10,
                        }}
                        onClick={() => handleCopy(item?.invite_code)}
                      >
                        <img src="/images/copy.svg" alt="copy" />
                      </button>
                    </div>
                  ) : null}
                </div>
              </td>

              <td className="px-2 py-3 whitespace-nowrap">
                {item?.invite_code || !item?.certificate_id ? (
                  <button
                    className="text-center font-ABeeZee  text-xs font-normal text-[#8597A8] border border-[#8597A8] rounded-[7px] py-2 px-6"
                    onClick={() => handlerSendReminder(item)}
                  >
                    Send Reminder
                  </button>
                ) : null}
              </td>
              <td className="px-2 py-3 whitespace-nowrap rounded-r-full">
                <div className="text-base text-[#1E3A56] flex justify-center">
                  <button onClick={() => handlerRemoveInvite(item)}>
                    <img src="/images/remove-icon.svg" alt="remove" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableLocation;
