const userLocale = navigator.language || "en-IE"; // Use 'en-US' as a default if the user's locale is not available

export const formattedCurrency = (number, value) =>
  new Intl.NumberFormat(userLocale, {
    style: "currency",
    currency: value || "EUR", // You can specify a default currency code here if needed
    minimumFractionDigits: 0, // Set minimum decimal places to 0
    maximumFractionDigits: 0, // Set maximum decimal places to 0
  }).format(number);

export function getCurrencySymbol(currencyCode) {
  const value = 0; // We don't need an actual value, just formatting
  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
    })
      .format(value)
      .replace(/[0-9\s.,]/g, "")
      .trim();
  } catch (error) {
    console.error("Currency code is not valid or not supported:", currencyCode);
    return currencyCode; // Return the code itself if an error occurs
  }
}

export const canAccessAll = (user) => {
  return user?.role_id === 3 && user?.organization_id === 1;
};

export const daysInARowCheck = (day, item, daysOfWeek) => {
  const litres = item?.dataEachLocationByDaysInLiters?.[day];
  const previousDayIndex = Object.keys(daysOfWeek).indexOf(day) - 1;
  const previous2DaysIndex = Object.keys(daysOfWeek).indexOf(day) - 2;
  const previousDay = Object.keys(daysOfWeek)[previousDayIndex];
  const previous2Day = Object.keys(daysOfWeek)[previous2DaysIndex];

  const previousDayLiters = item?.dataEachLocationByDaysInLiters?.[previousDay];
  const previous2DayLiters =
    item?.dataEachLocationByDaysInLiters?.[previous2Day];

  if (litres > 0) {
    return 1;
  } else if (
    (litres === 0 || litres === undefined) &&
    (previousDayLiters === 0 || previousDayLiters === undefined) &&
    previousDayIndex >= 0 &&
    (previous2DayLiters === 0 || previous2DayLiters === undefined) &&
    previous2DaysIndex >= 0
  ) {
    return 2;
  } else {
    return 0;
  }
};
