import React, { useMemo, useState } from "react";
import Profile from "../../components/profile";
import Sidebar from "../../components/sidebar";
import Chart from "../../components/UI/chart";
import Drawer from "../../components/drawer";
import ProfileDrawer from "../../components/profile-drawer";
import { formattedCurrency } from "../../helpers/helpers";
import { useNavigate } from "react-router-dom";
import StatBlock from "../../components/stat-block/StatBlock";
import { useDashboardDataStore } from "../../storesZustand/useDashboardDataStore";

const locale = navigator.language || "en-IE";

const Dashboard = () => {
  const [dashboardData] = useDashboardDataStore((state) => [
    state.devicesWithLocationWithAnalytics,
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
  };
  const toggleDrawer1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen(false);
  };

  const machinesActive = useMemo(() => {
    return dashboardData?.devices?.length;
  }, [dashboardData]);

  return (
    <React.Fragment>
      <div className="w-[100%]">
        <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
        <ProfileDrawer isOpen1={isOpen1} toggleDrawer1={toggleDrawer1} />
        <div className="w-[250px] xl:flex hidden">
          <Sidebar />
        </div>
        <div className=" xl:w-[calc(100%-240px)] w-[100%] xl:ml-[240px] bg-[#f7f7f7] flex justify-between ">
          <div className="w-[100%] sm:p-10 p-4">
            <div className="flex xl:justify-end justify-between">
              <svg
                onClick={() => toggleDrawer()}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 cursor-pointer xl:hidden flex"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                />
              </svg>
              <svg
                onClick={() => toggleDrawer1()}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-8 h-8 cursor-pointer  flex"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                />
              </svg>
            </div>
            <div className="flex justify-between items-center">
              <p className="font-bold text-[32px]">Savings</p>
            </div>
            <div className="sm:flex space-y-2 overflow-x-auto	 block gap-2 justify-between  mt-[45px]">
              <StatBlock
                variant="dark"
                icon="/images/Frame (10).svg"
                description="Total Savings"
                amount={
                  dashboardData?.totalLitres
                    ? formattedCurrency(dashboardData?.totalLitres * 1.19)
                    : null
                }
                unit="€"
              />
              <StatBlock
                variant="light"
                icon="/images/Frame (12).svg"
                description="Litres of oil saved"
                amount={
                  parseInt(dashboardData?.totalLitres).toLocaleString(locale) +
                  " L"
                }
                unit="L"
              />
              <StatBlock
                variant="light"
                icon="/images/carbon-footprint.svg"
                description="Carbon Saved"
                amount={parseInt(
                  dashboardData?.totalLitres * 3.81
                ).toLocaleString(locale)}
                unit="kg CO2e"
              />

              <StatBlock
                variant="light"
                icon="/images/Frame (12).svg"
                description="Savings last 30 Days"
                amount={
                  dashboardData?.totalLitresLast30Days
                    ? formattedCurrency(
                        dashboardData?.totalLitresLast30Days * 1.19
                      )
                    : null
                }
                unit="€"
              />
            </div>
            <div className=" ">
              <div className=" mt-8">
                <Chart data={dashboardData?.last7daysData} />
              </div>
              <div>
                <div
                  className="w-full bg-white rounded-xl py-6 px-5 flex justify-between items-center mt-7 cursor-pointer"
                  onClick={() => navigate("/usage")}
                >
                  <div className="flex items-center ">
                    <img src="/images/money card.svg" alt="" />
                    <p className="sm:text-[18px] text-[14px] font-bold ml-6">
                      Machines Active
                    </p>
                  </div>
                  <p className="text-[#5B25B4] font-bold">{machinesActive}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:block hidden">
            <ProfileDrawer
              data={dashboardData?.last3MonthsData}
              isOpen1={isOpen1}
              toggleDrawer1={toggleDrawer1}
            />
          </div>
          {/* <div className="xl:flex">
              <Profile data={dashboardData?.last3MonthsData} />

            </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
