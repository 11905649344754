import React, { useEffect, useMemo } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Chart = ({ item, len, i, onChartsRendered, currency }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 8, // Adjust the font size for days
          },
        },
      },
      y: {
        title: {
          display: true,
          text: `Savings (${currency})`, // Y axis label
          font: {
            size: 12, // Adjust the font size for the label
          },
        },
        beginAtZero: true,
        ticks: {
          font: {
            size: 8, // Adjust the font size for values
          },
        },
      },
    },
  };

  const days = useMemo(() => {
    if (item) {
      const numberOfDays = dayjs(item?.day[0]?.date).daysInMonth();
      console.log();
      let daysArray = [];
      for (let i = 1; i <= numberOfDays; i++) {
        daysArray.push(i);
      }
      return daysArray;
    }
    return [];
  }, [item]);

  function getNumberOfDaysInMonth(data) {
    const date = dayjs(data[0].date, "YYYY-MM-DD");
    return date.daysInMonth();
  }

  function getDayFromDate(dateString) {
    // Split the date string into parts
    const parts = dateString.split("/");

    // The day is the first part (index 0) of the split string
    const day = parseInt(parts[3], 10);

    return day;
  }

  const savings = useMemo(() => {
    if (item) {
      const numberOfDays = getNumberOfDaysInMonth(item?.day[0].date);
      let savingsArray = new Array(numberOfDays).fill(0);

      item?.day.forEach((_item) => {
        const day = dayjs(_item.date, "YYYY-MM-DD").date();
        savingsArray[day - 1] = parseFloat(_item.saving) || 0; // day - 1 because arrays are 0-indexed
      });

      return savingsArray;
    }
    return [];
  }, [item]);

  const data = {
    labels: days,
    datasets: [
      {
        label: "Dataset 1",
        data: savings,

        borderColor: "#E5A50E",
        backgroundColor: "#E5A50E",
        borderWidth: 1, // Set the line thickness
        pointRadius: 2,
      },
      {
        label: "Dataset 2",
        data: savings,
        borderColor: "#fbf1db",
        backgroundColor: "#fbf1db",
        borderWidth: 1, // Set the line thickness
        pointRadius: 2,
      },
    ],
  };

  if (i === len - 1 && savings.length > 25) {
    onChartsRendered();
  }

  return (
    <React.Fragment>
      <div className="container-graph" key={i}>
        <Line options={options} data={data} />
      </div>
    </React.Fragment>
  );
};

export default Chart;
