import React, { useEffect, useMemo, useState } from 'react';
import Drawer from '../../components/drawer';
import ProfileDrawer from '../../components/profile-drawer';

import Sidebar from '../../components/sidebar';

import MenuBar from '../../components/menu-bar';
import { useDashboardDataStore } from '../../storesZustand/useDashboardDataStore';
import { useUserStore } from '../../storesZustand/useUserStore';
import { Outlet, useNavigate } from 'react-router-dom';
import { useViewDasboardStore } from '../../storesZustand/useViewDasboardStore';
import { useOrganizationData } from '../../services/organization/queries';
import { useLocationData } from '../../services/location/queries';
import { useGetAllDevicesData } from '../../services/devices/queries';

const Dashboard = () => {
  //Request
  const { data: organizationData, error: errorOrganization } = useOrganizationData();
  const { error: errorLocation } = useLocationData();
  const { error: errorAllDevicesData } = useGetAllDevicesData();
  
  const [user] = useUserStore((state) => [state.user]);
  const [dashboardData] = useDashboardDataStore((state) => [
    state.devicesWithLocationWithAnalytics
  ]);
  
  const [isOpen, isOpen1, setIsOpen, setIsOpen1] = useViewDasboardStore((state) => [
    state.isOpen,
    state.isOpen1,
    state.setIsOpen,
    state.setIsOpen1
  ]);
  
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);
  
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
  };
  
  const toggleDrawer1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen(false);
  };
  
  return (
    <React.Fragment>
      <div className='w-[100%]'>
        <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
        <ProfileDrawer
          isOpen1={isOpen1}
          toggleDrawer1={toggleDrawer1}
          data={dashboardData?.last3MonthsData}
        />
        <div className='w-[250px] xl:flex hidden'>
          <Sidebar />
        </div>
        
        <div className=' xl:w-[calc(100%-240px)] w-[100%] xl:ml-[240px] bg-[#F6F6F6] flex justify-between '>
          <Outlet />
          <div>
            {user && <MenuBar user={user} screenWidth={screenWidth} />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
