import React from "react";
import VerifyEmail from "./verifyemail";
import VerifyCode from "./verifycode";
import { useState } from "react";
import ConfirmPassword from "./conformpassword";
const ResetPasswordAuthentication = () => {
  const [activeIndex,setActiveIndex]=useState(0)
  const [email,setEmail]=useState()
  return (
    <React.Fragment>
      <div className="bg-[#040824] w-full h-screen overflow-scroll my-scroller text-white">
        <div className="max-w-[476px] w-[100%] m-auto lg:pt-[130px] px-[10px]">
        <img
                src="/images/halcyon-logo-glow.svg"
                alt="Halcyon Logo"
                width="170" // Set the width of the logo
                height="50" // Set the height of the logo
                className="mx-auto lg:hidden block"
              />
          <img src="/images/Rectangle.png" alt="" />
          <p className="text-[45px] font-bold mt-3">Halcyon Eco Wiz </p>
          <p className="text-[20px] font-normal mt-[5px] tracking-[0.094px]">
            With Halcyon Eco Wiz you can complete your mandatory training and
            business owners/manager can monitor savings.
          </p>
          { activeIndex===0 && <VerifyEmail onSetEmail={setEmail} OnActiveIndex={setActiveIndex}/>} 
          { activeIndex===1 && <VerifyCode email={email} OnActiveIndex={setActiveIndex}/>} 
          { activeIndex===2 && <ConfirmPassword email={email} OnActiveIndex={setActiveIndex}/>} 
          
         
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordAuthentication;
