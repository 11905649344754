// src/components/Drawer.js
import React, { useMemo } from 'react';
import BurgerOpen from '../burger-open';
import Navbar from '../navbar';
import { useUserStore } from '../../storesZustand/useUserStore';

const Drawer = ({ toggleDrawer, isOpen }) => {
  const [user] = useUserStore(state => [
    state.user
  ]);
  const role = user?.role;
  
  const navItems = useMemo(() => {
    const items = [];
    if (role === 'superadmin') {
      items.push(
        {
          title: 'Dashboard',
          icon: '/images/dashboard-new.svg',
          path: user.organization_id === 1 ? '/dashboard/organizations' : '/dashboard/locations',
          pathActive: 'dashboard'
        },
        {
          title: 'Employees',
          icon: '/images/admin-symbol.svg',
          path: `/training`,
          pathActive: 'training'
        }
      );
      if (user?.canSeeEverything) {
        items.push({
          title: 'Devices',
          icon: '/images/device.svg',
          path: '/devices',
          pathActive: 'devices'
        });
      }
    } else if (role === 'admin') {
      items.push(
        {
          title: 'Dashboard',
          icon: '/images/dashboard-new.svg',
          path: '/dashboard/locations',
          pathActive: 'dashboard'
        },
        {
          title: 'Employees',
          icon: '/images/admin-symbol.svg',
          path: `/training/org/${user.organization_id}`,
          pathActive: 'training'
        }
      );
    }
    
    items.push({
      title: 'Training',
      icon: '/images/clipboard-text.svg',
      path: '/training-portal',
      pathActive: 'training-portal'
    });
    
    if (user?.canSeeEverything) {
      items.push({
        title: 'Settings',
        icon: '/images/Frame (8).svg',
        path: '/settings',
        pathActive: 'settings'
      });
    }
    return items;
  }, [role]);
  
  return (
    <div>
      <div
        className={`fixed top-0 left-0 md:h-[100vh] z-50 sm:w-64 w-[313px] h-full  bg-white shadow-md transform transition-transform rounded-tr-[20px] rounded-br-[20px] ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {/* Drawer content goes here */}
        <div className='pl-3 pr-8 py-4 h-full'>
          <div className='flex justify-start'>
            <BurgerOpen handleClick={toggleDrawer} />
          </div>
          <div className='flex flex-col h-full w-[180px] sm:w-[160px] mx-auto text-gray-700  flex-shrink-0 pb-8'>
            <div className='h-full'>
              <Navbar
                isOpen={isOpen}
                navItems={navItems}
                handleToggler={toggleDrawer}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
