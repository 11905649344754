import { useMutation, useQueryClient } from 'react-query';
import api from '../../utils/api';
import { toast } from 'react-toastify';

export function useLocationCreateMutation () {
  const token = localStorage.getItem('token');
  const queryClient = useQueryClient();
  
  return useMutation(
    'locationMutation',
    (newlocation) =>
      api.post('location/create', newlocation, {
        headers: {
          'x-auth-token': token
        }
      }),
    {
      onSuccess: async (data) => {
        toast.success('Location is created');
        const invalidateLocationDataPromise = queryClient.invalidateQueries('locationData');
        const invalidateDashboardDataPromise = queryClient.invalidateQueries('dashboardData');
        await Promise.all([invalidateLocationDataPromise, invalidateDashboardDataPromise]);
      },
      onError: (error) => {
        toast.error(`${error?.response?.data.message ? error?.response?.data.message : error}!`);
      }
    }
  );
}

export function useLocationUpdateMutation () {
  const token = localStorage.getItem('token');
  const queryClient = useQueryClient();
  
  return useMutation(
    'locationUpdateMutation',
    (arg) =>
      api.put(`location/update/${arg.id}`, arg.data, {
        headers: {
          'x-auth-token': token
        }
      }),
    {
      onSuccess: async (data) => {
        toast.success('Location is updated successfully');
        const invalidateLocationDataPromise = queryClient.invalidateQueries('locationData');
        const invalidateDashboardDataPromise = queryClient.invalidateQueries('dashboardData');
        await Promise.all([invalidateLocationDataPromise, invalidateDashboardDataPromise]);
      },
      onError: (error) => {
        toast.error(`${error?.response?.data.message ? error?.response?.data.message : error}!`);
      }
    }
  );
}

