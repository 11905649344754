import React, { useState } from 'react';

const SortButton = ({ handlerToggler, text, sortBy }) => {
    // const [order, setOrder] = useState('');
    // const handleClick = () => {
    //     handlerToggler();
    //     if (!order) {
    //         setOrder('desc');
    //     } else if (order === 'asc') {
    //         setOrder('desc');
    //     } else {
    //         setOrder('asc');
    //     }
    // };
    return (
        <button
            className='bg-[#8597A8] px-3 py-1 rounded-lg'
            onClick={() => {
                handlerToggler(sortBy);
            }}
        >
            <div className='flex items-center justify-center gap-2.5 ]'>
                <span className='text-white text-[11px] leading-6 whitespace-nowrap'>
                    {/* {order === 'asc' ? 'Z - A' : 'A - Z'} */}
                    {text}
                </span>
                <div className='h-[15px] w-[8px]'>
                    <img src='/images/up-down-arrow.svg' alt='arrows' />
                </div>
            </div>
        </button>
    );
};

export default SortButton;
