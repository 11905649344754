import React, { useState, useEffect } from "react";
import {
  LoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

const Map = ({ devices }) => {
  const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;
  const [map, setMap] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isApiLoaded, setIsApiLoaded] = useState(false);

  const mapStyles = {
    height: "600px",
    width: "100%",
  };

  // only valid lat/ longs
  const filteredDevices = devices.filter(
    (device) =>
      device.lat !== null &&
      device?.lat > -90 &&
      device?.lat < 90 &&
      device.long !== null &&
      device?.long > -180 &&
      device?.long < 180
  );
  const defaultCenter = {
    lat: filteredDevices[0]?.lat || 0,
    lng: filteredDevices[0]?.long || 0,
  };

  const handleMapLoad = (map) => {
    setMap(map);
  };

  const handleMarkerClick = (device) => {
    setSelectedDevice(device);
  };

  const handleInfoWindowClose = () => {
    setSelectedDevice(null);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setIsApiLoaded(true);
    };
    script.onerror = () => {
      console.error("Failed to load Google Maps API");
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [REACT_APP_GOOGLE_MAPS_API_KEY]);

  if (!isApiLoaded) {
    return null;
  }

  return (
    <div className="my-9">
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={7}
        center={defaultCenter}
        options={{
          mapId: "9f2c35df3a8d157e",
        }}
        onLoad={handleMapLoad}
      >
        {map &&
          filteredDevices.map((device, index) => (
            <Marker
              key={`${index}-${device.id}`}
              position={{
                lat: device.lat,
                lng: device.long,
              }}
              onClick={() => handleMarkerClick(device)}
              icon={{
                url: "/images/leaf-logo.svg",
                scaledSize: new window.google.maps.Size(30, 30),
              }}
              title={device.location_name}
            />
          ))}

        {selectedDevice && (
          <InfoWindow
            position={{
              lat: selectedDevice.lat,
              lng: selectedDevice.long,
            }}
            onCloseClick={handleInfoWindowClose}
          >
            <div>
              <h3>
                <b>{selectedDevice.location_name || selectedDevice?.name}</b>
              </h3>
              <p>{selectedDevice.address1}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );
};

export default Map;
