import React from 'react';
import { Bars } from 'react-loader-spinner';

const SpinnerBars = ({ size, color }) => {
  return (
    <Bars
      height='120'
      width='120'
      color='#E4A50E'
      ariaLabel='bars-loading'
      wrapperStyle={{}}
      wrapperClass=''
      visible={true}
    />
  );
};

export default SpinnerBars;


