import React, { useCallback, useEffect, useMemo, useState } from "react";
import { daysInARowCheck, formattedCurrency } from "../../helpers/helpers";

import SortButton from "../sort-button";
import useSortData from "../../hooks/useSortData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDashboardDataStore } from "../../storesZustand/useDashboardDataStore";
import { useDashboardData } from "../../services/dashboard/queries";
import { useUserStore } from "../../storesZustand/useUserStore";
import { listOfDates } from "../../utils/constans/listOfDates";
import { useViewDasboardStore } from "../../storesZustand/useViewDasboardStore";
import DropdownDays from "../dropdown-days";
import SearchBar from "../search-bar";
import StatBlock from "../stat-block/StatBlock";
import { locale } from "../../utils/constans/locaLanguage";
import Map from "../map";
import CircleIcon from "../UI/CircleIcon";
import { getPrevious7Days } from "../../helpers/getPrevious7Days";
import BreadcrumbsComponent from "../UI/breadcrumbs";
import Chart from "../UI/chart";
import { useOrganizationStore } from "../../storesZustand/useOrganizationStore";
import { useDeviceStore } from "../../storesZustand/useDeviceStore";
import { generateDaysOfWeek } from "../../helpers/iconsResultWorkersByDays";
import SpinnerBars from "../UI/SpinnerBars";
import { ModalTypes } from "../all-modal-window/modal-window";

const TableLocations = () => {
    let { organizationId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [user] = useUserStore((state) => [state.user]);

    const { isLoading } = useDashboardData(
        user?.role_id,
        user?.organization_id,
        organizationId
    );

    const [organizations] = useOrganizationStore((state) => [
        state.organizations,
    ]);

    const [
        dashboardData,
        setAllDashboardData,
        locationWithAnalyticsOneOrganization,
        setLocationWithAnalyticsOneOrganization,
    ] = useDashboardDataStore((state) => [
        state.devicesWithLocationWithAnalytics,
        state.setAllDashboardData,
        state.locationWithAnalyticsOneOrganization,
        state.setLocationWithAnalyticsOneOrganization,
    ]);
    const [setAllDevicesOneLocation] = useDeviceStore((state) => [
        state.setAllDevicesOneLocation,
    ]);

    let dataForUseComponent =
        user?.role_id === 3 && user?.organization_id !== 1
            ? locationWithAnalyticsOneOrganization
            : user?.role_id === 3 && organizationId
                ? locationWithAnalyticsOneOrganization
                : user?.role_id === 1
                    ? locationWithAnalyticsOneOrganization
                    : dashboardData;

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [selectedOption, setSelectedOption] = useState(listOfDates[0].name);

    const [isOpen, isOpen1, setIsOpen, setIsOpen1] = useViewDasboardStore(
        (store) => [store.open1, store.open2, store.setIsOpen, store.setIsOpen1]
    );

    const [setDisplayModalWindowType, setCurrentOpenIdForEditModalWindow] =
        useViewDasboardStore((state) => [
            state.setDisplayModalWindowType,
            state.setCurrentOpenIdForEditModalWindow,
        ]);

    const dataWithSorting = useMemo(() => {
        if (dataForUseComponent?.locationsWithAnalyticsByDays) {
            let resultSorting = dataForUseComponent.locationsWithAnalyticsByDays;
            resultSorting.sort((a, b) => b.savings - a.savings);
            return resultSorting;
        }
    }, [dataForUseComponent]);

    const { sortedData, sortData } = useSortData(dataWithSorting);

    useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", updateScreenWidth);
        return () => {
            window.removeEventListener("resize", updateScreenWidth);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "visible";
        }
    }, [isOpen]);

    const handlerClickButtonOneLocation = (locationId) => {
        if (organizationId) {
            navigate(`locations/${locationId}`);
            setAllDevicesOneLocation(null);
        } else {
            navigate(
                `/dashboard/organizations/${user.organization_id}/locations/${locationId}`
            );
            setAllDevicesOneLocation(null);
        }
    };

    const handlerCickButtonForOpenEditModal = (id, typeModalWindow) => {
        setDisplayModalWindowType(typeModalWindow);
        setCurrentOpenIdForEditModalWindow(id);
    };

    const handlerCickButtonDawnloadData = (id, typeModalWindow) => {
        setDisplayModalWindowType(typeModalWindow);
        setCurrentOpenIdForEditModalWindow(id);
    };

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
        setIsOpen1(false);
    };

    const openModal = (modalType) => {
        setDisplayModalWindowType(modalType);
    };

    const sumObjectValues = useCallback((obj) => {
        return Object.values(obj).reduce((sum, value) => sum + value, 0);
    }, []);

    const daysOfWeek = useMemo(() => {
        return generateDaysOfWeek();
    }, []);

    const checkCondition = useCallback(
        (day, item) => {
            return daysInARowCheck(day, item, daysOfWeek);
        },
        [daysOfWeek]
    );

    return isLoading ? (
        <div className="flex justify-center items-center w-full h-screen">
            <SpinnerBars />
        </div>
    ) : (
        <div className="w-[100%] h-full sm:p-10 p-4 pt-[100px] sm:pr-[120px] sm:pt-[25px]">
            <div
                className={`w-full h-[40px] flex flex-row justify-between items-center py-2 px-2 ${
                    screenWidth <= 640 || screenWidth >= 1025 ? "hidden" : "block"
                }`}
            >
                <button onClick={() => toggleDrawer()}>
                    <img src="/images/burger-open.svg" alt="burger" />
                </button>
            </div>
            {screenWidth < 640 && (
                <div className="fixed z-30 top-0 left-0 w-full h-[88px] bg-white flex flex-row justify-between items-center py-7 px-5">
                    <button onClick={() => toggleDrawer()}>
                        <img src="/images/burger-open.svg" alt="burger" />
                    </button>
                    <DropdownDays
                        screenWidth={screenWidth}
                        data={listOfDates}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        // optionDefault={listOfDates[0].name}
                    />
                </div>
            )}
            <div className="flex justify-between mb-4 items-center">
                <div className="flex items-start gap-2">
                    {user && dataWithSorting && (
                        <BreadcrumbsComponent
                            breadCrumbsArr={
                                user.organization_id === 1 && user.role_id === 3
                                    ? [
                                        { name: "Overview", url: "" },
                                        {
                                            name: user.organization_name,
                                            url: "/dashboard/organizations",
                                        },
                                        {
                                            name: organizations.find(
                                                (item) => item.id === +organizationId
                                            ).name,
                                            url: location.pathname,
                                        },
                                    ]
                                    : [
                                        { name: "Overview", url: "" },
                                        {
                                            name: user.organization_name,
                                            url: location.pathname,
                                        },
                                    ]
                            }
                        />
                    )}
                </div>
                <div className="flex items-start gap-2">
                    {screenWidth >= 640 && <SearchBar />}
                    {screenWidth >= 640 && (
                        <DropdownDays
                            screenWidth={screenWidth}
                            data={listOfDates}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            // optionDefault={listOfDates[0].name}
                        />
                    )}
                </div>
            </div>
            <div className="flex items-center justify-between gap-5 flex-col sm:flex-row">
                <div className="flex gap-2 justify-between">
                    <div className="flex flex-col gap-2">
                        <StatBlock
                            variant="dark"
                            icon="/images/Frame (10).svg"
                            description="Total Savings"
                            amount={
                                dataForUseComponent?.totalSavings
                                    ? formattedCurrency(
                                        dataForUseComponent?.totalSavings,
                                        dataForUseComponent?.currency
                                    )
                                    : "0"
                            }
                            unit="€"
                        />
                        <StatBlock
                            variant="light"
                            icon="/images/carbon-footprint.svg"
                            description="Carbon Saved"
                            amount={
                                dataForUseComponent?.totalLitres
                                    ? parseInt(
                                        dataForUseComponent.totalLitres * 3.81
                                    ).toLocaleString(locale)
                                    : "0"
                            }
                            unit="kg CO2e"
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <StatBlock
                            variant="light"
                            icon="/images/Frame (12).svg"
                            description="Savings last 30 Days"
                            amount={
                                dataForUseComponent?.totalSavingsLast30Days
                                    ? formattedCurrency(
                                        dataForUseComponent?.totalSavingsLast30Days,
                                        dataForUseComponent?.currency
                                    )
                                    : "0"
                            }
                            unit="€"
                        />
                        <StatBlock
                            variant="light"
                            icon="/images/Frame (12).svg"
                            description="Litres of oil saved"
                            amount={
                                dataForUseComponent?.totalLitres
                                    ? parseInt(dataForUseComponent?.totalLitres).toLocaleString(
                                    locale
                                ) + " L"
                                    : "0"
                            }
                            unit="L"
                        />
                    </div>
                </div>
                <div className="flex justify-center w-full">
                    <Chart
                        variant="light"
                        data={
                            dataForUseComponent?.last7daysData
                                ? dataForUseComponent?.last7daysData
                                : ""
                        }
                        screenWidth={screenWidth}
                    />
                </div>
            </div>
            <div className="flex gap-8 flex-col mt-8 sm:flex-row">
                {screenWidth < 640 && <SearchBar />}
            </div>
            <div className="table-container overflow-auto w-full">
                <table className="divide-y-8 divide-[#F4F4F4] w-full">
                    <thead>
                    <tr>
                        <th className="whitespace-nowrap flex items-center gap-2 px-6 py-2 text-left md:text-base text-sm font-normal text-[#B0CAD1] tracking-wider">
                            <p>Location Name</p>
                            <SortButton
                                handlerToggler={sortData}
                                text="A - Z"
                                sortBy="location_name"
                            />
                        </th>
                        <th className="px-6 py-2 text-left md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider">
                            Address
                        </th>
                        <th className="whitespace-nowrap px-6 py-2  md:text-base  text-[#B0CAD1] text-sm font-normal  tracking-wider text-center">
                            Total Savings
                        </th>
                        <th className="pl-6 py-2 flex justify-between md:text-base text-[#B0CAD1] text-sm font-normal tracking-wider text-center">
                            {getPrevious7Days().map((day, i) => {
                                return <div key={i}>{day}</div>;
                            })}
                        </th>
                        <th className="whitespace-nowrap px-6 py-2  md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider text-center">
                            Last 7 Days Savings
                        </th>
                        <th className="px-6 py-2 md:text-base text-sm font-normal  text-[#B0CAD1] tracking-wider text-center">
                            Reports
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y-8 divide-[#F4F4F4]">
                    {sortedData?.map((item, index) => (
                        <tr className="bg-white rounded-lg" key={index}>
                            <td
                                className="px-6 py-3 whitespace-nowrap rounded-l-full hover:cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlerClickButtonOneLocation(item.location_id);
                                }}
                            >
                                <div className="flex gap-3">
                                    <img
                                        className="cursor-pointer"
                                        onClick={() =>
                                            handlerCickButtonForOpenEditModal(
                                                item.location_id,
                                                "Edit Location"
                                            )
                                        }
                                        src="/images/pencil.svg"
                                        alt=""
                                    />
                                    <div className="text-base font-medium text-[#1E3A56]">
                                        {item?.location_name}
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-3 whitespace-nowrap">
                                <div className="flex gap-3">
                                    <div className="text-base text-[#1E3A56]">
                                        {item?.address1}
                                    </div>
                                </div>
                            </td>
                            <td className="px-6 py-3 whitespace-nowrap">
                                <div className="text-base text-[#1E3A56] text-center">
                                    {formattedCurrency(
                                        item?.savings ? item.savings : 0,
                                        dataForUseComponent?.currency
                                    )}
                                </div>
                            </td>
                            <td className="pl-6 py-3 whitespace-nowrap">
                                <div className="flex justify-between text-[#1E3A56] h-6">
                                    {Object.keys(generateDaysOfWeek())
                                        .map((day) => {
                                            return checkCondition(day, item);
                                        })
                                        .map((condition, index) => (
                                            <div key={index} className="w[22px]">
                                                <CircleIcon condition={condition} />
                                            </div>
                                        ))}
                                </div>
                            </td>
                            <td className="lg:px-6 px-20 py-3 whitespace-nowrap">
                                <div className="text-base text-[#1E3A56] text-center">
                                    {formattedCurrency(
                                        sumObjectValues(
                                            item?.dataEachLocationByDaysInLiters || 0
                                        ),
                                        dataForUseComponent?.currency
                                    )}
                                </div>
                            </td>
                            <td className="lg:px-6 px-20 py-3 whitespace-nowrap rounded-r-full">
                                <div className="text-base text-[#1E3A56] text-center flex gap-2">
                                    <button
                                        onClick={() => {
                                            handlerClickButtonOneLocation(item.location_id);
                                        }}
                                        className="w-[80px] h-[30px]  flex justify-center items-center font-ABeeZee  text-xs font-normal text-[#8597A8] border border-[#8597A8] rounded-[7px] py-3 px-6"
                                    >
                                        View
                                    </button>
                                    <button
                                        onClick={() =>
                                            handlerCickButtonDawnloadData(
                                                item.location_id,
                                                ModalTypes.DOWNLOAD_LOCATION_DATA
                                            )
                                        }
                                        className="w-[80px] h-[30px]  flex justify-center items-center font-ABeeZee text-xs font-normal text-white bg-[#8597A8] border border-[#8597A8] rounded-[7px] py-3 px-6"
                                    >
                                        Download
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                {sortedData?.length > 0 ? null : (
                    <>
                        <p className="text-center mt-5 mb-3 text-[#8597A8]">
                            No Location Found
                        </p>
                        <div
                            className="flex items-center flex-col justify-center gap-1 cursor-pointer"
                            onClick={() => openModal("Add Location")}
                        >
                            <div>
                                <img
                                    src={"/images/addLocIcon.svg"}
                                    alt=""
                                    className="w-5 h-5"
                                />
                            </div>
                            <div className="text-[#8597A8] text-[10px] text-center">
                                Add Location
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div>
                {dataForUseComponent?.locationsWithAnalyticsByDays &&
                dataForUseComponent?.locationsWithAnalyticsByDays.length > 0 ? (
                    <Map devices={dataForUseComponent?.locationsWithAnalyticsByDays} />
                ) : null}
            </div>
        </div>
    );
};

export default TableLocations;
